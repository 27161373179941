import React from "react";
import { styles } from "../styles";
import { ArrowRightWithoutStick, CircleAlertIcon } from "assets";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const AssistedEgressBanner = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.communicationContainer}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        <div
          style={{
            width: "24px",
            height: "24px",
          }}
        >
          <CircleAlertIcon color="#7F56D9" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography sx={styles.communicationTitle}>
            Nueva forma de registrar egresos en Siigo
          </Typography>
          <Typography sx={styles.communicationInfo}>
            A partir de ahora, tendrás la flexibilidad de registrar tus egresos
            semi-automáticamente desde el detalle de tus pagos a proveedores
          </Typography>
        </div>
      </div>
      <div
        style={styles.assistedEgressBannerAction}
        onClick={() => {
          navigate("/transactions", { replace: true });
        }}
      >
        <Typography style={styles.assistedEgressBannerLink}>
          Ir a mis pagos
        </Typography>
        <ArrowRightWithoutStick
          stroke={"#5925DC"}
          width={"22px"}
          height={"22px"}
        />
      </div>
    </div>
  );
};
