export const servicesColumns = [
  {
    field: "alias",
    Header: "Alias",
    headerName: "Alias",
    accessor: "alias",
    id: "alias",
    className: "provider-name-column",
    Cell: ({ value }) => {
      return (
        <p className="limit-text" style={{ margin: 0 }}>
          {value.toUpperCase()}
        </p>
      );
    },
  },
  {
    field: "service",
    Header: "Servicio",
    headerName: "Servicio",
    accessor: "service",
    id: "service",
    className: "provider-name-column",
    Cell: ({ value }) => {
      return (
        <p className="limit-text" style={{ margin: 0 }}>
          {value?.toUpperCase()}
        </p>
      );
    },
  },
  {
    field: "referral_code",
    Header: "Información de pago",
    headerName: "Información de pago",
    accessor: "referral_code",
    id: "referral_code",
    className: "provider-name-column",
    Cell: ({ value }) => {
      return (
        <p className="limit-text" style={{ margin: 0 }}>
          {value}
        </p>
      );
    },
  },
];
