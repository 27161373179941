import React from "react";
import { Steps } from "intro.js-react";
import { styles } from "./styles";

export const MultiplePaymentsIntro = ({ enabled, onComplete }) => {
  const tutorialStep = [
    {
      element: ".multiplePayments",
      intro: (
        <div style={styles.column}>
          <p style={styles.tooltipTitle}>
            Agrega múltiples formas de pago con distintas fechas
          </p>
          <p style={styles.tooltipSubtitle}>
            Puedes también cruzar con tus anticipos.
          </p>
        </div>
      ),
      position: "right",
      tooltipClass: "higlight-tooltip",
      highlightClass: "highlight",
    },
  ];

  return (
    <Steps
      enabled={enabled}
      steps={tutorialStep}
      initialStep={0}
      onComplete={onComplete}
      onExit={() => {}}
      options={{ showBullets: false, doneLabel: "Entendido" }}
    />
  );
};
