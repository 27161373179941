import { useContext } from "react";
import { Footer } from "../../../commons";
import { UserContext } from "../../../contexts";

export const CollectionsFooter = ({
  selectedRows,
  selectedRowsIds,
  totalAmount,
  deleteSelectedCollections,
  openMarkAsPaidModal,
  payLater,
  openWompiModal,
  totalSelected,
  pageIndex,
  sendNotification,
}) => {
  const { approvationNeeded, currentUserIsAdmin } = useContext(UserContext);

  const hasOneUnapproved = () => {
    return selectedRows.find((invoice) => {
      return invoice.original.approved === false;
    });
  };

  const hasTransactionId = () =>
    selectedRows.some((invoice) => invoice?.original?.transaction_id);

  return (
    <Footer
      disabled={selectedRows.length === 0}
      disabledApproval={
        approvationNeeded && hasOneUnapproved() && !currentUserIsAdmin
      }
      totalAmount={totalAmount}
      showDelete
      deleteAction={() => {
        deleteSelectedCollections(selectedRowsIds, pageIndex);
      }}
      showMarkAsPaid
      disabledMarkAsPaid={selectedRows.length < 1}
      markAsPaidAction={openMarkAsPaidModal}
      payLaterAction={payLater}
      openWompiModal={openWompiModal}
      disableWompi={selectedRows.length < 1 || totalAmount <= 0}
      totalSelected={totalSelected}
      disableDelete={hasTransactionId()}
      section="collections"
      showWompi={false}
      sendNotification={sendNotification}
    />
  );
};
