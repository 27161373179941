import { Grid, MenuItem, Select, Stack } from "@mui/material";
import { DataIcon } from "assets";
import { debsSiigoExpensesColumns } from "../utils/debsSiigoExpensesColumns";
import {
  ExpensesDateInput,
  ExpensesInputSelect,
  PaymentMethodInput,
  BalanceText,
  CustomDataGrid,
  AlertSupplierNotDeb,
} from "./";
import { TYPE } from "../constants";
import { styles } from "./styles";
import { Comments } from "./Comments";
import { Title } from "./Title";

export function DischargeRecord({
  receiptDetails,
  amountTotalSelect,
  amountAvailable,
  paymentMethods,
  handleOnChangeAmountRegister,
  formState,
  items,
  register,
  errors,
  toggleSelectedRow,
  onChangeInputPaymentMethod,
  comment,
  isChecked,
  handleChangeForm,
}) {
  const { costCenter, entryType } = receiptDetails;

  const types = [
    { id: 1, label: "Abono a deuda", value: "Abono a deuda" },
    { id: 2, label: "Anticipo", value: "Anticipo" },
  ];

  const isDebtPayment = formState.type === TYPE.debtPayment;

  const entryTypeSelect = receiptDetails.entryType.find(
    (item) => item.ERPDocumentTypeID === formState?.invoiceType
  );

  return (
    <Stack gap={"16px"} marginY={2}>
      <Title icon={<DataIcon />} title={"Registro del egreso"} />
      <ExpensesInputSelect
        label="Tipo"
        name="invoiceType"
        value={formState.invoiceType}
        errors={errors}
        {...register("invoiceType", { required: true })}
      >
        {entryType.map((entry) => (
          <MenuItem key={entry.ERPDocumentTypeID} value={entry.value}>
            {entry?.label}
          </MenuItem>
        ))}
      </ExpensesInputSelect>
      <Grid container direction={"row"} justifyContent={"space-between"}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            paddingRight: "8px",
            paddingLeft: "0px",
          }}
        >
          <Stack gap={"6px"}>
            <label htmlFor="type" style={styles.inputLabel}>
              Realizar un
            </label>
            <Select
              name="type"
              size="small"
              variant="outlined"
              displayEmpty={true}
              style={{ fontSize: "14px" }}
              value={formState.type}
              {...register("type", {
                required: true,
                onChange: (e) => handleChangeForm("type", e.target.value),
              })}
            >
              {types.map((type) => (
                <MenuItem key={type.id} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ paddingRight: "0px", paddingLeft: "8px" }}
        >
          <ExpensesDateInput
            name={"paidAt"}
            paidAt={formState.paidAt}
            errors={errors}
            register={register}
          />
        </Grid>
      </Grid>
      <PaymentMethodInput
        register={register}
        paymentMethods={paymentMethods}
        handleChangeForm={handleChangeForm}
        onChangeInputPaymentMethod={onChangeInputPaymentMethod}
        formState={formState}
        errors={errors}
      />
      {entryTypeSelect?.UseCostCenter && (
        <ExpensesInputSelect
          label="Centro de Costo"
          name="costCenter"
          errors={errors}
          value={formState.costCenter}
          renderValue={(option) =>
            costCenter.find((center) => center.id === option)?.name || option
          }
          {...register("costCenter", {
            required: entryTypeSelect?.CostCenterMandatory,
          })}
        >
          {costCenter.map((center) => (
            <MenuItem
              key={center.id}
              value={center.id}
              style={styles.selectOptionContainer}
              disabled={!center?.active}
            >
              <snap style={styles.selectOptionTitle}>
                {center.name} {!center?.active && "(inactiva)"}
              </snap>
              <snap style={styles.selectOptionSubtitle}>{center.code}</snap>
            </MenuItem>
          ))}
        </ExpensesInputSelect>
      )}
      {isDebtPayment && items.length > 0 && (
        <Stack spacing={1}>
          <label style={styles.inputLabel}>Deuda pendiente en Siigo</label>
          <CustomDataGrid
            columns={debsSiigoExpensesColumns(
              handleOnChangeAmountRegister,
              toggleSelectedRow,
              isChecked
            )}
            rows={items}
          />
        </Stack>
      )}
      {isDebtPayment && items.length === 0 && <AlertSupplierNotDeb />}
      {amountTotalSelect > 0 &&
        amountTotalSelect !== receiptDetails.receipts.total && (
          <BalanceText
            amountTotalSelect={amountTotalSelect}
            receipt={receiptDetails.receipts}
          />
        )}
      <Comments comment={comment} />
    </Stack>
  );
}
