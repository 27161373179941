import { Tooltip } from "commons/components";
import React, { useState } from "react";

export const InputDescription = ({ valueRow, isCaused = false, onChange }) => {
  const [value, setValue] = useState(valueRow);

  const handleOnChange = (value) => {
    setValue(value);
  };
  return (
    <Tooltip title={value}>
      <input
        type="text"
        value={value}
        disabled={isCaused}
        onChange={(e) => handleOnChange(e.target.value)}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          background: "transparent",
          textOverflow: "ellipsis",
          outline: "none",
        }}
        onKeyDown={(event) => {
          if (
            event.key === " " ||
            event.key === "ArrowRight" ||
            event.key === "ArrowLeft"
          ) {
            event.stopPropagation();
          }
        }}
        tabIndex={0}
        onBlur={() => onChange(value)}
      />
    </Tooltip>
  );
};
