import { createContext, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { toastOptions } from "../constants";
import {
  CreateEmployee,
  UpdateEmployee,
  GetEmployees,
  GetEmployee,
  DeleteEmployee,
  GetPayroll,
  CreatePayroll,
  UpdatePayroll,
  CreatePayrollfromEmployees,
  DeletePayrollItem,
  DeletePayroll,
  ClonePayroll,
  MarkAsPaid,
  UpdateMultiplePayrolls,
  GetBOEmployee,
  UpdateBOEmployee,
} from "../services";

export const PayrollContext = createContext();

export function PayrollProvider({ children }) {
  const navigate = useNavigate();
  // EMPLOYEES
  const [employees, setEmployees] = useState([]);

  const getEmployeeDetails = (isBO, id) =>
    isBO ? getBOEmployee(id) : getEmployee(id);

  const updateEmployeeDetails = (isBO, id, formValues) =>
    isBO ? updateBOEmployee(id, formValues) : updateEmployee(id, formValues);

  const getEmployees = async (pageIndex, employeeId = "all") => {
    const gottenEmployees = await GetEmployees({
      pageIndex,
      employeeId,
    });
    if (gottenEmployees !== null) {
      setEmployees(gottenEmployees.data);
    }
  };

  const getEmployee = async (id) => {
    if (id) {
      const gottenEmployee = await GetEmployee({
        id,
      });
      return gottenEmployee.employee;
    }
  };

  const getBOEmployee = async (id) => {
    if (id) {
      const gottenEmployee = await GetBOEmployee({
        id,
      });
      return gottenEmployee.employee;
    }
  };

  const createEmployee = async (formValues) => {
    const response = await CreateEmployee({
      formValues: formValues,
    });
    if (response) {
      getEmployees(1);
      toast.success("¡El empleado se creó con éxito!", toastOptions);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const updateEmployee = async (id, formValues) => {
    const response = await UpdateEmployee({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      toast.success("¡El empleado se actualizó con éxito!", toastOptions);
      getEmployees(1);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const updateBOEmployee = async (id, formValues) => {
    const response = await UpdateBOEmployee({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      toast.success("¡El empleado se actualizó con éxito!", toastOptions);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const deleteEmployee = async (id) => {
    const response = await DeleteEmployee({ id: id });
    if (response) {
      getEmployees(1);
      toast.success("¡El empleado se eliminó con éxito!", toastOptions);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  // PAYROLL
  const [payrolls, setPayrolls] = useState([]);
  const [uploadFileDrawerIsOpen, setUploadFileDrawerIsOpen] = useState(false);
  const [newPayrollItemDrawerIsOpen, setNewPayrollItemDrawerIsOpen] =
    useState(false);
  const [payrollTotalAmount, setPayrollTotalAmount] = useState(0);
  const [payrollTotalEmployees, setPayrollTotalEmployees] = useState(0);
  const [repeatPayrollModalIsOpen, setRepeatPayrollModalIsOpen] =
    useState(false);
  const [selectPeriodModalIsOpen, setSelectPeriodModalIsOpen] = useState(false);
  const [
    payrollHasDifferencesModalIsOpen,
    setPayrollHasDifferencesModalIsOpen,
  ] = useState(false);

  const getPayrolls = async (pageIndex, employeeId = "all") => {
    const response = await GetPayroll({
      pageIndex,
      employeeId,
    });
    if (response) {
      setPayrolls([...response.payroll.data]);
    }
    setPayrollTotalAmount(response.total_amount);
    setPayrollTotalEmployees(parseInt(response.total_employees));
  };

  const addSinglePayroll = async (formValues, replacePayroll = false) => {
    if (payrolls && payrolls.length) {
      const payrollExists = payrolls.findIndex(
        (payroll) => payroll.employee_id === formValues.employee_id
      );
      if (payrollExists !== -1 && !replacePayroll) {
        setRepeatPayrollModalIsOpen(true);
      } else if (payrollExists !== -1 && replacePayroll) {
        const response = await updateFullPayroll(
          payrolls[payrollExists].id,
          formValues
        );
        if (response) {
          toast.success("¡La nómina se actualizó con éxito!", toastOptions);
        } else {
          toast.error("Hubo un error.", toastOptions);
        }
      } else if (payrollExists === -1) {
        const response = await CreatePayroll({
          formValues: formValues,
        });
        if (response) {
          getPayrolls(1);
          toast.success("¡La nómina se creó con éxito!", toastOptions);
          navigate("/payroll/salaries", { replace: true });
        } else {
          toast.error("Hubo un error.", toastOptions);
        }
      }
    } else {
      const response = await CreatePayroll({
        formValues: formValues,
      });
      if (response) {
        getPayrolls(1);
        toast.success("¡La nómina se creó con éxito!", toastOptions);
        navigate("/payroll/salaries", { replace: true });
      } else {
        toast.error("Hubo un error.", toastOptions);
      }
    }
  };

  const updatePayrollAmount = async (
    period,
    id,
    newAmount,
    showToast = true
  ) => {
    const response = await UpdatePayroll({
      id: parseInt(id),
      formValues: { amount: newAmount, period: period },
    });
    if (response) {
      getPayrolls(1);
      showToast &&
        toast.success("¡La nómina se actualizó con éxito!", toastOptions);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const updateFullPayroll = async (id, formValues) => {
    const response = await UpdatePayroll({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      getPayrolls(1);
      return response;
    }
  };

  const updateMultiplePayrollsPeriod = async (ids, period) => {
    const response = await UpdateMultiplePayrolls(ids, period);
    if (response) {
      getPayrolls(1);
      toast.success("¡La nómina se actualizó con éxito!", toastOptions);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const markAsPaid = async () => {
    const response = await MarkAsPaid();
    if (response) {
      toast.success("¡La nómina se actualizó con éxito!", toastOptions);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const deleteSinglePayroll = async (id) => {
    const response = await DeletePayrollItem({ id: id });
    if (response) {
      getPayrolls(1);
      toast.success("¡La nómina se actualizó con éxito!", toastOptions);
    }
    return response;
  };

  const deleteFullPayroll = async () => {
    const response = await DeletePayroll();
    if (response) {
      getPayrolls(1);
      toast.success("¡La nómina se borró con éxito!", toastOptions);
    }
    return response;
  };

  const createPayrollByEmployees = async (period) => {
    const response = await CreatePayrollfromEmployees(period);
    if (response) {
      getPayrolls(1);
      toast.success("¡La nómina se creó con éxito!", toastOptions);
      navigate("/payroll/salaries", { replace: true });
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const createPayrollByTrx = async (id, period) => {
    const response = await ClonePayroll(id, period);
    if (response) {
      getPayrolls(1);
      if (response.deletedEmployees) {
        setPayrollHasDifferencesModalIsOpen(true);
      }
      toast.success("¡La nómina se creó con éxito!", toastOptions);
      navigate("/payroll/salaries", { replace: true });
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  return (
    <PayrollContext.Provider
      value={{
        employees,
        getEmployees,
        getEmployee,
        createEmployee,
        updateEmployee,
        deleteEmployee,
        payrolls,
        payrollTotalAmount,
        payrollTotalEmployees,
        getPayrolls,
        markAsPaid,
        createPayrollByEmployees,
        createPayrollByTrx,
        payrollHasDifferencesModalIsOpen,
        setPayrollHasDifferencesModalIsOpen,
        uploadFileDrawerIsOpen,
        repeatPayrollModalIsOpen,
        setRepeatPayrollModalIsOpen,
        setUploadFileDrawerIsOpen,
        selectPeriodModalIsOpen,
        setSelectPeriodModalIsOpen,
        newPayrollItemDrawerIsOpen,
        setNewPayrollItemDrawerIsOpen,
        addSinglePayroll,
        updatePayrollAmount,
        deleteFullPayroll,
        deleteSinglePayroll,
        updateMultiplePayrollsPeriod,
        getBOEmployee,
        updateBOEmployee,
        getEmployeeDetails,
        updateEmployeeDetails,
      }}
    >
      {children}
    </PayrollContext.Provider>
  );
}
