import React, { useMemo, useState, useEffect, useContext, useRef } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  AttentionModal,
  DeleteItemModal,
  RenderIfPermissionEnabled,
  Submenu,
  Table,
  TableEmptyState,
  TableSkeleton,
  Tooltip,
  UploadFileModal,
} from "../../commons";
import {
  suppliersColumns,
  paymentsScreens,
  filterOptions,
  servicesColumns,
  filterServicesOptions,
} from "../../utils";
import { styles } from "./styles";
import {
  GetTotalServices,
  GetTotalSuppliers,
  SearchCreatedServices,
  SearchSuppliers,
} from "../../services";
import { PaymentsContext } from "../../contexts";
import { SupplierDetails } from "../../commons/modals/SupplierDetails/SupplierDetails";
import { useLocation } from "react-router-dom";
import { AgendaFilter, NewSupplierDropdown } from "./components";
import { DeleteIcon, EditIcon, SearchIcon } from "../../assets";
import { useMediaQuery } from "react-responsive";
import CsvHandler from "../../commons/modals/UploadFileModal/CsvHandler";
import { useChat } from "../../hooks";
import { permissions } from "../../constants";

export const SuppliersScreen = (props) => {
  const supColumns = useMemo(() => suppliersColumns, []);
  const servColumns = useMemo(() => servicesColumns, []);
  const [pageIndex, setPageIndex] = useState(1);
  const [totals, setTotals] = useState();
  const {
    suppliers,
    getSuppliers,
    deleteSupplier,
    suppliersScreen,
    services,
    getServices,
    deleteService,
  } = useContext(PaymentsContext);
  const [deleteTitle, setDeleteTitle] = useState(
    "¿Quieres borrar este proveedor?"
  );
  const [totalPages, setTotalPages] = useState(1);
  const [deleteSupplierModalIsVisible, setDeleteSupplierModalIsVisible] =
    useState(false);
  const [supplierToDelete, setSupplierToDelete] = useState();
  const [typeSelected, setTypeSelected] = useState("supplier");
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [entity, setEntity] = useState("supplier");
  const [uploadFileModalIsVisible, setUploadFileModalIsVisible] =
    useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [searchOptions, setSearchOptions] = useState([]);
  const location = useLocation();
  const suppliersCsvButton = useRef(null);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const [
    isDeleteSupplierWithInvoicesWarningVisible,
    setIsDeleteSupplierWithInvoicesWarningVisible,
  ] = useState(false);

  useChat();

  const providerIdSelected = suppliersScreen?.providerIdSelected?.value;
  const setProviderIdSelected = suppliersScreen?.providerIdSelected?.setter;

  useEffect(() => {
    GetTotalSuppliers({
      providerId: providerIdSelected,
    }).then((res) => {
      setTotals(res);
    });
    location.state &&
      location.state.openCreateModal &&
      openEmptySupplierDrawer();
    location.state &&
      location.state.openEditModal &&
      openSupplierDrawer(location.state.providerId);
  }, []);

  useEffect(() => {
    setProviderIdSelected("all");
    if (typeSelected === "supplier") {
      GetTotalSuppliers({
        providerId: providerIdSelected,
      }).then((res) => {
        setTotals(res);
      });
      setDeleteTitle("¿Quieres borrar este proveedor?");
      setEntity("supplier");
    } else {
      GetTotalServices({
        serviceId: providerIdSelected,
      }).then((res) => {
        setTotals(res);
      });
      setDeleteTitle("¿Quieres borrar este servicio?");
      setEntity("service");
    }
  }, [typeSelected]);

  const getSearchOptions = (query) => {
    typeSelected === "supplier"
      ? SearchSuppliers(query).then((res) => {
          setSearchOptions(res);
        })
      : SearchCreatedServices(query).then((res) => {
          setSearchOptions(res);
        });
  };

  const openDeleteSupplierModal = (row) => {
    if (typeSelected === "supplier" && row.hasInvoices) {
      setIsDeleteSupplierWithInvoicesWarningVisible(true);
    } else {
      setSupplierToDelete(row.id);
      setDeleteSupplierModalIsVisible(true);
    }
  };

  useEffect(() => {
    if (typeSelected === "supplier") {
      getSuppliers(1, providerIdSelected);
      GetTotalSuppliers({
        providerId: providerIdSelected,
      }).then((res) => {
        setTotals(res);
      });
      setDeleteTitle("¿Quieres borrar este proveedor?");
      setEntity("supplier");
    } else {
      getServices(1, providerIdSelected);
      GetTotalServices({
        serviceId: providerIdSelected,
      }).then((res) => {
        setTotals(res);
      });
      setDeleteTitle("¿Quieres borrar este servicio?");
      setEntity("service");
    }
  }, [providerIdSelected, typeSelected]);

  useEffect(() => {
    if (typeSelected === "supplier") {
      getSuppliers(pageIndex, providerIdSelected);
    } else {
      getServices(pageIndex, providerIdSelected);
    }
  }, [pageIndex]);

  useEffect(() => {
    if (totals) {
      if (totals.total > 100) {
        setTotalPages(Math.ceil(totals.total / 100));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const removeSupplier = (id) => {
    deleteSupplier(id);
    setDeleteSupplierModalIsVisible(false);
  };

  const removeService = (id) => {
    deleteService(id);
    setDeleteSupplierModalIsVisible(false);
  };

  const openSupplierDrawer = (id) => {
    setDrawerIsOpen(true);
    setSelectedSupplier(id);
  };
  const openEmptySupplierDrawer = () => {
    setEntity("supplier");
    setDrawerIsOpen(true);
  };

  const openEmptyServiceDrawer = () => {
    setEntity("service");
    setDrawerIsOpen(true);
  };

  const openUploadFileModal = () => {
    suppliersCsvButton.current.click();
  };

  const closeUploadFileModal = () => {
    getSuppliers(1, "all");
    getServices(1, "all");
    setUploadFileModalIsVisible(false);
  };

  const handleSearchChange = (sup) => {
    if (sup) {
      setProviderIdSelected(sup.id);
      suppliersScreen?.supplierFilter?.setter(sup);
    } else {
      setProviderIdSelected("all");
      suppliersScreen?.supplierFilter?.setter({});
    }
    setPageIndex(1);
  };

  const renderSuppliersAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
        freeSolo
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onChange={(event, option) => {
          if (option?.id) {
            handleSearchChange(option);
          } else {
            handleSearchChange();
          }
        }}
        onInputChange={(event) => {
          if (event?.target?.value?.length >= 3) {
            getSearchOptions(event.target.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Buscar proveedor"}
          />
        )}
        value={
          suppliersScreen?.supplierFilter?.value || {
            name: "",
          }
        }
      />
    );
  };

  const renderServicesAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
        freeSolo
        filterOptions={filterServicesOptions}
        getOptionLabel={(option) => {
          return option?.alias || "";
        }}
        onChange={(event, option) => {
          if (option?.id) {
            handleSearchChange(option);
          } else {
            handleSearchChange();
          }
        }}
        onInputChange={(event) => {
          if (event?.target?.value?.length >= 3) {
            getSearchOptions(event.target.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Buscar servicio"}
          />
        )}
        value={
          suppliersScreen?.supplierFilter?.value || {
            alias: "",
          }
        }
      />
    );
  };

  const renderButtons = (row) => {
    return (
      <>
        <RenderIfPermissionEnabled permission={permissions.INVOICES_UPDATE}>
          <Tooltip title="Editar">
            <IconButton onClick={() => openSupplierDrawer(row.original.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </RenderIfPermissionEnabled>
        <RenderIfPermissionEnabled permission={permissions.INVOICES_DELETE}>
          <Tooltip title="Eliminar">
            <IconButton onClick={() => openDeleteSupplierModal(row.original)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </RenderIfPermissionEnabled>
      </>
    );
  };

  const renderRowEndButtons = (row) => {
    if (isMobile) {
      return <>{renderButtons(row)}</>;
    } else {
      return (
        <td style={{ backgroundColor: "transparent" }}>{renderButtons(row)}</td>
      );
    }
  };

  const handleCloseDeleteSupplierWithInvoicesWarningModal = () => {
    setIsDeleteSupplierWithInvoicesWarningVisible(null);
  };

  return (
    <>
      <CsvHandler
        entity="supplier"
        licenseKey={process.env.REACT_APP_CSVBOX_SUPPLIERS_LICENSE_KEY}
        buttonRef={suppliersCsvButton}
        setTotals={setTotals}
      />
      <DeleteItemModal
        itemId={supplierToDelete}
        action={typeSelected === "supplier" ? removeSupplier : removeService}
        handleClose={() => {
          setDeleteSupplierModalIsVisible(false);
          setProviderIdSelected("all");
        }}
        title={deleteTitle}
        subtitle={"Si lo eliminas, no aparecerá más en Payana"}
        visible={deleteSupplierModalIsVisible}
      />
      <UploadFileModal
        entity={"invoices"}
        type={"SupplierCSV"}
        visible={uploadFileModalIsVisible}
        handleClose={closeUploadFileModal}
      />
      <SupplierDetails
        id={selectedSupplier}
        isOpen={drawerIsOpen}
        handleClose={() => {
          setSelectedSupplier(null);
          setDrawerIsOpen(false);
          if (typeSelected === "supplier") {
            setEntity("supplier");
            setTypeSelected("supplier");
          } else {
            setEntity("service");
            setTypeSelected("service");
          }
          setDrawerIsOpen(false);
          setPageIndex(1);
        }}
        needRedirectionCreate={
          location.state ? location.state.openCreateModal : false
        }
        needRedirectionEdit={
          location.state ? location.state.openEditModal : false
        }
        isOnlySupplier={entity === "supplier"}
        isOnlyService={entity === "service"}
        pageIndex={pageIndex}
        providerId={providerIdSelected}
      />
      <AttentionModal
        title="No puedes eliminar este proveedor"
        description="Tienes facturas asociadas a este proveedor, no podemos eliminarlo. Para continuar, elimina las facturas correspondientes"
        onClose={handleCloseDeleteSupplierWithInvoicesWarningModal}
        open={!!isDeleteSupplierWithInvoicesWarningVisible}
      />
      <div>
        <Submenu items={paymentsScreens} />
        <div className="layout-container">
          <div style={styles.titleContainer}>
            <Typography sx={styles.title} noWrap>
              Agenda
            </Typography>
            <RenderIfPermissionEnabled permission={permissions.INVOICES_CREATE}>
              <NewSupplierDropdown
                openUploadFileModal={openUploadFileModal}
                openEmptySupplierDrawer={openEmptySupplierDrawer}
                openEmptyServiceDrawer={openEmptyServiceDrawer}
                type={typeSelected}
              />
            </RenderIfPermissionEnabled>
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              margin: "0px 2.5%",
              justifyContent: "space-between",
              flex: "0 0 auto",
            }}
          >
            <AgendaFilter
              typeSelected={typeSelected}
              setTypeSelected={setTypeSelected}
              setPageIndex={setPageIndex}
            />
          </Box>
          {typeSelected === "supplier" ? (
            suppliers.length < 1 ? (
              <TableEmptyState
                title={"Tu empresa aún no tiene proveedores cargados"}
                subtitle={
                  "Aquí se mostrarán los proveedores de tus facturas por pagar."
                }
              />
            ) : suppliers.length >= 1 ? (
              <Table
                columns={supColumns}
                data={suppliers}
                editIcon
                editAction={openSupplierDrawer}
                setPageIndex={setPageIndex}
                checkboxVisible={false}
                pageCount={totalPages}
                deleteIcon
                openDeleteItemModal={openDeleteSupplierModal}
                section="suppliers"
                supplier={suppliersScreen?.supplierFilter?.value}
                setSupplier={suppliersScreen?.supplierFilter?.setter}
                renderAutocomplete={renderSuppliersAutocomplete}
                pageNumber={pageIndex}
                renderRowEndButtons={renderRowEndButtons}
              />
            ) : (
              <TableSkeleton />
            )
          ) : services.length < 1 ? (
            <TableEmptyState
              title={"Tu empresa aún no tiene servicios cargados"}
              subtitle={
                "Aquí se mostrarán los servicios de tus facturas por pagar."
              }
            />
          ) : services.length >= 1 ? (
            <Table
              columns={servColumns}
              data={services}
              editIcon
              editAction={openSupplierDrawer}
              setPageIndex={setPageIndex}
              checkboxVisible={false}
              pageCount={totalPages}
              deleteIcon
              openDeleteItemModal={openDeleteSupplierModal}
              section="services"
              supplier={suppliersScreen?.supplierFilter?.value}
              setSupplier={suppliersScreen?.supplierFilter?.setter}
              renderAutocomplete={renderServicesAutocomplete}
              pageNumber={pageIndex}
              renderRowEndButtons={renderRowEndButtons}
            />
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </>
  );
};
