import { createContext, useContext, useState } from "react";
import {
  CreateTransaction,
  DeleteCollectionTransaction,
  DeleteTransaction,
  GetCollectionTransactions,
  GetReceiptsV2,
  GetTotalReceipts,
  GetTransactions,
  GetTransactionsV2,
} from "../services";
import { PayrollContext } from "./PayrollContext";
import { beneficiaryTypeOptions } from "constants";

export const TransactionsContext = createContext();

export function TransactionsProvider({ children }) {
  const [transactions, setTransactions] = useState([]);
  const [invoiceTransactions, setInvoiceTransactions] = useState([]);
  const [invoiceReceipts, setInvoiceReceipts] = useState([]);
  const [payrollTransactions, setPayrollTransactions] = useState([]);
  const [collectionTransactions, setCollectionTransactions] = useState([]);
  const [companyHasTrxs, setCompanyHasTrxs] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const { payrolls } = useContext(PayrollContext);

  const [
    beneficiaryForTransactionsScreen,
    setBeneficiaryForTransactionsScreen,
  ] = useState({});
  const [
    beneficiaryIdSelectedForTransactionsScreen,
    setBeneficiaryIdSelectedForTransactionsScreen,
  ] = useState("all");

  const getTransactions = async (pageIndex, type, query = "") => {
    const gottenTransactions = await GetTransactions(pageIndex, type, query);
    if (gottenTransactions !== null) {
      if (type === "payroll") {
        setPayrollTransactions(gottenTransactions.data);
      } else {
        setInvoiceTransactions(gottenTransactions.data);
      }
    }
  };

  const getTransactionsV2 = async (
    pageIndex,
    beneficiaryIdSelected = "all",
    beneficiaryType = "provider",
    startDate,
    endDate,
    transactionType = "invoice,payroll,collection"
  ) => {
    const gottenTransactions = await GetTransactionsV2(
      pageIndex,
      beneficiaryIdSelected,
      beneficiaryTypeOptions[beneficiaryType] || "provider_id",
      startDate,
      endDate,
      transactionType
    );
    if (gottenTransactions !== null) {
      setTransactions(gottenTransactions.data);
    }
  };

  const getCollectionTransactions = async (pageIndex, customerId = "all") => {
    const gottenTransactions = await GetCollectionTransactions(
      pageIndex,
      customerId
    );
    if (gottenTransactions !== null) {
      setCollectionTransactions(gottenTransactions);
    }
  };

  const getReceipts = async (pageIndex, providerId, statusSelected) => {
    const gottenReceipts = await GetReceiptsV2({
      pageIndex,
      providerId,
      status: statusSelected,
    });
    if (gottenReceipts !== null) {
      setInvoiceReceipts(gottenReceipts);
    }
  };

  const getTotalReceipts = async (providerId, status) =>
    GetTotalReceipts({
      providerId,
      status,
    });

  const createPayrollPayLaterTransaction = async () => {
    try {
      const ids = payrolls.map((payroll) => payroll.id);
      return CreateTransaction({
        ids: ids,
        type: "payroll",
        status: "pay_later",
      }).then((res) => res.data || res.response.data);
    } catch (error) {
      return "error";
    }
  };

  const createInvoicesPayLaterTransaction = async (ids) => {
    try {
      return CreateTransaction({
        ids: ids,
        type: "invoice",
        status: "pay_later",
      }).then((res) => res.data || res.response.data);
    } catch (error) {
      return "error";
    }
  };

  const createCollectionsPayLaterTransaction = async (ids) => {
    const response = await CreateTransaction({
      ids: ids,
      type: "collection",
      status: "pay_later",
    });
    if (response) {
      getTransactions(1, "collection");
      return 200;
    } else {
      return "error";
    }
  };

  const deletePendingTransaction = async (id, type) => {
    const response = await DeleteTransaction({ id: id });
    if (response) {
      getTransactions(1, type);
      return 200;
    } else {
      return "error";
    }
  };

  const deletePendingCollectionTransaction = async (id, type) => {
    const response = await DeleteCollectionTransaction({ id: id });
    if (response) {
      getCollectionTransactions(1);
      return 200;
    } else {
      return "error";
    }
  };

  return (
    <TransactionsContext.Provider
      value={{
        transactions,
        invoiceTransactions,
        invoiceReceipts,
        payrollTransactions,
        collectionTransactions,
        getTransactions,
        getTransactionsV2,
        getReceipts,
        getTotalReceipts,
        createPayrollPayLaterTransaction,
        createInvoicesPayLaterTransaction,
        createCollectionsPayLaterTransaction,
        deletePendingTransaction,
        companyHasTrxs,
        setCompanyHasTrxs,
        getCollectionTransactions,
        deletePendingCollectionTransaction,
        transactionsScreen: {
          beneficiaryFilter: {
            value: beneficiaryForTransactionsScreen,
            setter: setBeneficiaryForTransactionsScreen,
          },
          beneficiaryIdSelected: {
            value: beneficiaryIdSelectedForTransactionsScreen,
            setter: setBeneficiaryIdSelectedForTransactionsScreen,
          },
          pageIndex,
          setPageIndex,
        },
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
}
