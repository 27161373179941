export const paymentsScreens = [
  {
    name: "Cuentas por pagar",
    to: "/payments/invoices",
  },
  {
    name: "Lotes",
    to: "/payments/transactions",
  },
  {
    name: "Agenda",
    to: "/payments/suppliers",
  },
];

export const payrollScreens = [
  {
    name: "Pago de nómina",
    to: "/payroll/salaries",
  },
  {
    name: "Lotes",
    to: "/payroll/transactions",
  },
  {
    name: "Empleados",
    to: "/payroll/employees",
  },
];

export const collectionsMenuScreens = [
  {
    name: "Cuentas por cobrar",
    to: "/collections/index",
  },
  {
    name: "Agenda",
    to: "/collections/customers",
  },
];
