import { Box, Stack, Typography } from "@mui/material";
import { styles } from "./styles";
import { CheckIcon, EmptyStateIcon } from "../../../assets";
import { PrimaryButton } from "../../buttons";

export const TableEmptyState = ({
  title,
  subtitle,
  hasButton = false,
  buttonAction,
  buttonText,
  hasBullets,
  bulletpoints,
}) => {
  return (
    <Box sx={styles.box}>
      <Stack spacing={2} p={4} sx={styles.stack}>
        <EmptyStateIcon />
        <Box>
          <Typography mb={1} sx={styles.emptyStateTitle}>
            {title}
          </Typography>
          <Typography sx={styles.emptyStateSubtitle}>{subtitle}</Typography>
          {hasButton && (
            <Box my={2}>
              <PrimaryButton text={buttonText} action={buttonAction} />
            </Box>
          )}
          {hasBullets && (
            <Box my={2}>
              {bulletpoints.length &&
                bulletpoints.map((point) => {
                  return (
                    <Stack
                      alignItems={"center"}
                      my={1}
                      direction={"row"}
                      spacing={1}
                      key={point}
                    >
                      <CheckIcon />
                      <Typography sx={styles.emptyStateSubtitle}>
                        {point}
                      </Typography>
                    </Stack>
                  );
                })}
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
