export const styles = {
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  modalSaveButton: {
    backgroundColor: "#6938EF",
    border: "1px solid #6938EF",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "14px",
    marginLeft: "8px",
  },
  inputLabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  container: {
    margin: "10px auto",
    width: "100%",
  },
  tableContainer: { width: "95%", margin: "0px auto" },
  textContainerAddRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textAddRow: {
    textAlign: "center",
    margin: "20px 0px",
    color: "#5925DC",
    textDecoration: "none",
  },
  countGeneralBoxContainer: {
    minWidth: 360,
    maxWidth: 502,
    width: "100%",
  },
  countGeneralStackContainer: {
    backgroundColor: "rgb(239, 242, 246)",
    borderColor: "rgb(239, 242, 246)",
    borderRadius: "8px",
    padding: "24px 32px",
  },
  taxesContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    padding: "0px 12px",
    margin: "0px -12px",
  },
  taxesSelect: {
    minWidth: "150px",
    fontSize: "14px",
    "& fieldset": {
      borderTop: "none",
      borderBottom: "none",
      borderLeft: "none",
      borderRadius: "0px",
    },
  },
  totalText: {
    color: "#1D2939",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
  },
  totalTextNum: {
    color: "#1D2939",
    fontAize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
  },
  bannerContainer: {
    borderBottom: "1px solid #EAECF0",
    background: "#FFFAEB",
    textAlign: "center",
    padding: "8px 0px",
  },
  bannerText: { color: "#7A2E0E", margin: "6px 0px", fontSize: "14px" },
  switchContainer: {
    marginTop: "24px",
    width: "100%",
    height: "28px",
    backgroundColor: "#F9FAFB",
    borderRadius: "8px",
    padding: "24px 24px 24px 0px",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
  },
  countGeneralText: {
    color: "#475467",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
  },
  countGeneralTextNum: {
    color: "#1D2939",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  },
};
