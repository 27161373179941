import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { isAdvance, typePaymentOnChange } from "../../constants";

export const PaymentSelect = ({
  paymentsSearch,
  payment,
  handleOnChangePayment,
  index,
}) => {
  const getOptionLabel = (option) => {
    if (option?.accounting_concept === isAdvance) return "Cruzar anticipo";

    if (option?.name && option?.accounting_concept) {
      return `${option.name} - ${option.accounting_concept}`;
    }

    return "";
  };

  return (
    <Autocomplete
      name="paymentType"
      size="small"
      options={paymentsSearch}
      value={payment}
      getOptionLabel={(option) => getOptionLabel(option)}
      filterOptions={(options, { inputValue }) => {
        const inputValueLower = inputValue.toLowerCase();
        return options.filter(
          (option) =>
            option?.name?.toLowerCase()?.includes(inputValueLower) ||
            option?.accounting_concept
              ?.toString()
              ?.toLowerCase()
              ?.includes(inputValueLower)
        );
      }}
      onChange={(event, value) => {
        handleOnChangePayment(index, value, typePaymentOnChange.payment);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option?.accounting_concept}>
            {option.name} - {option.accounting_concept}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: 14 },
          }}
          placeholder="Ingresa la forma de pago"
        />
      )}
      sx={{
        width: "100%",
        "& .MuiAutocomplete-clearIndicator": {
          display: payment?.accounting_concept === "" ? "none" : "",
          visibility: payment?.accounting_concept === "" ? "" : "visible",
        },
        "& .MuiAutocomplete-popupIndicator": {
          display: payment?.accounting_concept !== "" ? "none" : "",
        },
      }}
    />
  );
};
