export const RejectedIcon = ({
  fill = "#FEE4E2",
  stroke = "#D92D20",
  width = 56,
  height = 56,
  circle = true,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={circle ? "0 0 56 56" : "0 0 20 20"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {circle ? (
      <>
        <rect width={56} height={56} rx={28} fill={fill} />
        <path
          d="M27.9999 23.3333V27.9999M27.9999 32.6666H28.0116M39.6666 27.9999C39.6666 34.4432 34.4432 39.6666 27.9999 39.6666C21.5566 39.6666 16.3333 34.4432 16.3333 27.9999C16.3333 21.5566 21.5566 16.3333 27.9999 16.3333C34.4432 16.3333 39.6666 21.5566 39.6666 27.9999Z"
          stroke={stroke}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <path
        d="M9.99984 6.66675V10.0001M9.99984 13.3334H10.0082M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z"
        stroke="#667085"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    )}
  </svg>
);
