export const styles = {
  emptyStateBox: {
    minHeight: "400px",
    display: "flex",
    justifyContent: "center",
    maxWidth: "480px",
    gap: "4px",
    height: "100%",
  },
  emptyStateStack: {
    maxWidth: "488px",
    alignItems: "center",
    width: "90%",
    padding: "32px",
    backgroundColor: "#FFFFFF",
    marginTop: "30px",
    borderRadius: "12px",
  },
  emptyStateBtnInGroup: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    borderRadius: "8px",
    padding: "14px 16px",
    border: "1px solid #EAECF0",
    background: "#FFF",
    textTransform: "inherit",
    textAlign: "inherit",
    justifyContent: "flex-start",
    color: "#344054",
    WebkitTransition: "none",
    transition: "none",
    "&:hover": {
      border: "1px solid #EAECF0",
    },
    "&:disabled": {
      borderColor: "#5925dc80",
    },
    "& .MuiButton-startIcon": {
      width: "46px",
      height: "36px",
      borderRadius: "4px",
      background: "#FFF",
      boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
      margin: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "flex-start",
    },
  },
  container: {
    width: "100%",
  },
  rowSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  firstStatusOptionsSelected: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#5925DC",
    marginTop: "24px",
    borderBottom: "2px solid #7f56d9",
    borderRadius: "0px",
    textTransform: "unset !important",
    paddingBottom: "12px",
  },
  statusOptionsSelected: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#5925DC",
    marginTop: "24px",
    borderBottom: "2px solid #7f56d9",
    borderRadius: "0px",
    textTransform: "unset !important",
    paddingBottom: "12px",
  },
  firstStatusOptions: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#667085",
    marginTop: "24px",
    textTransform: "unset !important",
    paddingBottom: "12px",
  },
  separator: {
    height: "1px",
    width: "100%",
    backgroundColor: "#E9ECF0",
    margin: "revert",
    opacity: "1",
    border: "0",
    marginTop: "-2px",
  },
  actionsDropdownPadding: {
    padding: "2px 16px 2px 16px",
  },
  noPadding: {
    padding: "0px",
  },
  newDropdownPadding: {
    padding: "14px 16px 14px 16px",
  },
  dropdownItemContainer: {
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center",
  },
  dropdownItemIconContainer: {
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    margin: "0px",
  },
  dropdownItemTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "16px",
    textAlign: "initial",
    marginTop: "16px",
  },
  dropdownItemText: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "8px",
  },
  dropdownItemTitle: {
    color: "#1D2939",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "8px",
  },
  actionsDropdownItemText: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  actionsDropdownItemDeleteText: {
    color: "#B42318",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  titleContianer: {
    justifyContent: "space-between",
    display: "flex",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: "16px",
    alignItems: "center",
    height: "40px",
  },
  titleDianContainer: { display: "flex", flexDirection: "row", gap: "10px" },
  statusFilterContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    margin: "0px 2.5%",
    justifyContent: "space-between",
    flex: "0 0 auto",
  },
  emptyStateTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
  },
  emptyStateSubtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#475467",
  },
  buttonStepEmptyStateText: {
    color: "#667085",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
  },
  buttonStepEmptyStateTitle: {
    color: "#101828",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
  },
  emptyInfoText: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "flex-start",
    color: "#667085",
  },
  emptyRow: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    justifyContent: "flex-start",
    width: "100%",
  },
  containerComingSoonText: {
    padding: 24,
    background: "#FCFAFF",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.06)",
    borderRadius: 8,
    border: "1px #D6BBFB solid",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "16px",
    display: "flex",
  },
  title: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#101828",
  },
  titleContainer: {
    justifyContent: "space-between",
    display: "flex",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: "16px",
    alignItems: "center",
    height: "40px",
  },
};
