export const TimerIcon = () => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={56} height={56} rx={28} fill="#D0D5DD" />
    <path
      d="M28.0001 28L23.015 23.8458C22.2744 23.2286 21.9041 22.92 21.6378 22.5417C21.4019 22.2065 21.2268 21.8325 21.1203 21.4367C21.0001 20.99 21.0001 20.5079 21.0001 19.5438V16.3334M28.0001 28L32.9851 23.8458C33.7258 23.2286 34.0961 22.92 34.3623 22.5417C34.5982 22.2065 34.7734 21.8325 34.8799 21.4367C35.0001 20.99 35.0001 20.5079 35.0001 19.5438V16.3334M28.0001 28L23.015 32.1542C22.2744 32.7714 21.9041 33.0801 21.6378 33.4584C21.4019 33.7936 21.2268 34.1676 21.1203 34.5634C21.0001 35.0101 21.0001 35.4921 21.0001 36.4563V39.6667M28.0001 28L32.9851 32.1542C33.7258 32.7715 34.0961 33.0801 34.3623 33.4584C34.5982 33.7936 34.7734 34.1676 34.8799 34.5634C35.0001 35.0101 35.0001 35.4921 35.0001 36.4563V39.6667M18.6667 16.3334H37.3334M18.6667 39.6667H37.3334"
      stroke="#364152"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
