import { format } from "date-fns";

export const formatDate = (dateToFormat, inputDate = false) => {
  const date = new Date(dateToFormat);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate() + 1;

  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  const formattedDate = inputDate
    ? `${year}-${month}-${day}`
    : `${day}/${month}/${year}`;

  return formattedDate;
};

export const applyFormatDate = (date, formatStr = "dd/MM/yyyy") =>
  format(new Date(date), formatStr);
