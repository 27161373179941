import { createContext, useState } from "react";
import {
  DeleteSupplier,
  GetSuppliers,
  UpdateSupplier,
  CreateSupplier,
  GetAllBanks,
  GetSupplier,
  GetInvoice,
  CreateInvoice,
  UpdateInvoice,
  GetInvoicesInternal,
  GetBOSuppliers,
  ChargeWithholdings,
  MakePartialPayment,
  ModifyAmountTotal,
  DeleteInvoice,
  DeleteInvoices,
  UploadInvoicesFile,
  InvoicesApproval,
  GetAllSuppliers,
  CreateService,
  GetServices,
  GetService,
  DeleteService,
  UpdateService,
} from "../services";
import { MultiAccountModal } from "../commons/modals";
import { toast } from "react-toastify";
import { toastOptions } from "../constants";

export const PaymentsContext = createContext();

const DEFAULT_FILTER_CAUSATION = {
  label: "",
  value: "",
};

export function PaymentsProvider({ children }) {
  const [multiAccountModalIsOpen, setMultiAccountModalIsOpen] = useState(false);
  const [syncIsLoading, setSyncIsLoading] = useState(false);
  const [syncHasError, setSyncHasError] = useState(false);
  const [syncBtnIsDisabled, setSyncBtnIsDisabled] = useState(false);
  const [statusSelected, setStatusSelected] = useState("all");
  const [providerIdSelected, setProviderIdSelected] = useState("all");
  const [perPageSelected, setPerPageSelected] = useState(100);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [selectFilterCausation, setSelectFilterCausation] = useState({
    ...DEFAULT_FILTER_CAUSATION,
  });
  const [sortingSelected, setSortingQuery] = useState("");
  const [supplier, setSupplier] = useState({});
  const [pageIndex, setPageIndex] = useState(1);

  const [supplierForInvoicesScreen, setSupplierForInvoicesScreen] = useState(
    {}
  );
  const [serviceForInvoicesScreen, setServiceForInvoicesScreen] = useState({});
  const [
    providerIdSelectedForInvoicesScreen,
    setProviderIdSelectedForInvoicesScreen,
  ] = useState("all");
  const [
    serviceIdSelectedForInvoicesScreen,
    setServiceIdSelectedForInvoicesScreen,
  ] = useState("all");
  const [syncBannerForInvoicesScreen, setSyncBannerForInvoicesScreen] =
    useState({ visible: false, type: "", title: "", text: "" });

  const [supplierForSuppliersScreen, setSupplierForSuppliersScreen] = useState(
    {}
  );
  const [
    providerIdSelectedForSuppliersScreen,
    setProviderIdSelectedForSuppliersScreen,
  ] = useState("all");

  const [supplierForReceiptsScreen, setSupplierForReceiptsScreen] = useState(
    {}
  );
  const [
    providerIdSelectedForReceiptsScreen,
    setProviderIdSelectedForReceiptsScreen,
  ] = useState("all");

  // INVOICES
  const [invoices, setInvoices] = useState([]);

  const internalGetInvoices = async (
    pageIndex,
    status,
    providerId,
    serviceId,
    perPage,
    sorting,
    tags,
    causationFilter
  ) => {
    const gottenInvoices = await GetInvoicesInternal({
      pageIndex: pageIndex === 0 ? 1 : pageIndex,
      status: status || statusSelected,
      providerId: providerId || providerIdSelectedForInvoicesScreen,
      serviceId: serviceId || serviceIdSelectedForInvoicesScreen,
      perPage: perPage || perPageSelected,
      sorting: sorting || sortingSelected,
      tags: tags || tagsSelected,
      causation: causationFilter?.value || selectFilterCausation?.value,
    });
    if (gottenInvoices !== null) {
      setInvoices(
        (gottenInvoices.data || []).map((invoice) => ({
          ...invoice,
          numberOfNotes: invoice.number_of_notes || 0,
        }))
      );
    }
  };

  const getInvoice = async (id) => {
    if (id) {
      const gottenInvoice = await GetInvoice({
        id,
      });
      return gottenInvoice.data;
    }
  };

  const deleteSelectedInvoices = async (ids, pageIndex = 1) => {
    const response = await DeleteInvoices(ids);
    if (response) {
      internalGetInvoices(
        pageIndex,
        statusSelected,
        providerIdSelectedForInvoicesScreen,
        serviceIdSelectedForInvoicesScreen,
        perPageSelected,
        sortingSelected,
        tagsSelected
      );
      toast.success("¡Las facturas se borraron con éxito!", toastOptions);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const approveInvoices = async (ids) => {
    const response = await InvoicesApproval(ids);
    if (response) {
      internalGetInvoices(
        1,
        statusSelected,
        providerIdSelectedForInvoicesScreen,
        serviceIdSelectedForInvoicesScreen,
        perPageSelected,
        sortingSelected,
        tagsSelected
      );
      toast.success("¡Las facturas se aprobaron con éxito!", toastOptions);
      return 200;
    } else {
      toast.error("Hubo un error.", toastOptions);
      return "error";
    }
  };

  const deleteInvoice = async (invoiceId, pageIndex = 1) => {
    const response = await DeleteInvoice({ id: invoiceId });
    if (response) {
      internalGetInvoices(
        pageIndex,
        statusSelected,
        providerIdSelectedForInvoicesScreen,
        serviceIdSelectedForInvoicesScreen,
        perPageSelected,
        sortingSelected,
        tagsSelected
      );
      toast.success("¡La factura se borró con éxito!", toastOptions);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const changeStatus = (ids, status) => {
    const updatedInvoices = invoices.map((item, index) => {
      if (ids.includes(item.id)) {
        return {
          ...item,
          status: status,
        };
      } else {
        return item;
      }
    });
    setInvoices(updatedInvoices);
  };

  const changeAmount = async (
    value,
    id,
    withholdings,
    selectedOption,
    partialAmount,
    fullAmount,
    pageIndex
  ) => {
    let response = {};
    if (value === "1") {
      response = await ChargeWithholdings({
        id: id,
        amount: withholdings,
        type: selectedOption,
      });
    } else if (value === "2") {
      response = await MakePartialPayment({ id: id, amount: partialAmount });
    } else if (value === "3") {
      response = await ModifyAmountTotal({ id: id, amount: fullAmount });
    }
    if (response.message === "ok") {
      internalGetInvoices(
        pageIndex,
        statusSelected,
        providerIdSelectedForInvoicesScreen,
        serviceIdSelectedForInvoicesScreen,
        perPageSelected,
        sortingSelected,
        tagsSelected
      );
    }
    return response.status;
  };

  const createInvoice = async (formValues) => {
    const response = await CreateInvoice({
      formValues: formValues,
    });
    if (response) {
      await internalGetInvoices(
        1,
        statusSelected,
        providerIdSelectedForInvoicesScreen,
        serviceIdSelectedForInvoicesScreen,
        perPageSelected,
        sortingSelected,
        tagsSelected
      );
      return response;
    } else {
      return "error";
    }
  };

  const updateInvoice = async (id, formValues) => {
    const response = await UpdateInvoice({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      internalGetInvoices(
        1,
        statusSelected,
        providerIdSelectedForInvoicesScreen,
        serviceIdSelectedForInvoicesScreen,
        perPageSelected,
        sortingSelected,
        tagsSelected
      );
      return 200;
    } else {
      return "error";
    }
  };

  const uploadInvoiceFile = async (selectedFiles) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const fileToUpload = selectedFiles[0];
      return UploadInvoicesFile(fileToUpload);
    }
  };

  // SUPPLIERS
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [boSuppliers, setBOSuppliers] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [services, setServices] = useState([]);

  const getAllBanks = async (pageIndex = 1) => {
    const gottenBanks = await GetAllBanks({
      pageIndex,
    });
    if (gottenBanks !== null) {
      setAllBanks(gottenBanks);
    }
  };

  const getSuppliers = async (pageIndex, providerId) => {
    const gottenSuppliers = await GetSuppliers({
      pageIndex,
      providerId,
    });
    if (gottenSuppliers !== null) {
      setSuppliers(gottenSuppliers);
    }
  };

  const getAllSuppliers = async () => {
    const gottenSuppliers = await GetAllSuppliers();
    if (gottenSuppliers !== null) {
      setAllSuppliers(gottenSuppliers);
    }
  };

  const getBOSuppliers = async (pageIndex, providerId, companyId) => {
    const gottenSuppliers = await GetBOSuppliers({
      pageIndex,
      providerId,
      companyId,
    });
    if (gottenSuppliers !== null) {
      setBOSuppliers(gottenSuppliers);
    }
  };

  const getSupplier = async (id) => {
    if (id) {
      const gottenSupplier = await GetSupplier({
        id,
      });
      return gottenSupplier;
    }
  };

  const createSupplier = async (formValues) => {
    const response = await CreateSupplier({
      formValues: formValues,
    });
    if (response) {
      getSuppliers(1, "all");
      return response;
    }
    return "error";
  };

  const deleteSupplier = async (id) => {
    const response = await DeleteSupplier({ id: id });
    if (response) {
      const updatedSuppliers = suppliers.filter(
        (supplier) => supplier.id !== id
      );
      setSuppliers(updatedSuppliers);
      return 200;
    }
    return "error";
  };

  const updateSupplier = async (
    id,
    formValues,
    pageIndex = 1,
    providerId = "all"
  ) => {
    const response = await UpdateSupplier({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      getSuppliers(pageIndex, providerId);
      return 200;
    }
    return "error";
  };

  const createService = async (formValues) => {
    const response = await CreateService({
      formValues: formValues,
    });
    if (response) {
      getServices(1, "all");
      return response;
    }
    return "error";
  };

  const updateService = async (id, formValues) => {
    const response = await UpdateService({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      getServices(1, "all");
      return 200;
    }
    return "error";
  };

  const getServices = async (pageIndex, serviceId) => {
    const gottenServices = await GetServices({
      pageIndex,
      serviceId,
    });
    if (gottenServices !== null) {
      setServices(gottenServices);
    }
  };

  const getService = async (id) => {
    if (id) {
      const gottenService = await GetService({
        id,
      });
      return gottenService;
    }
  };

  const deleteService = async (id) => {
    const response = await DeleteService({ id: id });
    if (response) {
      const updatedServices = services.filter((service) => service.id !== id);
      setServices(updatedServices);
      return 200;
    }
    return "error";
  };

  // COLLECTIONS
  const [collections, setCollections] = useState([]);

  return (
    <PaymentsContext.Provider
      value={{
        invoices,
        internalGetInvoices,
        invoicesScreen: {
          supplierFilter: {
            value: supplierForInvoicesScreen,
            setter: setSupplierForInvoicesScreen,
          },
          serviceFilter: {
            value: serviceForInvoicesScreen,
            setter: setServiceForInvoicesScreen,
          },
          providerIdSelected: {
            value: providerIdSelectedForInvoicesScreen,
            setter: setProviderIdSelectedForInvoicesScreen,
          },
          serviceIdSelected: {
            value: serviceIdSelectedForInvoicesScreen,
            setter: setServiceIdSelectedForInvoicesScreen,
          },
          syncBanner: {
            value: syncBannerForInvoicesScreen,
            setter: setSyncBannerForInvoicesScreen,
          },
          pageIndex,
          setPageIndex,
        },
        suppliersScreen: {
          supplierFilter: {
            value: supplierForSuppliersScreen,
            setter: setSupplierForSuppliersScreen,
          },
          providerIdSelected: {
            value: providerIdSelectedForSuppliersScreen,
            setter: setProviderIdSelectedForSuppliersScreen,
          },
        },
        receiptsScreen: {
          supplierFilter: {
            value: supplierForReceiptsScreen,
            setter: setSupplierForReceiptsScreen,
          },
          providerIdSelected: {
            value: providerIdSelectedForReceiptsScreen,
            setter: setProviderIdSelectedForReceiptsScreen,
          },
        },
        setInvoices,
        createInvoice,
        updateInvoice,
        getInvoice,
        deleteSelectedInvoices,
        deleteInvoice,
        changeStatus,
        changeAmount,
        getSuppliers,
        getAllSuppliers,
        getBOSuppliers,
        suppliers,
        allSuppliers,
        boSuppliers,
        setSuppliers,
        setBOSuppliers,
        createSupplier,
        deleteSupplier,
        updateSupplier,
        getAllBanks,
        allBanks,
        getSupplier,
        syncIsLoading,
        setSyncIsLoading,
        syncHasError,
        setSyncHasError,
        syncBtnIsDisabled,
        setSyncBtnIsDisabled,
        multiAccountModalIsOpen,
        setMultiAccountModalIsOpen,
        uploadInvoiceFile,
        approveInvoices,
        statusSelected,
        setStatusSelected,
        providerIdSelected,
        setProviderIdSelected,
        perPageSelected,
        setPerPageSelected,
        sortingSelected,
        tagsSelected,
        setTagsSelected,
        setSortingQuery,
        supplier,
        setSupplier,
        createService,
        getService,
        collections,
        setCollections,
        getServices,
        services,
        setServices,
        deleteService,
        updateService,
        selectFilterCausation,
        setSelectFilterCausation,
      }}
    >
      {multiAccountModalIsOpen && <MultiAccountModal />}
      {children}
    </PaymentsContext.Provider>
  );
}
