import { Skeleton } from "@mui/material";

export const CustomSkeleton = () => {
  return (
    <div style={{ marginLeft: "2.5%", marginRight: "2.5%", marginTop: "24px" }}>
      {[...Array(5)].map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={"100%"}
          height={40}
          style={{ marginTop: "12px" }}
        />
      ))}
    </div>
  );
};
