import React, { useContext } from "react";
import { UserContext } from "../../contexts";
import { ReconciliationsComingSoon } from "./components";
import { styles } from "./styles";
import { Typography } from "@mui/material";

export const ReconciliationsScreen = (props) => {
  const { currentCompany } = useContext(UserContext);

  return (
    <>
      <div className="layout-container">
        <div style={styles.titleContainer}>
          <Typography sx={styles.title} noWrap>
            Conciliaciones
          </Typography>
        </div>
      </div>
      <ReconciliationsComingSoon companyName={currentCompany.name} />
    </>
  );
};
