import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { TrackJS } from "trackjs";
import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  DeleteItemModal,
  MarkAsPaidModal,
  NotifyWompiModal,
  PickDatesToExportModal,
  Submenu,
  Table,
  TableEmptyState,
  TableSkeleton,
  UploadFileModal,
  SiigoIntegrationModal,
  DeleteIconContainer,
  LinearProgressModal,
  AttentionModal,
  RenderIfPermissionEnabled,
  SyncBanner,
} from "../../commons";
import {
  collectionsMenuScreens,
  filterOptions,
  collectionsColumns,
  SortingQuery,
  handleSyncErpResponse,
  openSiigoSyncStep,
  useIsUserAllowedTo,
  getFromLocalStorage,
} from "../../utils";
import { styles } from "./styles";
import {
  CollectionStatusFilters,
  CollectionsEmptyState,
  NewCollectionsDropdown,
  SyncButton,
} from "./components";
import {
  CheckSyncErp,
  GetTotalCollections,
  SearchCustomers,
  SendNotification,
  SyncErpCollections,
} from "../../services";
import {
  CollectionsContext,
  TransactionsContext,
  UserContext,
} from "../../contexts";
import { SearchIcon } from "../../assets";
import { CollectionsFooter } from "./components/FooterHandler";
import { WelcomeModal } from "../../commons/modals/WelcomeModal";
import { useMediaQuery } from "react-responsive";
import CsvHandler from "../../commons/modals/UploadFileModal/CsvHandler";
import { ExportButton } from "../../commons/buttons";
import { CollectionObligationModal } from "../../commons/modals/CollectionObligationModal/CollectionObligationModal";
import { BLOCKED_MESSAGE, permissions, toastOptions } from "../../constants";
import { toast } from "react-toastify";
import DrawerCustomerForm from "./DrawerCustomerForm";
import { useChat } from "../../hooks";
import CompleteCustomersBeforePayModal from "./CompleteCustomersBeforePayModal";
import CustomerCopyLink from "./CustomerCopyLink";
import SendCollectionToClient from "./SendCollectionToClient";
import ChevronLink from "./ChevronLink";
import { ReceiverDetails } from "commons/modals/ReceiverDetails";
import { CustomerDetails } from "commons/modals/CustomerDetails";

const INCOMPLETE_CUSTOMER_MODAL_KEY =
  "entering_payment_with_customer_incomplete_disclamer";

const getRandomCollectionNumber = () =>
  `COB-${Math.floor(100000 + Math.random() * 900000)}`;

export const CollectionsScreen = () => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const [createdCustomer, setCreatedCustomer] = useState(null);
  const [createdReceiver, setCreatedReceiver] = useState(null);
  const [createdMainReceiver, setCreatedMainReceiver] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCustomerIncomplete, setOpenCustomerIncomplete] = useState(false);
  const [modalKeys, setModalKeys] = useState([]);
  const [openCollectionIncomplete, setOpenCollectionIncomplete] =
    useState(false);
  const [openCollectionsIncomplete, setOpenCollectionsIncomplete] =
    useState(false);
  const [openBlockedModal, setOpenBlockedModal] = useState(false);
  const [drawerState, setDrawerState] = useState({ key: "none" });

  const showModal = (key) => {
    setModalKeys((keys) => [...keys, key]);
  };
  const closeModal = (key) => {
    setModalKeys((keys) => keys.filter((innerKey) => innerKey !== key));
  };

  const isChecked = (row) =>
    !!selectedRows.find((selectedRow) => selectedRow.original.id === row.id);

  const toggleSelectedRow = (row) => {
    isChecked(row)
      ? setSelectedRows((rows) => rows.filter((r) => r.original.id !== row.id)) // si ya esta checked, sacamos la row
      : setSelectedRows((rows) => [...rows, { original: row }]); // si no, agregamos la row las seleccionadas
  };

  const toggleSelectedIds = (ids) => {
    ids.map((id) =>
      setSelectedRows((rows) => rows.filter((r) => r.original.id !== id))
    );
  };

  const toggleSelectedAll = (row, selectAllChecked) => {
    if (selectAllChecked) {
      !isChecked(row) &&
        setSelectedRows((rows) => [...rows, { original: row }]);
    } else {
      setSelectedRows((rows) => rows.filter((r) => r.original.id !== row.id));
    }
  };
  const navigate = useNavigate();
  const [totals, setTotals] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [defaultCollectionNumber, setDefaultCollectionNumber] = useState(
    getRandomCollectionNumber()
  );
  let selectedRowsAmount = 0;
  let selectedRowsIdsArr = [];
  let selectedCustomersArr = [];
  const {
    collections,
    internalGetCollections,
    deleteSelectedCollections,
    statusSelected,
    setStatusSelected,
    perPageSelected,
    setPerPageSelected,
    sortingSelected,
    setSortingQuery,
    collectionsScreen,
    getCustomers,
    mainReceiver,
    getMainReceiver,
    getReceivers,
  } = useContext(CollectionsContext);
  const { createCollectionsPayLaterTransaction } =
    useContext(TransactionsContext);
  const { getMe, currentCompany, currentUser, blockedCompany } =
    useContext(UserContext);
  const [collectionToDelete, setCollectionToDelete] = useState(null);
  const [collectionsToDelete, setCollectionsToDelete] = useState(null);
  const [isSiigoModalVisible, setIsSiigoModalVisible] = useState(false);
  const [isLinearProgressModalVisible, setIsLinearProgressModalVisible] =
    useState(false);
  const [isPageInitialLoading, setIsPageInitialLoading] = useState(true);
  const [initialStep, setInitialStep] = useState(null);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const customerIdSelected = collectionsScreen?.customerIdSelected?.value;
  const setCustomerIdSelected = collectionsScreen?.customerIdSelected?.setter;
  const pageIndex = collectionsScreen?.pageIndex;
  const setPageIndex = collectionsScreen?.setPageIndex;

  const syncBanner = collectionsScreen?.syncBanner?.value;
  const setSyncBanner = collectionsScreen?.syncBanner?.setter;

  const onStatusSelectedChanged = (status) => {
    setLoading(true);
    setStatusSelected(status);
    setPageIndex(1);
  };

  useChat();

  const setSorting = (fieldToSort) => {
    const sq = new SortingQuery(sortingSelected);
    sq.addField(fieldToSort);
    setSortingQuery(sq.sort);
  };

  useEffect(() => {
    getMe();
    getCustomers(1, "all");
    getReceivers();
    getMainReceiver();
  }, []);

  useEffect(() => {
    if (currentUser.backoffice_user) {
      navigate("/backoffice-receipts", { replace: true });
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    location.state &&
      location.state.openCreateObligationModal &&
      openCollectionObligationModal();
  }, []);

  useEffect(() => {
    refreshCollectionsTable().then(() => {
      setIsPageInitialLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!isPageInitialLoading) {
      refreshCollectionsTable();
    }
  }, [
    pageIndex,
    statusSelected,
    customerIdSelected,
    perPageSelected,
    sortingSelected,
  ]);

  useEffect(() => {
    if (totals) {
      if (totals.total > perPageSelected) {
        setTotalPages(Math.ceil(totals.total / perPageSelected));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  useEffect(() => {
    // obtener array de ids para la transacción
    selectedRows.forEach((row) => {
      if (row.original.amount) {
        selectedRowsAmount = selectedRowsAmount + parseInt(row.original.amount);
      }
      selectedRowsIdsArr.push(row.original.id);
      if (
        row.original.customer_id &&
        !selectedCustomersArr.includes(row.original.customer_id)
      ) {
        selectedCustomersArr.push(row.original.customer_id);
      }
    });
    setTotalAmount(selectedRowsAmount);
    setSelectedRowsIds(selectedRowsIdsArr);
  }, [selectedRows]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      let rowsUpdated = [];
      selectedRows.forEach((row) => {
        const collectionFromApi = collections.find(
          (collection) => collection.id === row.original.id
        );
        if (
          collectionFromApi &&
          JSON.stringify({ original: collectionFromApi }) !==
            JSON.stringify(row)
        ) {
          rowsUpdated.push({ original: collectionFromApi });
        }
      });
      if (rowsUpdated.length > 0) {
        const selectedRowsUpdated = [...selectedRows].filter(
          (row) => row.original.id !== rowsUpdated[0]?.original?.id
        );
        selectedRowsUpdated.push(rowsUpdated[0]);
        setSelectedRows(selectedRowsUpdated);
      }
    }
  }, [collections]);

  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const collection_obligation = queryParameters.get("collection_obligation");

  const [markAsPaidModalIsVisible, setMarkAsPaidModalIsVisible] =
    useState(false);
  const [wompiModalIsVisible, setWompiModalIsVisible] = useState(false);
  const [uploadFileModalIsVisible, setUploadFileModalIsVisible] =
    useState(false);
  const [deleteInvoiceModalIsVisible, setDeleteInvoiceModalIsVisible] =
    useState(false);
  const [
    collectionObligationModalIsVisible,
    setCollectionObligationModalIsVisible,
  ] = useState(collection_obligation ? true : false);
  const [notifyWompiModalIsVisible, setNotifyWompiModalIsVisible] =
    useState(id);
  const [searchOptions, setSearchOptions] = useState([]);
  const [uploadType, setUploadType] = useState();
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [receiverDrawerIsOpen, setReceiverDrawerIsOpen] = useState(false);
  const collectionsCsvButton = useRef(null);
  const [communicationVisible, setCommunicationVisible] = useState(
    getFromLocalStorage("isCollectionsCommunicationVisible") || true
  );

  const getSearchOptions = (query) => {
    SearchCustomers(query).then((res) => {
      setSearchOptions(res);
    });
  };

  const payLater = () => {
    createCollectionsPayLaterTransaction(selectedRowsIds).then((res) => {
      navigate("../collections/transactions", { replace: true });
    });
  };

  const closeMarkAsPaidModal = (ids) => {
    setMarkAsPaidModalIsVisible(false);
    ids && toggleSelectedIds(ids);
  };
  const openMarkAsPaidModal = () => setMarkAsPaidModalIsVisible(true);
  const closeWompiModal = () => setWompiModalIsVisible(false);
  const closeUploadFileModal = () => setUploadFileModalIsVisible(false);
  const openUploadFileModal = (type) => {
    if (type === "CSV") {
      collectionsCsvButton.current.click();
    } else {
      setUploadType(type);
      setUploadFileModalIsVisible(true);
    }
  };
  const closeDeleteInvoiceModal = () => {
    setDeleteInvoiceModalIsVisible(false);
    setCollectionToDelete(null);
    setCollectionsToDelete(null);
  };
  const openDeleteItemModal = (id) => {
    setCollectionToDelete(id);
    setDeleteInvoiceModalIsVisible(true);
  };
  const openDeleteItemsModal = (ids) => {
    setCollectionsToDelete(ids);
    setDeleteInvoiceModalIsVisible(true);
  };
  const closePaymentObligationModal = () => {
    navigate("/collections", {
      replace: true,
      state: {},
    });
    setCollectionObligationModalIsVisible(false);
  };
  const openCollectionObligationModal = () => {
    setCollectionObligationModalIsVisible(true);
    setDefaultCollectionNumber(getRandomCollectionNumber());
  };
  const closeNotifyWompiModal = (id) => {
    setNotifyWompiModalIsVisible(false);
    navigate("/collections/transactions", {
      replace: true,
      state: { openDetail: id },
    });
  };

  const handleSearchChange = (sup) => {
    if (sup) {
      setCustomerIdSelected(sup.id);
      collectionsScreen?.customerFilter?.setter(sup);
    } else {
      setCustomerIdSelected("all");
      collectionsScreen?.customerFilter?.setter({});
    }
    setPageIndex(1);
  };

  const disableExpand = (target, html) => {
    const dropdownOptions =
      html.toLowerCase().includes("dropdown") ||
      html.toLowerCase().includes("descargar") ||
      html.toLowerCase().includes("eliminar") ||
      html.toLowerCase().includes("dd/mm/yyyy") ||
      html.toLowerCase().includes("2024") ||
      html.toLowerCase().includes("necesario");
    return (
      target === "input" ||
      target === "svg" ||
      target === "button" ||
      target === "path" ||
      target === "label" ||
      (dropdownOptions && target === "p") ||
      (dropdownOptions && target === "div")
    );
  };

  const handleOnClickRow = (row, target, html) => {
    if (!row.original.expiration_date && !disableExpand(target, html)) {
      setOpenCollectionIncomplete(true);
    } else {
      !disableExpand(target, html) &&
        navigate(`/collections/index/${row.original.id}`);
    }
  };

  const renderRowEndButtons = (row) => {
    const isPendingOrSent =
      row.original.status === "pending" || row.original.status === "sent";

    if (!isUserAllowedTo(permissions.COLLECTIONS_DELETE)) {
      return;
    }

    const Td = ({ children }) => {
      return isMobile ? (
        children
      ) : (
        <td style={{ backgroundColor: "transparent" }}>{children}</td>
      );
    };

    return (
      <Td>
        {isPendingOrSent ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CustomerCopyLink hash={row.original.hash} />
            <SendCollectionToClient
              row={row}
              blockedCompany={blockedCompany}
              setOpenBlockedModal={setOpenBlockedModal}
              setOpenCollectionIncomplete={setOpenCollectionIncomplete}
              collectionIsIncomplete={collectionIsIncomplete}
              isChecked={isChecked}
              toggleSelectedRow={toggleSelectedRow}
              setOpenCustomerIncomplete={setOpenCustomerIncomplete}
              SendNotification={SendNotification}
              refreshCollectionsTable={() =>
                internalGetCollections(
                  pageIndex,
                  statusSelected,
                  customerIdSelected,
                  perPageSelected,
                  sortingSelected
                )
              }
            />
            <DeleteIconContainer
              itemId={row.original.id}
              openDeleteItemModal={openDeleteItemModal}
            />
            {!isMobile && <ChevronLink text="Ver detalle" />}
          </div>
        ) : (
          <CustomerCopyLink hash={row.original.hash} />
        )}
      </Td>
    );
  };

  const renderAutocomplete = () => {
    return (
      <>
        <Autocomplete
          options={searchOptions}
          size="small"
          sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
          freeSolo
          filterOptions={filterOptions}
          getOptionLabel={(option) => {
            return option?.name || "";
          }}
          onChange={(event, option) => {
            if (option?.id) {
              handleSearchChange(option);
            } else {
              handleSearchChange();
            }
          }}
          onInputChange={(event) => {
            if (event?.target?.value?.length >= 3) {
              getSearchOptions(event.target.value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontSize: 14 },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={"Buscar cliente"}
            />
          )}
          value={
            collectionsScreen?.customerFilter?.value || {
              name: "",
            }
          }
        />
      </>
    );
  };

  const renderDownloadButton = () => {
    return (
      <ExportButton
        onClick={() => setIsExportModalVisible(true)}
        styles={{ marginTop: "24px", paddingBottom: "12px" }}
      />
    );
  };

  const refreshCollectionsTable = async () => {
    try {
      setLoading(true);
      await internalGetCollections(
        pageIndex,
        statusSelected,
        customerIdSelected,
        perPageSelected,
        sortingSelected
      );
      const totalCollections = await GetTotalCollections({
        status: statusSelected,
        customerId: customerIdSelected,
      });
      setTotals(totalCollections);
      getMe();
    } catch (error) {
      TrackJS.console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const syncCollections = () => {
    setSyncBanner((syncBanner) => {
      return {
        ...syncBanner,
        visible: true,
        type: "loading",
        title: "Actualizando información de Siigo",
      };
    });
    SyncErpCollections().then((res) => {
      const onSuccess = () => {
        refreshCollectionsTable();
        setSyncBanner((syncBanner) => {
          return {
            ...syncBanner,
            visible: true,
            type: "success",
            title: "Importación exitosa.",
          };
        });
      };
      handleSyncErpResponse(res, onSuccess, setSyncBanner);
    });
  };

  const handleSyncErp = async () => {
    await CheckSyncErp().then(async (res) => {
      const syncValidation = res?.syncValidation;
      syncValidation?.credentials
        ? syncValidation.collection
          ? syncCollections()
          : openSiigoSyncStep(7, setInitialStep, setIsSiigoModalVisible)
        : currentCompany.user_cs && currentCompany.password_cs
        ? openSiigoSyncStep(4, setInitialStep, setIsSiigoModalVisible)
        : setIsSiigoModalVisible(true);
    });
  };

  const collectionIsIncomplete = (collectionToCheck) => {
    const { id } = collectionToCheck;
    const collection = collections.find((collection) => collection.id === id);

    if (!collection) {
      return false;
    } else {
      const { name, document_type, document_number, email, phone } = collection;
      return (
        !name ||
        !document_type ||
        !document_number ||
        ((!email || email === "") && (!phone || phone === "+57"))
      );
    }
  };

  const hasOneIncomplete = (customer = {}) => {
    const dictionary = selectedRows
      .filter((collection) => {
        return (
          collection.original.status === "pending" &&
          collectionIsIncomplete(collection.original) &&
          collection.original.customer_id !== customer?.id
        );
      })
      .reduce((acc, collection) => {
        acc[collection.original.customer_id] = collection;
        return acc;
      }, {});
    return Object.values(dictionary);
  };

  const hasOneWithoutExpirationDate = () => {
    const rows = selectedRows.find((collection) => {
      return !collection.original.expiration_date;
    });
    return rows ? [rows] : [];
  };

  const handleCloseCollectionIncomplete = () => {
    setOpenCollectionIncomplete(null);
  };

  const handleCloseCollectionsIncomplete = () => {
    setOpenCollectionsIncomplete(null);
  };

  const handleCloseCustomerIncomplete = () => {
    setOpenCustomerIncomplete(null);

    const collectionsWithIncompleteCustomer = hasOneIncomplete();

    if (collectionsWithIncompleteCustomer.length > 0) {
      setDrawerState({
        key: "complete",
        updated: [],
        customerIdsToComplete: collectionsWithIncompleteCustomer.map(
          (collection) => collection.original.customer_id
        ),
      });
    } else {
      setDrawerIsOpen(openCustomerIncomplete.customer_id);
    }
  };

  const sendNotification = async () => {
    if (blockedCompany) {
      setOpenBlockedModal(true);
    } else {
      await SendNotification(selectedRowsIds).then(() => {
        toast.success(
          `Las notificaciones fueron enviadas con éxito`,
          toastOptions
        );
        refreshCollectionsTable();
        selectedRowsIds && toggleSelectedIds(selectedRowsIds);
      });
    }
  };

  const openCustomersToCompleteModal = () => {
    closeModal(INCOMPLETE_CUSTOMER_MODAL_KEY);
    const collectionsWithIncompleteCustomer = hasOneIncomplete();
    if (collectionsWithIncompleteCustomer.length > 0) {
      setDrawerState({
        key: "complete",
        updated: [],
        customerIdsToComplete: collectionsWithIncompleteCustomer.map(
          (collection) => collection.original.customer_id
        ),
      });
    }
  };

  const emptyState =
    !loading &&
    collections &&
    collections.length === 0 &&
    statusSelected === "all" &&
    customerIdSelected === "all";

  return (
    <>
      <>
        <SiigoIntegrationModal
          visible={isSiigoModalVisible}
          setIsSiigoModalVisible={setIsSiigoModalVisible}
          refreshTable={refreshCollectionsTable}
          isCollections={true}
          initialStep={initialStep}
          setSyncBanner={setSyncBanner}
          isCausation={true}
        />
        <CsvHandler
          entity="collection"
          licenseKey={process.env.REACT_APP_CSVBOX_COLLECTIONS_LICENSE_KEY}
          buttonRef={collectionsCsvButton}
          setTotals={setTotals}
        />
        <WelcomeModal />
        <MarkAsPaidModal
          visible={markAsPaidModalIsVisible}
          handleClose={closeMarkAsPaidModal}
          selectedRowsIds={selectedRowsIds}
          section="collections"
        />
        <DeleteItemModal
          itemsIds={collectionsToDelete || [collectionToDelete]}
          action={deleteSelectedCollections}
          handleClose={closeDeleteInvoiceModal}
          title="¿Quieres borrar estos documentos?"
          subtitle="Si los eliminas, no aparecerán más en Payana"
          visible={deleteInvoiceModalIsVisible}
          cleanSelectedRows={() => setSelectedRows([])}
          pageIndex={pageIndex}
        />
        <UploadFileModal
          entity={"collections"}
          type={"CSV"}
          visible={uploadFileModalIsVisible}
          handleClose={closeUploadFileModal}
          setTotals={setTotals}
        />
        <PickDatesToExportModal
          visible={isExportModalVisible}
          handleClose={() => setIsExportModalVisible(false)}
        />
        <CollectionObligationModal
          setDrawerIsOpen={setDrawerIsOpen}
          createdCustomer={createdCustomer}
          visible={collectionObligationModalIsVisible}
          handleClose={closePaymentObligationModal}
          defaultCollectionNumber={defaultCollectionNumber}
          setReceiverDrawerIsOpen={setReceiverDrawerIsOpen}
          createdMainReceiver={createdMainReceiver}
          createdReceiver={createdReceiver}
        />
        <NotifyWompiModal
          visible={notifyWompiModalIsVisible}
          handleClose={closeNotifyWompiModal}
        />
        <LinearProgressModal
          title="Sincronizando Siigo"
          subtitle="Este proceso puede demorar hasta 3 minutos. Por favor no cierres esta
          ventana."
          visible={isLinearProgressModalVisible}
          handleClose={() => {
            setIsLinearProgressModalVisible(false);
          }}
        />
        <AttentionModal
          title="¡Falta información sobre tu cliente!"
          description="Para poder enviar esta cuenta por cobrar debes completar la información faltante de tu cliente. Adicionalmente, si incluyes el correo electrónico y el número de celular, le enviaremos un aviso de pago."
          onClose={handleCloseCustomerIncomplete}
          open={!!openCustomerIncomplete}
        />
        <AttentionModal
          title="¡Falta completar la fecha de vencimiento!"
          description="Para poder enviar el cobro, necesitamos que completes la fecha de vencimiento del mismo."
          onClose={handleCloseCollectionIncomplete}
          open={!!openCollectionIncomplete}
        />
        <AttentionModal
          title="¡Falta completar la fecha de vencimiento!"
          description="A algunos de tus cobros seleccionados les falta la fecha de vencimiento."
          onClose={handleCloseCollectionsIncomplete}
          open={!!openCollectionsIncomplete}
        />
        <AttentionModal
          title={BLOCKED_MESSAGE}
          onClose={() => setOpenBlockedModal(false)}
          open={openBlockedModal}
        />
        <CompleteCustomersBeforePayModal
          numberOfIncompleted={hasOneIncomplete().length}
          visible={modalKeys.includes(INCOMPLETE_CUSTOMER_MODAL_KEY)}
          close={() => {
            closeModal(INCOMPLETE_CUSTOMER_MODAL_KEY);
          }}
          primaryAction={openCustomersToCompleteModal}
        />
        <div
          style={{ marginBottom: "150px", backgroundColor: "#FFFFFF" }}
          id="page-wrap"
        >
          {isPageInitialLoading ? (
            <div className="table-skeleton-page-initial-loading">
              <TableSkeleton />
            </div>
          ) : emptyState ? (
            <CollectionsEmptyState
              openUploadFileModal={openUploadFileModal}
              openCollectionObligationModal={openCollectionObligationModal}
              setReceiverDrawerIsOpen={setReceiverDrawerIsOpen}
              handleSyncErp={handleSyncErp}
            />
          ) : (
            <>
              <Submenu items={collectionsMenuScreens} />
              <div className="layout-container">
                <div style={styles.titleContainer}>
                  <Typography sx={styles.title} noWrap>
                    Cuentas por cobrar
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <SyncButton handleSyncErp={handleSyncErp} />
                    <RenderIfPermissionEnabled
                      permission={permissions.COLLECTIONS_CREATE}
                    >
                      <NewCollectionsDropdown
                        openUploadFileModal={openUploadFileModal}
                        openCollectionObligationModal={
                          openCollectionObligationModal
                        }
                        handleSyncErp={handleSyncErp}
                        setSyncBanner={setSyncBanner}
                      />
                    </RenderIfPermissionEnabled>
                  </div>
                </div>
                {syncBanner.visible && (
                  <SyncBanner
                    title={syncBanner.title}
                    description={syncBanner.description}
                    type={syncBanner.type}
                    setSyncBanner={setSyncBanner}
                  />
                )}
                {emptyState ? null : isMobile ? (
                  <CollectionStatusFilters
                    statusSelected={statusSelected}
                    setStatusSelected={onStatusSelectedChanged}
                    isMobile={isMobile}
                  />
                ) : (
                  <div className="invoices-data-filters">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        margin: "0px 2.5%",
                        justifyContent: "space-between",
                        flex: "0 0 auto",
                      }}
                    >
                      <CollectionStatusFilters
                        statusSelected={statusSelected}
                        setStatusSelected={onStatusSelectedChanged}
                        isMobile={isMobile}
                        renderDownloadButton={renderDownloadButton}
                      />
                    </Box>
                  </div>
                )}
                <Table
                  loading={loading}
                  loadingSkeleton={<TableSkeleton />}
                  pageNumber={pageIndex}
                  columns={collectionsColumns(
                    setDrawerIsOpen,
                    setSorting,
                    sortingSelected
                  )}
                  data={collections}
                  amountModal
                  expandIcon
                  expandLink={`/payments/invoices/`}
                  setSelectedRows={() => {}}
                  setPageIndex={setPageIndex}
                  checkboxVisible={isUserAllowedTo(
                    permissions.SELECT_COLLECTIONS
                  )}
                  pageCount={totalPages}
                  supplier={collectionsScreen?.customerFilter?.value}
                  setSupplier={collectionsScreen?.customerFilter?.setter}
                  section="collections"
                  hasFileIcon
                  toggleSelectedRow={toggleSelectedRow}
                  isChecked={isChecked}
                  renderRowEndButtons={renderRowEndButtons}
                  toggleSelectedAll={toggleSelectedAll}
                  perPage={perPageSelected}
                  setPerPage={setPerPageSelected}
                  perPageVisible={true}
                  statusSelected={statusSelected}
                  providerIdSelected={customerIdSelected}
                  onClickRow={handleOnClickRow}
                  renderAutocomplete={renderAutocomplete}
                  emptyState={
                    <TableEmptyState
                      title={"No se encontraron resultados para esta búsqueda"}
                      subtitle={"Por favor intente nuevamente"}
                    />
                  }
                />
              </div>
            </>
          )}
        </div>
        {collections.length > 0 && (
          <RenderIfPermissionEnabled
            permission={permissions.SHOW_FOOTER_COLLECTIONS}
          >
            <CollectionsFooter
              selectedRows={selectedRows}
              selectedRowsIds={selectedRowsIds}
              totalAmount={totalAmount}
              deleteSelectedCollections={openDeleteItemsModal}
              openMarkAsPaidModal={openMarkAsPaidModal}
              payLater={payLater}
              sendNotification={() => {
                if (blockedCompany) {
                  setOpenBlockedModal(true);
                } else {
                  const collectionsWithIncompleteCustomer = hasOneIncomplete();
                  const collectionsWithoutExpirationDate =
                    hasOneWithoutExpirationDate();
                  if (collectionsWithoutExpirationDate.length > 0) {
                    setOpenCollectionsIncomplete(true);
                  } else if (collectionsWithIncompleteCustomer.length > 0) {
                    showModal(INCOMPLETE_CUSTOMER_MODAL_KEY);
                  } else {
                    sendNotification();
                  }
                }
              }}
              totalSelected={selectedRows.length}
              pageIndex={pageIndex}
              refreshCollectionsTable={refreshCollectionsTable}
              toggleSelectedIds={toggleSelectedIds}
            />
          </RenderIfPermissionEnabled>
        )}
        <DrawerCustomerForm
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          onFinish={() => {
            if (blockedCompany) {
              setOpenBlockedModal(true);
            } else {
              sendNotification();
              refreshCollectionsTable();
            }
          }}
        />
        <CustomerDetails
          isOpen={drawerIsOpen}
          id={Number.isFinite(drawerIsOpen) ? drawerIsOpen : null}
          handleClose={() => {
            setDrawerIsOpen(false);
          }}
          handleSuccess={(customer) => {
            internalGetCollections(
              pageIndex,
              statusSelected,
              customerIdSelected,
              perPageSelected,
              sortingSelected
            );
            setCreatedCustomer(customer);
          }}
        />
        <ReceiverDetails
          isOpen={!!receiverDrawerIsOpen}
          id={
            Number.isFinite(receiverDrawerIsOpen) ? receiverDrawerIsOpen : null
          }
          handleClose={() => {
            setReceiverDrawerIsOpen(false);
          }}
          handleSuccess={(receiver) => {
            setCreatedMainReceiver(receiver);
            getMainReceiver();
          }}
          mainReceiver={mainReceiver}
        />
      </>
    </>
  );
};
