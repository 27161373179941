import React from "react";
import { expandCausationColumns } from "../../../../utils";
import { DataGrid, GridCellModes } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { styles } from "../styles";

export function TableCausation({
  data,
  addNewTableInfo,
  products,
  taxesIva,
  taxesRete,
  handleOnChangeItem,
  handleDeleteItem,
  getSearchOptionsProducts,
  isCaused,
}) {
  const [cellModesModel, setCellModesModel] = React.useState({});

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    if (
      event.target.nodeType === 1 &&
      !event.currentTarget.contains(event.target)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <Box sx={styles.tableContainer}>
      <DataGrid
        autoHeight
        columns={expandCausationColumns(
          products,
          taxesIva,
          taxesRete,
          handleOnChangeItem,
          handleDeleteItem,
          getSearchOptionsProducts,
          isCaused
        )}
        rows={data}
        hideFooter={true}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        disableColumnSort
        getRowHeight={() => "auto"}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        sx={{
          "& .MuiDataGrid-cell": {
            borderRight: "1px solid #D0D5DD",
            color: isCaused ? "rgba(0, 0, 0, 0.38)" : "",
            fontSize: "13px",
            maxHeight: "52px",
          },
          "& .MuiDataGrid-columnHeader": {
            borderRight: "1px solid #D0D5DD",
            fontSize: "12px",
            backgroundColor: "#F2F4F7",
            color: "#667085",
          },
          "& .MuiDataGrid-root .MuiDataGrid-main": {
            borderRadius: "8px",
            borderRight: "none",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "52px !important",
          },
        }}
        style={{
          borderRadius: "8px",
          borderRight: "none",
        }}
      />
      <div style={styles.textContainerAddRow}>
        <a
          href="#"
          style={styles.textAddRow}
          onClick={(e) => {
            e.preventDefault();
            addNewTableInfo();
          }}
        >
          Agregar Item
        </a>
      </div>
    </Box>
  );
}
