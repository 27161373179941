import React from "react";
import { useMediaQuery } from "react-responsive";
import { styles } from "../styles";
import { SecondaryButton } from "../../buttons";
import { ArrowLeftIcon, ArrowRightIcon, RightArrowIcon } from "assets";

export const Pagination = ({
  canPreviousPage,
  previousPage,
  pageIndex,
  pageOptions,
  canNextPage,
  pageSize,
  nextPage,
  setPerPage,
  perPageVisible,
  gotoPage,
  setPageIndex,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  return (
    <>
      {isMobile ? (
        <nav className="paginationNavMobile">
          <ul className="pagination justify-content-end pagination-sm">
            <li className="page-item">
              <button
                className={canPreviousPage ? "page-link" : "page-link disabled"}
                onClick={() => previousPage()}
                style={styles.paginationFont}
              >
                {"<"}
              </button>
            </li>
            <li className="page-item">
              <span
                className="page-link disabled"
                style={styles.paginationFont}
              >
                <strong>
                  {pageIndex} de {pageOptions?.length}
                </strong>
              </span>
            </li>
            <li className="page-item">
              <button
                className={canNextPage ? "page-link" : "page-link disabled"}
                onClick={() => nextPage()}
                style={styles.paginationFont}
              >
                {">"}
              </button>
            </li>
          </ul>
        </nav>
      ) : (
        <div className="paginationNav">
          <span style={styles.paginationFont}>
            Página <strong>{pageIndex}</strong> de{" "}
            <strong>{pageOptions?.length}</strong>
          </span>
          <SecondaryButton
            isDisable={!canPreviousPage}
            action={() => previousPage()}
            icon={
              <ArrowLeftIcon color={!canPreviousPage ? "#EAECF0" : "#344054"} />
            }
            width={40}
            style={{ padding: 0 }}
          />
          <SecondaryButton
            isDisable={!canNextPage}
            action={() => nextPage()}
            icon={
              <RightArrowIcon color={!canNextPage ? "#EAECF0" : "#344054"} />
            }
            width={40}
            style={{ padding: 0 }}
          />
        </div>
      )}
    </>
  );
};
