import { requestContainer } from "../requestContainer";

export async function ServicesSearch(query) {
  return requestContainer({
    method: "get",
    url: `/services/search?q=${query}`,
  });
}

export async function showService(query) {
  return requestContainer({
    method: "get",
    url: `/services?q=${query}`,
  });
}

export async function CreateService({ formValues }) {
  return requestContainer({
    method: "post",
    url: `/services`,
    data: {
      ...formValues,
    },
  });
}

export async function GetService({ id }) {
  return requestContainer({
    method: "get",
    url: `/services/${id}`,
  });
}

export async function GetServices({ pageIndex, serviceId }) {
  return requestContainer({
    method: "get",
    url: `/service?page=${pageIndex}&service_id=${serviceId}&per_page=100`,
  });
}

export async function GetTotalServices({ serviceId }) {
  return requestContainer({
    method: "get",
    url: `/service/total?service_id=${serviceId}`,
  });
}

export async function SearchCreatedServices(query) {
  return requestContainer({
    method: "get",
    url: `/services?q=${query}`,
  });
}

export async function DeleteService({ id }) {
  return requestContainer({
    method: "delete",
    url: `/services/${id}`,
  });
}

export async function UpdateService({ id, formValues }) {
  return requestContainer({
    method: "put",
    url: `/services/${id}`,
    data: {
      ...formValues,
    },
  });
}
