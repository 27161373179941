import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  FileMenuIcon,
  FilePlusIcon,
  FilePlusFilledIcon,
  FileAttachmentFilledIcon,
  FileAttachmentIcon,
} from "../../../assets";
import { styles } from "../styles";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { DropdownButton } from "../../../commons/buttons";
import { Tooltip } from "commons";

export const NewDropdown = ({
  openUploadFileModal,
  openPaymentObligationModal,
  disabled,
  isCausationAvailable,
  blockedCausation,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const [open, setOpen] = useState(false);

  const goToCausation = async () => {
    navigate("/payments/causation/new", {
      replace: true,
    });
  };

  const toggleDropdown = () => {
    setOpen((prevValue) => !prevValue);
  };

  return (
    <Tooltip
      title={
        disabled && !isCausationAvailable
          ? "El rol contador no permite realizar esta acción"
          : ""
      }
    >
      <Dropdown
        onToggle={() => {
          toggleDropdown();
        }}
        align="end"
      >
        <span>
          <DropdownButton
            text="Crear documento"
            disabled={disabled && !isCausationAvailable}
            style={{
              width: "172px",
              backgroundColor: "#6938EF",
              color: "#FFFFFF",
              borderColor: "transparent",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "8px 12px 8px 12px",
              alignItems: "center",
            }}
            open={open}
            openedIcon={<ChevronUpIcon color="#FFFFFF" />}
            closedIcon={<ChevronDownIcon color="#FFFFFF" />}
          />
        </span>
        <Dropdown.Menu style={styles.noPadding}>
          <Dropdown.Header
            style={{
              marginTop: "8px",
              color: "#667085",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            ANTICIPO
          </Dropdown.Header>
          <Tooltip
            title={
              disabled && isCausationAvailable
                ? "El rol contador no permite realizar esta acción"
                : ""
            }
            arrow={true}
          >
            <span>
              <Dropdown.Item
                id="dropdown_button_create_payment"
                style={
                  disabled && isCausationAvailable
                    ? styles.disabledNewDropdownPadding
                    : styles.newDropdownPadding
                }
                onClick={() => openPaymentObligationModal("invoice")}
                disabled={disabled && isCausationAvailable}
              >
                <div style={styles.dropdownItemContainer}>
                  <FileMenuIcon
                    color={
                      disabled && isCausationAvailable ? "#697586" : "#475467"
                    }
                  />
                  <div style={styles.dropdownItemTextContainer}>
                    <p
                      style={
                        disabled && isCausationAvailable
                          ? styles.disabledDropdownItemTitle
                          : styles.dropdownItemTitle
                      }
                    >
                      Crear pago manual
                    </p>
                    <p
                      style={
                        disabled && isCausationAvailable
                          ? styles.disabledDropdownItemText
                          : styles.dropdownItemText
                      }
                    >
                      Ingresa sólo el monto a pagar y los datos del proveedor.
                    </p>
                  </div>
                </div>
              </Dropdown.Item>
            </span>
          </Tooltip>
          <Divider />
          {isCausationAvailable && !blockedCausation && (
            <>
              <Dropdown.Header
                style={{
                  marginTop: "8px",
                  color: "#667085",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                CUENTA DE COBRO
              </Dropdown.Header>
              <Dropdown.Item
                id="dropdown_button_create_payment"
                style={styles.newDropdownPadding}
                onClick={() => openPaymentObligationModal("collection_account")}
              >
                <div style={styles.dropdownItemContainer}>
                  <FilePlusIcon />
                  <div style={styles.dropdownItemTextContainer}>
                    <p style={styles.dropdownItemTitle}>
                      Crear cuenta de cobro
                    </p>
                    <p style={styles.dropdownItemText}>
                      Ingresa la información básica, paga y causa luego.
                    </p>
                  </div>
                </div>
              </Dropdown.Item>
              <Divider />
              <Dropdown.Item
                id="dropdown_button_integration"
                style={styles.newDropdownPadding}
                onClick={() => goToCausation()}
              >
                <div style={styles.dropdownItemContainer}>
                  <FilePlusFilledIcon />
                  <div style={styles.dropdownItemTextContainer}>
                    <p style={styles.dropdownItemTitle}>
                      Crear y causar cuenta de cobro
                    </p>
                    <p style={styles.dropdownItemText}>
                      Ingresa la información completa y causa.
                    </p>
                  </div>
                </div>
              </Dropdown.Item>
              <Divider />
            </>
          )}
          <Dropdown.Header
            style={{
              marginTop: "8px",
              color: "#667085",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            CARGA MASIVA
          </Dropdown.Header>
          {!isMobile && (
            <>
              <Tooltip
                title={
                  disabled && isCausationAvailable
                    ? "El rol contador no permite realizar esta acción"
                    : ""
                }
                arrow={true}
              >
                <span>
                  <Dropdown.Item
                    id="dropdown_button_upload_txt"
                    style={
                      disabled && isCausationAvailable
                        ? styles.disabledNewDropdownPadding
                        : styles.newDropdownPadding
                    }
                    onClick={() => openUploadFileModal("TXT")}
                    disabled={disabled && isCausationAvailable}
                  >
                    <div style={styles.dropdownItemContainer}>
                      <FileAttachmentIcon
                        color={
                          disabled && isCausationAvailable
                            ? "#697586"
                            : "#475467"
                        }
                      />
                      <div style={styles.dropdownItemTextContainer}>
                        <p
                          style={
                            disabled && isCausationAvailable
                              ? styles.disabledDropdownItemTitle
                              : styles.dropdownItemTitle
                          }
                        >
                          Importar archivo de Bancolombia
                        </p>
                        <p
                          style={
                            disabled && isCausationAvailable
                              ? styles.disabledDropdownItemText
                              : styles.dropdownItemText
                          }
                        >
                          Soportamos formato PAB.
                        </p>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Divider />
                  <Dropdown.Item
                    id="dropdown_button_updload_payments_list"
                    style={{
                      ...(disabled && isCausationAvailable
                        ? styles.disabledNewDropdownPadding
                        : styles.newDropdownPadding),
                      padding: "16px 16px 24px 16px",
                    }}
                    onClick={() => openUploadFileModal("CSV")}
                    disabled={disabled && isCausationAvailable}
                  >
                    <div style={styles.dropdownItemContainer}>
                      <FileAttachmentFilledIcon
                        color={
                          disabled && isCausationAvailable
                            ? "#697586"
                            : "#475467"
                        }
                      />
                      <div style={styles.dropdownItemTextContainer}>
                        <p
                          style={
                            disabled && isCausationAvailable
                              ? styles.disabledDropdownItemTitle
                              : styles.dropdownItemTitle
                          }
                        >
                          Importar archivo de Excel
                        </p>
                        <p
                          style={
                            disabled && isCausationAvailable
                              ? styles.disabledDropdownItemText
                              : styles.dropdownItemText
                          }
                        >
                          Soportamos formatos XLS, XLSX o CSV.
                        </p>
                      </div>
                    </div>
                  </Dropdown.Item>
                </span>
              </Tooltip>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Tooltip>
  );
};
