import { requestContainer } from "../requestContainer";

export async function search(type = "", q = "", accountId = "") {
  return requestContainer({
    method: "get",
    url: `/erp/causation/search?type=${type}&q=${q}&accountId=${accountId}`,
  }).then((response) => {
    if (response.message === "Failed to make request.") {
      return [];
    }
    return response;
  });
}

export async function sendCausation(body) {
  return requestContainer({
    method: "post",
    url: `/erp/causation`,
    data: body,
  });
}

export async function getInvoicesCausation({
  pageIndex,
  status = "all",
  providerId = "all",
  perPage = 100,
  sorting = "",
}) {
  return requestContainer({
    method: "get",
    url: `/erp/causation/invoices?page=${pageIndex}&status=${status}&provider_id=${providerId}&per_page=${perPage}&sort=${sorting}`,
  });
}

export async function getInvoicesTotalCausation({ status, providerId }) {
  return requestContainer({
    method: "get",
    url: `/erp/causation/total?causation_status=${status}&provider_id=${providerId}`,
  });
}

export async function deleteInvoiceCausation(id) {
  return requestContainer({
    method: "delete",
    url: `/erp/causation/invoice/${id}`,
  });
}

export async function updateInvoices(invoicesIds) {
  return requestContainer({
    method: "put",
    url: `/erp/causation/invoice/bulk-update`,
    data: { invoicesIds },
  });
}

export async function deleteInvoices(invoicesIds) {
  return requestContainer({
    method: "post",
    url: `/erp/causation/invoice/bulk-delete`,
    data: { invoicesIds },
  });
}

export async function getCausationShow(invoiceId) {
  return requestContainer({
    method: "get",
    url: `/erp/causation/${invoiceId}`,
  });
}

export async function syncCausation() {
  return requestContainer({
    method: "get",
    url: `/erp/causation/sync`,
  });
}

export async function checkSiigoCredential() {
  return requestContainer({
    method: "get",
    url: "/sync/check",
  });
}

export async function saveCausation(body) {
  return requestContainer({
    method: "post",
    url: "/erp/causation/save",
    data: body,
  });
}

export async function checkInvoicesSiigo() {
  return requestContainer({
    method: "get",
    url: "/erp/causation/checkInvoicesSiigo",
  });
}

export async function storeSupplier(body) {
  return requestContainer({
    method: "post",
    url: "/erp/causation/supplier",
    data: body,
  });
}

export async function searchErpSupplier({ documentNumber }) {
  return requestContainer({
    method: "get",
    url: `/erp/causation/supplier/${documentNumber}`,
  });
}
