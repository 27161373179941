import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { EditIcon, NewIcon } from "../../../assets";
import { styles } from "../styles";
import { permissions } from "constants";
import { useIsUserAllowedTo } from "utils";
import { Tooltip } from "commons/components";

export const SupplierRow = ({
  cell,
  isIncomplete,
  linkTo,
  itemId,
  isNew,
  setDrawerIsOpen,
  className,
}) => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  return (
    <div
      style={{
        ...styles.row,
        justifyContent: "space-between",
        maxWidth: "380px",
      }}
    >
      <div
        title={cell.value}
        className={`limit-text incompleted-provider-indicator ${
          cell.value !== null ? className : ""
        }`}
        style={{
          color: isIncomplete ? "" : "#475467",
          margin: "revert",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="limit-text" style={{ maxWidth: "300px" }}>
          {cell.value !== null ? cell.value.toUpperCase() : "NO INFORMADO"}
        </div>
        {isUserAllowedTo(permissions.INVOICES_UPDATE) &&
          isIncomplete &&
          !cell.row.original.provider_id && (
            <Tooltip title="Carga la información de tu proveedor">
              <Link to={linkTo + itemId}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
        {isNew && (
          <div style={{ marginLeft: "8px", marginRight: "8px" }}>
            <NewIcon />
          </div>
        )}
      </div>
      {isUserAllowedTo(permissions.INVOICES_UPDATE) &&
        isIncomplete &&
        cell.row.original.provider_id && (
          <Tooltip title="Completa la información de tu proveedor">
            <span
              onClick={(e) => {
                e.stopPropagation();
                setDrawerIsOpen(cell.row.original.provider_id);
              }}
              className="incompleted-provider-indicator"
              style={{
                textTransform: "none",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: 26,
                    textWrap: "nowrap",
                    padding: "4px 8px",
                    marginRight: 12,
                    background: "#FEF0C7",
                    display: "flex",
                    gap: 6,
                  }}
                >
                  <span>Falta información</span>
                  <EditIcon color={isIncomplete ? "#B54708" : "#000000"} />
                </div>
              </div>
            </span>
          </Tooltip>
        )}
    </div>
  );
};
