import React, { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { styles } from "./styles";
import { PrimaryButton, SecondaryButton } from "../../buttons";
import { CloseButton, Spinner } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { ItemsInTransaction } from "./ItemsInTransaction";
import { getSourceOfFundsLabel } from "../RegisterSourceOfFundsModal/getSourceOfFundsLabel";
import { EditIcon } from "../../../assets";
import { ItemsInPayrollTransaction } from "./ItemsInPayrollTransaction";
import ComissionRetentions from "./ComissionRetentions";
import { requestContainer } from "../../../services/api/requestContainer";
import { UiContext } from "../../../contexts/UiContext";
import { toast } from "react-toastify";
import { toastOptions, ACCOUNT_NAME, permissions } from "../../../constants";
import { StatusPill } from "../../Table/components";
import { useIsUserAllowedTo, formatLocaleDate } from "../../../utils";
import getComissionLabel from "./getComissionLabel";

export const TransactionsDetails = ({
  type,
  visible,
  openWompiModal,
  setSourceOfFundsModal,
  isLoading,
  transaction,
  trxRows,
  sourceOfFunds,
  onClose,
  sourceOfFundsActive = false,
  closeDrawer,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const [requestInProcess, setRequestInProcess] = useState(false);
  const suppliers = Object.keys(trxRows);
  const {
    hooks: { setOpenModalKeys, setClosedModalKeys },
  } = useContext(UiContext);

  let invoicesIds = [];
  const selectedRowsIds =
    type === "invoice" || type === "collections"
      ? suppliers.map((sup) =>
          trxRows[sup].map((row) => invoicesIds.push(row.id))
        )
      : trxRows.map((payroll) => payroll.id);

  const payanaComission = transaction.amount_comission;

  const renderComission = () => {
    return payanaComission && payanaComission > 0 ? (
      <>
        <div style={styles.trxRow}>
          <div style={styles.column}>
            <p style={styles.textWithoutLimit} className="card-subtitle mb-2">
              {ACCOUNT_NAME}
            </p>
            <p
              style={{ ...styles.rowTextLeftWithoutLimit, marginTop: "-4px" }}
              className="card-subtitle mb-2"
            >
              {getComissionLabel(transaction, selectedRowsIds, suppliers)}
            </p>
          </div>
          <p style={styles.rowText} className="card-subtitle mb-2">
            <NumericFormat
              value={parseInt(payanaComission)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
            />
          </p>
        </div>
        <hr style={styles.separator} />
      </>
    ) : null;
  };

  return (
    <Drawer anchor="right" open={visible} onClose={onClose}>
      <Stack
        sx={{ minWidth: "400px", maxWidth: "400px", height: "100%" }}
        justifyContent="flex-start"
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          p={2}
          sx={{ paddingLeft: isMobile ? "24px" : "16px" }}
        >
          <Typography sx={styles.modalTitle}>
            {type === "collections"
              ? "Detalle de transacción"
              : "Orden de pago"}
          </Typography>
          <CloseButton style={{ boxShadow: "none" }} onClick={onClose} />
        </Stack>
        <Stack
          my={2}
          spacing={2}
          sx={{
            marginTop: "0px",
            height: "90%",
            paddingLeft: isMobile ? "8px" : "0px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {isLoading ? (
            <div style={styles.spinnerContainer}>
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <div style={styles.greyContainer}>
                <div style={styles.firstItem}>
                  <p style={styles.title} className="card-subtitle mb-2">
                    Estado
                  </p>
                  <div style={{ paddingTop: isMobile ? "4px" : "0px" }}>
                    <StatusPill
                      status={transaction?.status}
                      section={"invoiceTrx"}
                      receipts={transaction?.receipts}
                    />
                  </div>
                </div>
                <PaymentDelayedAlert
                  onClose={onClose}
                  setClosedModalKeys={setClosedModalKeys}
                  closeDrawer={closeDrawer}
                  setOpenModalKeys={setOpenModalKeys}
                  visible={transaction?.payment_delayed}
                  id={transaction?.id}
                />
                <div style={styles.middleItem}>
                  <p style={styles.title} className="card-subtitle mb-2">
                    Código de transacción
                  </p>
                  <p
                    style={styles.transactionHash}
                    className="card-subtitle mb-2"
                  >
                    {transaction?.hash?.toUpperCase()}
                  </p>
                </div>
                <div style={styles.middleItem}>
                  <p style={styles.title} className="card-subtitle mb-2">
                    Fecha de creación
                  </p>
                  <p style={styles.date} className="card-subtitle mb-2">
                    {transaction?.created_at &&
                      formatLocaleDate(
                        transaction?.created_at,
                        "d MMM yyyy, h:mm aaaa"
                      )}
                  </p>
                </div>
                {getSourceOfFundsLabel(sourceOfFunds) && (
                  <div style={styles.lastItem}>
                    <p style={styles.title} className="card-subtitle mb-2">
                      Origen de fondos
                    </p>
                    <p
                      style={styles.sourceOfFunds}
                      className="card-subtitle mb-2"
                    >
                      {getSourceOfFundsLabel(sourceOfFunds)}
                      <IconButton
                        onClick={() => {
                          setSourceOfFundsModal(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </p>
                  </div>
                )}
                {sourceOfFundsActive &&
                  !getSourceOfFundsLabel(sourceOfFunds) && (
                    <div style={styles.lastItem}>
                      <p style={styles.title} className="card-subtitle mb-2">
                        Origen de fondos
                      </p>
                      <p
                        style={{
                          color: "#6938EF",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "20px",
                          cursor: "pointer",
                        }}
                        className="card-subtitle mb-2"
                      >
                        Agregar
                        <IconButton
                          onClick={() => {
                            setSourceOfFundsModal(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </p>
                    </div>
                  )}
                {type === "payroll" && (
                  <div style={styles.middleItem}>
                    <p style={styles.title} className="card-subtitle mb-2">
                      Cantidad de empleados
                    </p>
                    <p style={styles.date} className="card-subtitle mb-2">
                      {trxRows.length}
                    </p>
                  </div>
                )}
              </div>
              <div
                style={{
                  ...styles.rowsContainer,
                  marginBottom:
                    transaction.status === "pay_later" ? "84px" : "24px",
                }}
              >
                {type === "payroll" &&
                  trxRows.length > 0 &&
                  trxRows.map((row, index) => {
                    return (
                      <ItemsInPayrollTransaction
                        row={row}
                        type={type}
                        transaction={transaction}
                        requestInProcess={requestInProcess}
                        setRequestInProcess={setRequestInProcess}
                      />
                    );
                  })}
                {(type === "invoice" || type === "collections") &&
                  [trxRows].length > 0 && (
                    <>
                      {suppliers.map((sup, index) => {
                        return (
                          <ItemsInTransaction
                            key={index}
                            index={index}
                            rows={trxRows[sup]}
                            suppliers={suppliers}
                            type={type}
                            transaction={transaction}
                            requestInProcess={requestInProcess}
                            setRequestInProcess={setRequestInProcess}
                          />
                        );
                      })}
                    </>
                  )}
                {type !== "collections" && (
                  <>
                    {renderComission()}
                    <ComissionRetentions transaction={transaction} />
                  </>
                )}
              </div>
            </>
          )}
        </Stack>
        {transaction && (
          <Stack
            spacing={2}
            justifyContent="flex-end"
            sx={{
              backgroundColor: "#f5f6f7",
              position: "absolute",
              bottom: "0px",
              left: "0px",
              right: "0px",
            }}
          >
            <Box
              style={styles.footer}
              p={2}
              sx={{ paddingLeft: isMobile ? "24px" : "16px" }}
            >
              <Typography style={styles.amountTitle}>
                {transaction.status === "pay_later"
                  ? "Monto total a pagar:"
                  : "Monto total transferido:"}
              </Typography>
              <Typography style={styles.amount}>
                <NumericFormat
                  value={parseInt(transaction?.amount)}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                  decimalScale={2}
                />
              </Typography>
            </Box>
            {isUserAllowedTo(permissions.INVOICES_CREATE) &&
              transaction &&
              transaction.status === "pay_later" &&
              transaction.amount >= 2000 && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{ paddingBottom: "16px" }}
                  px={2}
                >
                  <PrimaryButton
                    text={"Pagar ahora"}
                    action={() =>
                      openWompiModal({
                        visible: true,
                        totalAmount: transaction.amount,
                        selectedRowsIds:
                          type === "invoice" ? invoicesIds : selectedRowsIds,
                        selectedSuppliers: suppliers,
                      })
                    }
                    width="100%"
                  />
                </Box>
              )}
            {transaction &&
              transaction.status === "pay_later" &&
              transaction.amount < 1500 && (
                <Typography px={2} style={styles.title}>
                  No se puede procesar el pago porque el importe es menor al
                  requerido ($2.500)
                </Typography>
              )}
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
};

function PaymentDelayedAlert({
  visible,
  id,
  setOpenModalKeys,
  setClosedModalKeys,
  closeDrawer,
  onClose,
}) {
  return visible ? (
    <div
      style={{
        color: "#7A2E0E",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "18px",
        borderRadius: "6px",
        padding: "12px 16px",
        background: "#FEF0C7",
        marginBottom: 16,
      }}
    >
      <span>
        Estamos esperando la confirmación del pago. Usualmente tarda segundos,
        pero <strong>en algunos casos puede tomar hasta 10 minutos.</strong>
      </span>
      <br />
      <br />
      <span>
        Si tuviste algún inconveniente o deseas modificar la transacción puedes
        elegir alguna de estas opciones.
      </span>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="16px"
        sx={{ marginTop: 2 }}
      >
        <SecondaryButton
          width="100%"
          text="Cancelar pago"
          action={async () => {
            closeDrawer();
            await new Promise((r) => setTimeout(r, 250));
            setOpenModalKeys("cancel-transaction-modal", {
              onClose: () => {
                setClosedModalKeys("cancel-transaction-modal");
              },
              onConfirm: () => {
                requestContainer({
                  url: "/transactions/ldp/" + id,
                  method: "delete",
                }).then(() => {
                  onClose();
                  setClosedModalKeys("cancel-transaction-modal");
                });
              },
            });
          }}
        />
        <PrimaryButton
          width="100%"
          text="Reintentar pago"
          action={() => {
            requestContainer({ url: "/transactions/ldp/" + id }).then(
              (response) => {
                const { linkUrl } = response;
                if (!linkUrl) {
                  return toast.error(
                    "El link de pago no se generó correctamente, disculpe las molestias.",
                    toastOptions
                  );
                }
                window.location = linkUrl;
              }
            );
          }}
        />
      </Stack>
    </div>
  ) : null;
}
