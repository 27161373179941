import { requestContainer } from "../requestContainer";

export async function GetAssistedExpenses({ document_number, receipt_id }) {
  return requestContainer({
    method: "get",
    url: `/accountingReceipt/get-details?document_number=${document_number}&receipt_id=${receipt_id}`,
  });
}

export async function GetPaymentMethods(query = "") {
  return requestContainer({
    method: "get",
    url: `/accountingReceipt/get-payment-methods?query=${query}`,
  });
}

export async function PostAssistedExpenses(data) {
  return requestContainer({
    method: "post",
    url: "/accountingReceipt/create-accounting-receipt",
    data,
  });
}
