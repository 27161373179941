import { IconButton, MenuItem, Select } from "@mui/material";
import { DeleteIcon } from "../assets";
import { AutocompleteTable, Tooltip } from "commons/components";
import {
  InputDescription,
  InputEditNumberRow,
  InputNumberRow,
} from "commons/modals/ExpandCausationModal/components";

export const expandCausationColumns = (
  searchProductsOptions,
  taxesIva,
  taxesRete,
  handleOnChangeItem,
  handleDeleteItem,
  getSearchOptionsProducts,
  isCaused
) => [
  {
    field: "product",
    headerName: "Producto",
    sortable: false,
    flex: 1,
    headerAlign: "center",
    cellClassName: "super-app-theme--cell",
    renderCell: (params) => {
      return (
        <AutocompleteTable
          name="product"
          disabled={isCaused}
          options={searchProductsOptions}
          tabIndex={params.tabIndex}
          getOptionLabel={(option) =>
            option?.fname
              ? `${option?.fcode.trim()} - ${option?.fname.trim()}`
              : ""
          }
          onChange={(event, option) => {
            event.preventDefault();
            handleOnChangeItem(params, "product", option);
          }}
          onInputChange={(event) => {
            getSearchOptionsProducts("product", event?.target?.value);
          }}
          value={params.value}
          tableConfig={{
            columns: [
              {
                headerName: "Código",
                getText: (option) => option?.fcode,
              },
              {
                headerName: "Descripción",
                getText: (option) => option?.fname.trim(),
              },
            ],
            rowClassName: "causation",
            getRowTooltipText: (option) =>
              `${option?.fcode} - ${option?.fname}`,
          }}
        />
      );
    },
  },
  {
    field: "description",
    headerName: "Descripción",
    sortable: false,
    flex: 1,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <InputDescription
          isCaused={isCaused}
          valueRow={params.value}
          onChange={(value) => handleOnChangeItem(params, "description", value)}
        />
      );
    },
  },
  {
    field: "quantity",
    headerName: "Cant.",
    type: "number",
    editable: !isCaused,
    width: 80,
    sortable: false,
    headerAlign: "center",
    renderEditCell: (params) => {
      return (
        <InputEditNumberRow
          isCaused={isCaused}
          valueRow={params.value}
          onChange={(value) => handleOnChangeItem(params, "quantity", value)}
          formatMoney={false}
          {...params}
        />
      );
    },
  },
  {
    field: "unitValue",
    headerName: "Valor unitario",
    type: "number",
    editable: !isCaused,
    sortable: false,
    width: 160,
    headerAlign: "center",
    renderCell: (params) => {
      return <InputNumberRow value={params.value} />;
    },
    renderEditCell: (params) => {
      return (
        <InputEditNumberRow
          isCaused={isCaused}
          valueRow={params.value}
          onChange={(value) => handleOnChangeItem(params, "unitValue", value)}
          formatMoney={true}
          {...params}
        />
      );
    },
  },
  {
    field: "discount",
    headerName: "Descuento",
    type: "number",
    editable: !isCaused,
    sortable: false,
    width: 160,
    headerAlign: "center",
    renderCell: (params) => {
      return <InputNumberRow value={params.value} />;
    },
    renderEditCell: (params) => {
      return (
        <InputEditNumberRow
          isCaused={isCaused}
          valueRow={params.value}
          onChange={(value) => handleOnChangeItem(params, "discount", value)}
          formatMoney={true}
          {...params}
        />
      );
    },
  },
  {
    field: "taxIva",
    headerName: "Imp. Cargo",
    sortable: false,
    width: 95,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <Tooltip title={params?.value?.description}>
          <Select
            disabled={isCaused}
            name="taxIva"
            size="small"
            displayEmpty={true}
            onChange={(e) =>
              handleOnChangeItem(params, "taxIva", e.target.value)
            }
            sx={stylesSelectedTaxs}
            value={params.value.id}
          >
            <MenuItem value={null} style={{ height: "36px" }}>
              {" "}
            </MenuItem>
            {taxesIva.map((tax) => (
              <MenuItem key={tax.id} value={tax.id}>
                {tax.description}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      );
    },
  },
  {
    field: "taxRetefuente",
    headerName: "Imp. Retención",
    sortable: false,
    width: 95,
    headerAlign: "center",
    renderCell: (params) => (
      <Tooltip title={params?.value?.description}>
        <Select
          disabled={isCaused}
          name="taxRetefuente"
          size="small"
          displayEmpty={true}
          fullWidth
          onChange={(e) =>
            handleOnChangeItem(params, "taxRetefuente", e.target.value)
          }
          value={params.value.id}
          sx={stylesSelectedTaxs}
        >
          <MenuItem value={null} style={{ height: "36px" }}>
            {}
          </MenuItem>
          {taxesRete.map((tax) => (
            <MenuItem key={tax.id} value={tax.id}>
              {tax.description}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    ),
  },
  {
    field: "amountTotal",
    headerName: "Valor Total",
    sortable: false,
    width: 120,
    headerAlign: "center",
    type: "number",
    renderCell: (params) => {
      return <InputNumberRow value={params.value} />;
    },
  },
  {
    field: "deleteItem",
    headerName: "",
    sortable: false,
    width: 60,
    renderCell: (params) => {
      return (
        <IconButton onClick={() => handleDeleteItem(params.id)}>
          <DeleteIcon />
        </IconButton>
      );
    },
  },
];

const stylesSelectedTaxs = {
  fontSize: "13px",
  width: "100%",
  height: "100%",
  "& fieldset": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    display: "none",
  },
  "& .MuiInputBase-root": {
    width: "100%",
  },
  "& .MuiSelect-select": {
    padding: "0px !important",
    width: "100%",
    height: "100%",
    maxHeight: "50px",
    display: "flex",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
};
