import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { fixNumber } from "utils";
import {
  INITIAL_PAYMENT_METHOD,
  isAdvance,
  typePaymentOnChange,
} from "../../constants";
import { PaymentRow } from "./PaymentRow";
import { styles } from "./styles";

export const Payments = ({
  paymentsSearch = [],
  paymentsSelected = [],
  amountNeto,
  paymentsAdvances,
  handleSetPaymentsAdvances,
  handleSetPayments,
  dueDate,
  showTutorial,
}) => {
  const [valueAdvance, setValueAdvance] = useState(0);
  const [amountRegister, setAmountRegister] = useState(0);

  const amountAvailable =
    fixNumber(amountNeto - amountRegister) > 0
      ? fixNumber(amountNeto - amountRegister)
      : 0;

  const addNewPayment = () => {
    handleSetPayments((prev) => [...prev, { ...INITIAL_PAYMENT_METHOD }]);
  };

  const formatValuePayment = (value, preValue, amountAvailable, dueDate) => {
    if (value === null) {
      return { ...INITIAL_PAYMENT_METHOD };
    }
    if (value?.accounting_concept === isAdvance) {
      return {
        ...preValue,
        accounting_concept: value?.accounting_concept,
        name: value?.accounting_concept,
        data: {},
      };
    }

    const { amount, accounting_concept, name, ...data } = value;
    return {
      ...preValue,
      accounting_concept: accounting_concept,
      name: name,
      data: { ...data, ...(value?.due_type === -1 && { dueDate: dueDate }) },
      amount: amountAvailable,
    };
  };

  const deselectAdvance = (advancePrevValue, paymentsUpdate, index) => {
    if (advancePrevValue.data?.AcDueBalanceID) {
      const advanceUpdate = paymentsAdvances.map((advance) => {
        if (advance.AcDueBalanceID === advancePrevValue.data.AcDueBalanceID) {
          return {
            ...advance,
            isUsed: false,
          };
        }
        return advance;
      });
      handleSetPaymentsAdvances(advanceUpdate);
    }
    paymentsUpdate[index] = {
      ...paymentsUpdate[index],
      data: {},
      amount: 0,
    };

    handleSetPayments(paymentsUpdate);
  };

  const setSelectedAdvance = (
    value,
    paymentsUpdate,
    amountAvailable,
    index
  ) => {
    const advance = paymentsAdvances.find(
      (advance) => advance.AcDueBalanceID === value
    );

    const advancePrev = { ...paymentsUpdate[index].data };

    if (advance) {
      paymentsUpdate[index] = {
        ...paymentsUpdate[index],
        data: advance,
        amount:
          advance?.Value > amountAvailable ? amountAvailable : advance?.Value,
      };
      advance.isUsed = true;
      const advancesUpdate = paymentsAdvances.filter(
        (advance) => advance.AcDueBalanceID !== value
      );
      let advancePrevUpdate = advancesUpdate;
      if (advancePrev.AcDueBalanceID) {
        advancePrevUpdate = advancesUpdate.map((advance) => {
          if (advance.AcDueBalanceID === advancePrev.AcDueBalanceID) {
            return {
              ...advance,
              isUsed: false,
            };
          }
          return advance;
        });
      }
      handleSetPaymentsAdvances([...advancePrevUpdate, { ...advance }]);
    }
  };

  const handleOnChangePayment = (index, value, typeChange) => {
    const paymentsUpdate = [...paymentsSelected];

    if (typeChange === typePaymentOnChange.payment) {
      paymentsUpdate[index] = formatValuePayment(
        value,
        paymentsUpdate[index],
        amountAvailable,
        dueDate
      );
    } else if (typeChange === typePaymentOnChange.advance) {
      if (!value) {
        deselectAdvance(paymentsUpdate[index], paymentsUpdate, index);
        return;
      }

      setSelectedAdvance(value, paymentsUpdate, amountAvailable, index);
    } else if (typeChange === typePaymentOnChange.dueDate) {
      paymentsUpdate[index].data.dueDate = value;
    } else if (typeChange === typePaymentOnChange.amount) {
      paymentsUpdate[index].amount = value;
    }

    handleSetPayments(paymentsUpdate);
  };

  const handleDeletePayment = (index) => {
    const payment = paymentsSelected[index];
    if (payment.data?.AcDueBalanceID) {
      const advanceUpdate = paymentsAdvances.map((advance) => {
        if (advance.AcDueBalanceID === payment.data.AcDueBalanceID) {
          return {
            ...advance,
            isUsed: false,
          };
        }
        return advance;
      });
      handleSetPaymentsAdvances(advanceUpdate);
    }
    if (index > -1) {
      const paymentsUpdate = [...paymentsSelected];
      paymentsUpdate.splice(index, 1);
      if (paymentsUpdate.length === 0) {
        handleSetPayments([{ ...INITIAL_PAYMENT_METHOD }]);
      } else {
        handleSetPayments(paymentsUpdate);
      }
    }
  };

  const setAdvanceAndAmount = () => {
    const advance = paymentsSearch.find(
      (payment) => payment?.accounting_concept === isAdvance
    );
    if (advance) {
      const value = advance?.purchaseAdvances.reduce(
        (acc, curr) => acc + curr.Value,
        0
      );
      setValueAdvance(value);
      handleSetPaymentsAdvances(
        advance?.purchaseAdvances?.map((advance) => ({
          isUsed: false,
          ...advance,
        }))
      );
    }
  };

  const getAmountRegisterToPay = () => {
    const totalRegister = paymentsSelected?.reduce((acc, curr) => {
      return acc + +curr.amount;
    }, 0);
    setAmountRegister(totalRegister);
  };

  useEffect(() => {
    setAdvanceAndAmount();
  }, [paymentsSearch]);

  useEffect(() => {
    getAmountRegisterToPay();
  }, [paymentsSelected]);

  return (
    <Stack gap={"8px"}>
      <div
        style={{
          padding: showTutorial ? "24px" : "0px",
        }}
        class="multiplePayments"
      >
        <Typography style={styles.title}>Formas de pago</Typography>
        <Typography style={styles.subtitleProviderAdvance}>
          El proveedor tiene anticipos por{" "}
          <NumericFormat
            tabIndex="0"
            value={valueAdvance}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </Typography>
        <PaymentRow
          paymentsSelected={paymentsSelected}
          handleOnChangePayment={handleOnChangePayment}
          paymentsSearch={paymentsSearch}
          paymentsAdvances={paymentsAdvances}
          handleDeletePayment={handleDeletePayment}
        />
        <AddNewItem
          onClick={addNewPayment}
          text={"+ Agregar otra forma de pago"}
        />
        {amountRegister !== amountNeto && amountRegister !== 0 && (
          <WarningText
            amountNeto={amountNeto}
            text={
              "El total de las formas de pago debe coincidir con el total neto"
            }
            withParenthesis={true}
          />
        )}
        {amountRegister !== amountNeto &&
          amountRegister !== 0 &&
          amountAvailable > 0 && (
            <WarningText
              amountNeto={amountAvailable}
              text={"Resta definir"}
              withParenthesis={false}
            />
          )}
      </div>
    </Stack>
  );
};

const AddNewItem = ({ onClick, text }) => (
  <Typography onClick={onClick} sx={styles.textAddNewItem}>
    {text}
  </Typography>
);

const WarningText = ({ amountNeto, text, withParenthesis }) => {
  return withParenthesis ? (
    <Typography sx={styles.warningText}>
      {text} (
      <NumericFormat
        tabIndex="0"
        value={amountNeto}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
      )
    </Typography>
  ) : (
    <Typography sx={styles.warningText}>
      {text}{" "}
      <NumericFormat
        tabIndex="0"
        value={amountNeto}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    </Typography>
  );
};
