import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { checkInvoicesSiigo } from "../../services";
import { UserContext } from "../../contexts";
import { DianIntegrationModal } from "../../commons/modals/DianIntegrationModal/DianIntegrationModal";

import { SiigoIntegrationModal, TableSkeleton } from "../../commons";
import { EmptyState, CausationComingSoon } from "./components";
import { useCausation } from "../../hooks";
import { CausationContext } from "contexts/CausationContext";

export function CausationScreen() {
  const { checkSiigoCredentials } = useCausation();

  const location = useLocation();
  const { currentCompany, getMe } = useContext(UserContext);
  const { causationScreen } = useContext(CausationContext);
  const [isDianModalVisible, setIsDianModalVisible] = useState(false);

  const [isSiigoModalVisible, setIsSiigoModalVisible] = useState(false);

  const navigate = useNavigate();

  const syncBanner = causationScreen?.syncBanner?.value;
  const setSyncBanner = causationScreen?.syncBanner?.setter;

  const [hasInvoicesSiigo, setHasInvoicesSiigo] = useState(false);
  const [causationComingSoon, setCausationComingSoon] = useState(false);
  const [causationComingSoonType, setCausationComingSoonType] = useState(null);
  useEffect(() => {
    checkInvoicesSiigo().then((resp) => setHasInvoicesSiigo(resp));
    if (location.state) {
      setCausationComingSoon(location.state.causationComingSoon);
      setCausationComingSoonType(location.state.type);
    }
  }, []);

  const checkIfShouldMoveToStepTwo = async () => {
    const haveCredentials = await checkSiigoCredentials();
    if (haveCredentials) {
      setSyncCS((prev) => ({ ...prev, siigo: true }));
    }
  };

  useEffect(() => {
    checkIfShouldMoveToStepTwo();
    if (
      currentCompany?.name_cs?.toUpperCase() === "DIAN" &&
      currentCompany?.last_sync_at
    ) {
      setSyncCS((prev) => ({ ...prev, dian: true }));
    }
  }, [currentCompany]);

  const handleSiigoCloseModal = async () => {
    setIsSiigoModalVisible(false);
    checkIfShouldMoveToStepTwo();
  };

  const [syncCS, setSyncCS] = useState({
    siigo: false,
    dian: false,
  });

  const showEmptyStateSync = !syncCS.siigo || !syncCS.dian;
  const NAME_CS_SIIGO = "Siigo";

  const goToInvoicesScreen = () => {
    navigate("/payments/invoices", {
      replace: true,
    });
  };

  return (
    <>
      <DianIntegrationModal
        visible={isDianModalVisible}
        setIsDianModalVisible={setIsDianModalVisible}
        refreshInvoiceTable={() => {
          getMe();
        }}
      />

      <SiigoIntegrationModal
        visible={isSiigoModalVisible}
        setIsSiigoModalVisible={handleSiigoCloseModal}
        isCausation={true}
        setSyncBanner={setSyncBanner}
      />
      {causationComingSoon ||
      (currentCompany?.name_cs === NAME_CS_SIIGO && hasInvoicesSiigo) ? (
        <CausationComingSoon
          companyName={currentCompany.name}
          type={causationComingSoonType}
          setCausationComingSoon={setCausationComingSoon}
        />
      ) : showEmptyStateSync ? (
        <EmptyState
          setIsDianModalVisible={setIsDianModalVisible}
          setIsSiigoModalVisible={setIsSiigoModalVisible}
          syncCS={syncCS}
          syncBanner={syncBanner}
          setSyncBanner={setSyncBanner}
        />
      ) : (
        <>{goToInvoicesScreen()}</>
      )}
    </>
  );
}
