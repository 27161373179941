import { createContext, useState } from "react";
import {
  GetBOSuppliers,
  CollectionsApproval,
  DeleteCollection,
  DeleteCollections,
  GetCollection,
  GetCollectionsInternal,
  CollectionChargeWithholdings,
  CollectionMakePartialPayment,
  CollectionModifyAmountTotal,
  CreateCollection,
  UpdateCollection,
  UploadCollectionsFile,
  GetCustomers,
  GetAllCustomers,
  GetCustomer,
  CreateCustomer,
  DeleteCustomer,
  UpdateCustomer,
  GetCollectionsByHash,
  GetCustomerByHash,
  GetMainReceiver,
  UpdateReceiver,
  GetReceivers,
  GetReceiver,
  CreateReceiver,
  GetBanks,
  DeleteReceiver,
  GetBOCustomer,
  UpdateBOCustomer,
} from "../services";
import { MultiAccountModal } from "../commons/modals";
import { toast } from "react-toastify";
import { toastOptions } from "../constants";

export const CollectionsContext = createContext();

export function CollectionsProvider({ children }) {
  const [multiAccountModalIsOpen, setMultiAccountModalIsOpen] = useState(false);
  const [syncIsLoading, setSyncIsLoading] = useState(false);
  const [syncHasError, setSyncHasError] = useState(false);
  const [syncBtnIsDisabled, setSyncBtnIsDisabled] = useState(false);
  const [statusSelected, setStatusSelected] = useState("all");
  const [customerIdSelected, setCustomerIdSelected] = useState("all");
  const [perPageSelected, setPerPageSelected] = useState(100);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [sortingSelected, setSortingQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(1);

  const [customerForCollectionsScreen, setCustomerForCollectionsScreen] =
    useState({});
  const [
    customerIdSelectedForCollectionsScreen,
    setCustomerIdSelectedForCollectionsScreen,
  ] = useState("all");
  const [syncBannerForCollectionsScreen, setSyncBannerForCollectionsScreen] =
    useState({ visible: false, type: "", title: "", text: "" });

  const [customerForCustomersScreen, setCustomerForCustomersScreen] = useState(
    {}
  );
  const [
    customerIdSelectedForCustomersScreen,
    setCustomerIdSelectedForCustomersScreen,
  ] = useState("all");

  // COLLECTIONS
  const [collections, setCollections] = useState([]);
  const [collectionsByHash, setCollectionsByHash] = useState([]);

  const internalGetCollections = async (
    pageIndex,
    status,
    customerId,
    perPage,
    sorting
  ) => {
    const gottenCollections = await GetCollectionsInternal({
      pageIndex: pageIndex === 0 ? 1 : pageIndex,
      status: status || statusSelected,
      customerId: customerId || customerIdSelectedForCollectionsScreen,
      perPage: perPage || perPageSelected,
      sorting: sorting || sortingSelected,
    });
    if (gottenCollections?.data) {
      setCollections(
        gottenCollections.data.map((collection) => ({
          ...collection,
          numberOfNotes: collection.number_of_notes || 0,
        }))
      );
    }
  };

  const getCollectionsByHash = async (
    pageIndex,
    status,
    hash,
    perPage,
    sorting
  ) => {
    const gottenCollections = await GetCollectionsByHash({
      pageIndex: pageIndex === 0 ? 1 : pageIndex,
      status: status || statusSelected,
      hash: hash || null,
      perPage: perPage || perPageSelected,
      sorting: sorting || sortingSelected,
    });
    if (gottenCollections !== null) {
      setCollectionsByHash(
        gottenCollections.data.map((collection) => ({
          ...collection,
        }))
      );
    }
  };

  const getCollection = async (id) => {
    if (id) {
      const gottenCollection = await GetCollection({
        id,
      });
      return gottenCollection.data;
    }
  };

  const deleteSelectedCollections = async (ids, pageIndex = 1) => {
    await DeleteCollections(ids);
    internalGetCollections(
      pageIndex,
      statusSelected,
      customerIdSelectedForCollectionsScreen,
      perPageSelected,
      sortingSelected
    );
    toast.success("¡Los documentos se borraron con éxito!", toastOptions);
  };

  const approveCollections = async (ids) => {
    const response = await CollectionsApproval(ids);
    if (response) {
      internalGetCollections(
        1,
        statusSelected,
        customerIdSelectedForCollectionsScreen,
        perPageSelected,
        sortingSelected
      );
      toast.success("¡Los documentos se aprobaron con éxito!", toastOptions);
      return 200;
    } else {
      toast.error("Hubo un error.", toastOptions);
      return "error";
    }
  };

  const deleteCollection = async (collectionId, pageIndex = 1) => {
    const response = await DeleteCollection({ id: collectionId });
    if (response) {
      internalGetCollections(
        pageIndex,
        statusSelected,
        customerIdSelectedForCollectionsScreen,
        perPageSelected,
        sortingSelected
      );
      toast.success("¡El documento se borró con éxito!", toastOptions);
    } else {
      toast.error("Hubo un error.", toastOptions);
    }
  };

  const changeStatus = (ids, status) => {
    const updatedCollections = collections.map((item, index) => {
      if (ids.includes(item.id)) {
        return {
          ...item,
          status: status,
        };
      } else {
        return item;
      }
    });
    setCollections(updatedCollections);
  };

  const changeAmount = async (
    value,
    id,
    withholdings,
    selectedOption,
    partialAmount,
    fullAmount,
    pageIndex
  ) => {
    let response = {};
    if (value === "1") {
      response = await CollectionChargeWithholdings({
        id: id,
        amount: withholdings,
        type: selectedOption,
      });
    } else if (value === "2") {
      response = await CollectionMakePartialPayment({
        id: id,
        amount: partialAmount,
      });
    } else if (value === "3") {
      response = await CollectionModifyAmountTotal({
        id: id,
        amount: fullAmount,
      });
    }
    if (response.message === "ok") {
      internalGetCollections(
        pageIndex,
        statusSelected,
        customerIdSelectedForCollectionsScreen,
        perPageSelected,
        sortingSelected
      );
    }
    return response.status;
  };

  const createCollection = async (formValues) => {
    const response = await CreateCollection({
      formValues: formValues,
    });
    if (response) {
      await internalGetCollections(
        1,
        statusSelected,
        customerIdSelectedForCollectionsScreen,
        perPageSelected,
        sortingSelected
      );
      return response;
    } else {
      return "error";
    }
  };

  const updateCollection = async (id, formValues) => {
    const response = await UpdateCollection({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      internalGetCollections(
        1,
        statusSelected,
        customerIdSelectedForCollectionsScreen,
        perPageSelected,
        sortingSelected
      );
      return 200;
    } else {
      return "error";
    }
  };

  const uploadCollectionFile = async (selectedFiles) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const fileToUpload = selectedFiles[0];
      return UploadCollectionsFile(fileToUpload);
    }
  };

  // CUSTOMERS
  const [allCustomers, setAllCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [BOCustomers, setBOCustomers] = useState([]);

  const getCustomers = async (pageIndex, customerId) => {
    const gottenCustomers = await GetCustomers({
      pageIndex,
      customerId,
    });
    if (gottenCustomers !== null) {
      setCustomers(gottenCustomers);
    }
  };

  const getAllCustomers = async () => {
    const gottenCustomers = await GetAllCustomers();
    if (gottenCustomers !== null) {
      setAllCustomers(gottenCustomers);
    }
  };

  const getBOCustomers = async (pageIndex, customerId, companyId) => {
    const gottenCustomers = await GetBOSuppliers({
      pageIndex,
      customerId,
      companyId,
    });
    if (gottenCustomers !== null) {
      setBOCustomers(gottenCustomers);
    }
  };

  const getCustomer = async (id) => {
    if (id) {
      const gottenCustomer = await GetCustomer({
        id,
      });
      return gottenCustomer;
    }
  };

  const getBOCustomer = async (id) => {
    if (id) {
      const gottenCustomer = await GetBOCustomer({
        id,
      });
      return gottenCustomer;
    }
  };

  const getCustomerByHash = async (hash) => {
    if (hash) {
      const gottenCustomer = await GetCustomerByHash({
        hash,
      });
      return gottenCustomer;
    }
  };

  const createCustomer = async (formValues) => {
    const response = await CreateCustomer({
      formValues: formValues,
    });
    if (response) {
      getCustomers(1, "all");
      return response;
    }
    return "error";
  };

  const deleteCustomer = async (id) => {
    const response = await DeleteCustomer({ id: id });
    if (response) {
      const updatedCustomers = customers.filter(
        (customer) => customer.id !== id
      );
      setCustomers(updatedCustomers);
      return 200;
    }
    return "error";
  };

  const updateCustomer = async (id, formValues) => {
    const response = await UpdateCustomer({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      getCustomers(1, "all");
      return 200;
    }
    return "error";
  };

  const updateBOCustomer = async (id, formValues) => {
    const response = await UpdateBOCustomer({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      return 200;
    }
    return "error";
  };

  // RECEIVERS
  const [receivers, setReceivers] = useState([]);
  const [mainReceiver, setMainReceiver] = useState({});
  const [banks, setBanks] = useState([]);

  const getMainReceiver = async () => {
    const gottenReceiver = await GetMainReceiver();
    if (gottenReceiver !== null) {
      setMainReceiver(gottenReceiver);
    }
  };

  const getReceivers = async (pageIndex, providerId) => {
    const gottenReceivers = await GetReceivers({
      pageIndex,
      providerId,
    });
    if (gottenReceivers !== null) {
      setReceivers(gottenReceivers);
    }
  };

  const getReceiver = async (id) => {
    if (id) {
      const gottenReceiver = await GetReceiver({
        id,
      });
      return gottenReceiver;
    }
  };

  const createReceiver = async (formValues) => {
    const response = await CreateReceiver({
      formValues: formValues,
    });
    if (response) {
      getReceivers(1, "all");
      return response;
    }
    return "error";
  };

  const updateReceiver = async (
    id,
    formValues,
    pageIndex = 1,
    providerId = "all"
  ) => {
    const response = await UpdateReceiver({
      id: parseInt(id),
      formValues: formValues,
    });
    if (response) {
      getReceivers(pageIndex, providerId);
      return 200;
    }
    return "error";
  };

  const deleteReceiver = async (id) => {
    const response = await DeleteReceiver({ id: id });
    if (response) {
      const updatedReceivers = receivers.filter(
        (receiver) => receiver.id !== id
      );
      setReceivers(updatedReceivers);
      return 200;
    }
    return "error";
  };

  const getBanks = async (pageIndex = 1) => {
    const gottenBanks = await GetBanks({
      pageIndex,
    });
    if (gottenBanks !== null) {
      setBanks(gottenBanks);
    }
  };

  return (
    <CollectionsContext.Provider
      value={{
        collections,
        internalGetCollections,
        collectionsScreen: {
          customerFilter: {
            value: customerForCollectionsScreen,
            setter: setCustomerForCollectionsScreen,
          },
          customerIdSelected: {
            value: customerIdSelectedForCollectionsScreen,
            setter: setCustomerIdSelectedForCollectionsScreen,
          },
          syncBanner: {
            value: syncBannerForCollectionsScreen,
            setter: setSyncBannerForCollectionsScreen,
          },
          pageIndex,
          setPageIndex,
        },
        customersScreen: {
          customerFilter: {
            value: customerForCustomersScreen,
            setter: setCustomerForCustomersScreen,
          },
          customerIdSelected: {
            value: customerIdSelectedForCustomersScreen,
            setter: setCustomerIdSelectedForCustomersScreen,
          },
        },
        setCollections,
        createCollection,
        updateCollection,
        getCollection,
        deleteSelectedCollections,
        deleteCollection,
        changeStatus,
        changeAmount,
        getCustomers,
        getAllCustomers,
        getBOCustomers,
        customers,
        allCustomers,
        BOCustomers,
        setCustomers,
        setBOCustomers,
        createCustomer,
        deleteCustomer,
        updateCustomer,
        getCustomer,
        syncIsLoading,
        setSyncIsLoading,
        syncHasError,
        setSyncHasError,
        syncBtnIsDisabled,
        setSyncBtnIsDisabled,
        multiAccountModalIsOpen,
        setMultiAccountModalIsOpen,
        uploadCollectionFile,
        approveCollections,
        statusSelected,
        setStatusSelected,
        customerIdSelected,
        setCustomerIdSelected,
        perPageSelected,
        setPerPageSelected,
        sortingSelected,
        tagsSelected,
        setTagsSelected,
        setSortingQuery,
        collectionsByHash,
        setCollectionsByHash,
        getCollectionsByHash,
        getCustomerByHash,
        getMainReceiver,
        receivers,
        getReceivers,
        setReceivers,
        updateReceiver,
        getReceiver,
        createReceiver,
        banks,
        getBanks,
        setBanks,
        mainReceiver,
        setMainReceiver,
        deleteReceiver,
        getBOCustomer,
        updateBOCustomer,
      }}
    >
      {multiAccountModalIsOpen && <MultiAccountModal />}
      {children}
    </CollectionsContext.Provider>
  );
}
