import React from "react";

export const ConnectorIcon = ({
  color = "#6938EF",
  width = "3",
  height = "34",
}) => (
  <svg width={width} height={height} viewBox="0 0 3 34" fill="none">
    <rect x="0.5" width="2" height="34" rx="1" fill={color} />
  </svg>
);
