import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Dialog, Stack } from "@mui/material";
import {
  PaymentMade,
  DischargeRecord,
  ExpandAssistedExpensesHeader,
} from "./components";
import { TableSkeleton } from "../../../commons";
import { TYPE } from "./constants";
import { ModalReponse } from "./components/ModalReponse";
import { useAssistedExpenses } from "hooks";

export function ExpandAssistedExpenses() {
  const params = useParams();
  const {
    items,
    selectedRows,
    amountTotalSelect,
    receiptDetails,
    comment,
    amountAvailable,
    modalState,
    paymentMethods,
    formValues,
    errors,
    loading,
    getAssistedExpenses,
    getPaymentMethods,
    toggleSelectedRow,
    onSubmit,
    register,
    handleOnChangeAmountRegister,
    handleOnChangeInputPaymentMethod,
    isChecked,
    handleChangeForm,
  } = useAssistedExpenses();

  const { receipts } = receiptDetails;

  useEffect(() => {
    const { document_number, receipt_id } = params;
    getAssistedExpenses(document_number, receipt_id);
    getPaymentMethods();
  }, [params]);

  const disabledButtonToSiigo =
    (selectedRows.length > 0 &&
      amountTotalSelect > 0 &&
      amountTotalSelect <= receipts?.total) ||
    formValues.type !== TYPE.debtPayment;

  return (
    <>
      <ModalReponse
        open={modalState.open}
        type={modalState.type}
        text={modalState.message}
      />
      <Dialog
        fullScreen
        open={true}
        style={{
          zIndex: 1000,
        }}
      >
        {loading ? (
          <TableSkeleton />
        ) : (
          <>
            <ExpandAssistedExpensesHeader
              handleSubmit={onSubmit}
              disabledButtonToSiigo={disabledButtonToSiigo}
            />
            <Stack marginX={"auto"} marginY={"24px"}>
              <Box alignItems={"center"} justifyContent={"center"} width={600}>
                <PaymentMade receiptDetails={receiptDetails} />
                <DischargeRecord
                  receiptDetails={receiptDetails}
                  amountTotalSelect={amountTotalSelect}
                  amountAvailable={amountAvailable}
                  paymentMethods={paymentMethods}
                  handleOnChangeAmountRegister={handleOnChangeAmountRegister}
                  formState={formValues}
                  items={items}
                  register={register}
                  errors={errors}
                  toggleSelectedRow={toggleSelectedRow}
                  onChangeInputPaymentMethod={handleOnChangeInputPaymentMethod}
                  comment={comment}
                  isChecked={isChecked}
                  handleChangeForm={handleChangeForm}
                />
              </Box>
            </Stack>
          </>
        )}
      </Dialog>
    </>
  );
}
