export const operationType = {
  invoices: "Lote de pago a proveedores",
  invoice: "Pago a proveedor",
  payrolls: "Lote de pago a nómina",
  payroll: "Pago a nómina",
  collections: "Liquidación cobro",
  collectionCustomer: "Cobro",
  collectionReceiver: "División cobro",
  payana_commission: "Comisión Payana",
};

export const commissionType = {
  card: "Comisión por tarjeta",
  others: "Comisión por PSE",
};

export const transactionDetailTitle = {
  invoice: "Información sobre el pago",
  payroll: "Información sobre el pago",
  collection: "Información general",
  payana_commission: "Información general",
};

export const transactionEventType = {
  invoice: "pagos_proveedores",
  payroll: "pagos_empleados",
  collection: "cobros",
  payana_commission: "comision_payana",
};

export const beneficiaryTypeOptions = {
  provider: "provider_id",
  customer: "customer_id",
  employee: "employee_id",
  receiver: "receiver_id",
};

export const retentionName = {
  reteica: "ReteICA",
  retefuente: "ReteFuente",
};

export const transactionsAccountTypes = [
  {
    value: "SAVINGS_ACCOUNT",
    label: "CA",
  },
  {
    value: "CHECKING_ACCOUNT",
    label: "CC",
  },
];

export const getTransactionsAccountTypeLabel = (type) => {
  return transactionsAccountTypes.find(
    (accountType) => accountType.value === type
  )?.label;
};
