import React, { useContext } from "react";
import { CSVBoxButton } from "@csvbox/react";
import { toast } from "react-toastify";
import { toastOptions } from "../../../constants";
import {
  checkInvoicesNews,
  getFromLocalStorage,
  saveInLocalStorage,
} from "../../../utils";
import { CollectionsContext, PaymentsContext } from "../../../contexts";
import {
  GetTotalCollections,
  GetTotalInvoices,
  UploadCollectionFileCSV2,
  UploadInvoiceFileCSVV2,
  UploadPayrollV2,
  UploadSupplierFileCSVV2,
} from "../../../services";
import ModalWrapper from "../Base/Base";
import { Box, LinearProgress, Typography } from "@mui/material";
import { Modal, Spinner } from "react-bootstrap";
import { UiContext } from "../../../contexts/UiContext";

const errorMessages = {
  "Error: Faltan campos requeridos":
    "El archivo tiene información faltante o incorrecta. Revisa los campos obligatorios e intenta de nuevo.",
  "Error: plantilla incorrecta":
    "El archivo cargado no es correcto. Por favor, utiliza la plantilla provista por Payana.",
  "Error: No se pudo descargar el archivo":
    "Ha ocurrido un error. Por favor, intenta nuevamente.",
  "Error - Para agregar nuevos empleados, se debe especificar el tipo de documento":
    "Por favor, especifica el tipo de documento de los empleados cargados e intenta nuevamente.",
};

const uploadCsvActions = {
  invoice: (data) => UploadInvoiceFileCSVV2(data),
  payroll: (data) => UploadPayrollV2(data),
  supplier: (data) => UploadSupplierFileCSVV2(data),
  collection: (data) => UploadCollectionFileCSV2(data),
};

const CsvHandler = ({
  entity = "invoice",
  licenseKey,
  buttonRef,
  openResultModal,
  setTotals,
}) => {
  const { internalGetInvoices, getSuppliers } = useContext(PaymentsContext);
  const { internalGetCollections } = useContext(CollectionsContext);
  const {
    state: { isModalOpen },
    actions: { toogleModal },
  } = useContext(UiContext);

  if (!licenseKey) {
    return null;
  }

  const handleOnImport = async (result, data) => {
    const lastImportId = getFromLocalStorage("lastImportId");

    if (lastImportId === data.import_id.toString()) {
      return;
    }

    saveInLocalStorage("lastImportId", data.import_id.toString());
    if (result) {
      toogleModal();

      const res = await uploadCsvActions[entity]({
        key: `${data.import_id}_${data.custom_fields.user_id}.csv`,
      });

      const response = JSON.parse(res.lambdaResponse.Payload);

      const requestIsSuccessfull = response.statusCode === 200;

      await handleLambdaResponse[requestIsSuccessfull](response);
    } else {
      toast.error("Hubo un error subiendo el archivo", toastOptions);
    }
  };

  const handleError = (response) => {
    let message = "";
    if (response?.body?.error) {
      message = "Hubo un error subiendo el archivo";
    } else {
      const parseMessage = JSON.parse(response?.body);
      message =
        errorMessages[parseMessage?.error] ||
        "Hubo un error subiendo el archivo";
    }
    toast.error(message, toastOptions);
    toogleModal(false);
  };

  const handleSuppliersSuccess = () => {
    toast.success("¡El archivo se subió con éxito!", toastOptions);
    getSuppliers(1, "all");
    toogleModal(false);
  };

  const handleCollectionsSuccess = () => {
    toast.success("¡El archivo se subió con éxito!", toastOptions);
    internalGetCollections(1);
    GetTotalCollections({
      status: "all",
      customerId: "all",
    }).then((res) => {
      setTotals(res);
    });
    toogleModal(false);
  };

  const triggerResultModal = (response) => {
    toast.success("¡El archivo se subió con éxito!", toastOptions);
    openResultModal(JSON.parse(response.body));
    toogleModal(false);
  };

  const triggerNewsUpdate = (response) => {
    if (response.body?.resources_to_review?.length > 0) {
      toogleModal(false);
      openResultModal(response.body.resources_to_review);
    } else {
      setInterval(async () => {
        const updated = await checkInvoicesNews();
        if (updated) {
          toast.success("¡El archivo se subió con éxito!", toastOptions);
          internalGetInvoices(1);
          GetTotalInvoices({
            status: "all",
            providerId: "all",
            tags: [],
          }).then((res) => {
            setTotals(res);
          });
          toogleModal(false);
        }
      }, 2000);
    }
  };

  const successHandlers = {
    invoice: triggerNewsUpdate,
    payroll: triggerResultModal,
    supplier: handleSuppliersSuccess,
    collection: handleCollectionsSuccess,
  };

  const handleLambdaResponse = {
    true: (response) => successHandlers[entity](response),
    false: (response) => handleError(response),
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={toogleModal} centered>
        <Modal.Body>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography
              sx={{
                paddingX: "1rem",
                paddingY: "1.25rem",
                textAlign: "center",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Estamos procesando la información
            </Typography>
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
      <CSVBoxButton
        licenseKey={licenseKey}
        user={{
          user_id: process.env.REACT_APP_CSVBOX_USER_ID,
        }}
        onImport={handleOnImport}
        render={(launch, isLoading) =>
          ImportButton(launch, isLoading, buttonRef)
        }
      />
    </>
  );
};

const ImportButton = (launch, isLoading, buttonRef) => {
  return (
    <button
      style={{ display: "none" }}
      disabled={isLoading}
      onClick={launch}
      ref={buttonRef}
    />
  );
};

export default CsvHandler;
