import { DRAFT, OUTSTANDING_BALANCE, PENDING, SENT } from "../constants";

export const isRowEnabled = (section, row) => {
  const isCustomerSection =
    section === "customer" && row.values.status === SENT;
  const isCollectionsSection =
    section === "collections" &&
    (row.values.status === PENDING || row.values.status === SENT);
  const isCausationSection =
    section === "causation" && row.values.causation_state === DRAFT;
  const isPending = row.values.status === PENDING;
  const isOutstandingBalance = row.values.status === OUTSTANDING_BALANCE;
  return (
    isCustomerSection ||
    isCollectionsSection ||
    isCausationSection ||
    isPending ||
    isOutstandingBalance
  );
};
