import React from "react";

export const ClipboardIcon = ({ size = "16", stroke = "#475467" }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_754_20946)">
      <path
        d="M3.33398 9.9987C2.71273 9.9987 2.4021 9.9987 2.15707 9.8972C1.83037 9.76188 1.5708 9.50231 1.43548 9.17561C1.33398 8.93058 1.33398 8.61995 1.33398 7.9987V3.46536C1.33398 2.71863 1.33398 2.34526 1.47931 2.06004C1.60714 1.80916 1.81111 1.60519 2.062 1.47736C2.34721 1.33203 2.72058 1.33203 3.46732 1.33203H8.00065C8.62191 1.33203 8.93253 1.33203 9.17756 1.43353C9.50427 1.56885 9.76383 1.82842 9.89916 2.15512C10.0007 2.40015 10.0007 2.71078 10.0007 3.33203M8.13398 14.6654H12.534C13.2807 14.6654 13.6541 14.6654 13.9393 14.52C14.1902 14.3922 14.3942 14.1882 14.522 13.9374C14.6673 13.6521 14.6673 13.2788 14.6673 12.532V8.13203C14.6673 7.38529 14.6673 7.01193 14.522 6.72671C14.3942 6.47583 14.1902 6.27185 13.9393 6.14402C13.6541 5.9987 13.2807 5.9987 12.534 5.9987H8.13398C7.38725 5.9987 7.01388 5.9987 6.72866 6.14402C6.47778 6.27185 6.27381 6.47583 6.14598 6.72671C6.00065 7.01193 6.00065 7.38529 6.00065 8.13203V12.532C6.00065 13.2788 6.00065 13.6521 6.14598 13.9374C6.27381 14.1882 6.47778 14.3922 6.72866 14.52C7.01388 14.6654 7.38725 14.6654 8.13398 14.6654Z"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_754_20946">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
