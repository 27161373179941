export const INITIAL_PRODUCT = {
  id: null,
  fname: "",
  fcode: "",
};

export const documentTypeINITIAL = {
  id: null,
  name: "",
  erp_doc_class: "",
  cost_center_default_code: "",
  use_cost_center: false,
  allow_ret_ica: false,
  allow_ret_vat: false,
};

export const INITIAL_TAX = { id: null, description: "", percentage: 0 };

export const searchType = {
  invoiceType: "invoiceType",
  supplier: "supplier",
  product: "product",
  paymentMethod: "paymentMethod",
  tax: "tax",
  costCenter: "costCenter",
};

export const INITIAL_SUPPLIER = {
  email: null,
  full_name: "",
  id: null,
};

export const INITIAL_VALUES = {
  documentType: documentTypeINITIAL,
  nroComprobante: "",
  dateElaboration: null,
  dateExpiration: null,
  costCenter: "",
  supplier: INITIAL_SUPPLIER,
  dueDate: null,
};

export const INITIAL_ITEM_VALUES = {
  id: new Date().getTime(),
  type: null,
  product: INITIAL_PRODUCT,
  description: null,
  quantity: 1,
  unitValue: 0,
  discount: 0,
  taxIva: INITIAL_TAX,
  taxRetefuente: INITIAL_TAX,
  amountTotal: 0,
};

export const INITIAL_COUNT_GENERAL = {
  totalGross: 0,
  discount: 0,
  subtotal: 0,
  ivaTotal: 0,
  retefuenteTotal: 0,
  reteIva: INITIAL_TAX,
  reteIvaValue: 0,
  reteIca: INITIAL_TAX,
  reteIcaValue: 0,
};

export const INITIAL_SEARCH = {
  invoiceType: [],
  supplier: [],
  product: [],
  paymentMethod: [],
  taxIva: [],
  taxRetefuente: [],
  taxReteIva: [],
  taxReteIca: [],
};

export const TAX_VALUES = {
  iva: 0,
  retefuente: 1,
  reteIca: 2,
  reteIva: 3,
  ivaTypeFour: 4,
};

export const MODAL_RESPONSE_INITIAL_STATE = {
  open: false,
  type: "",
  textError: "",
  statusCode: null,
};

export const MODAL_RESPONSE_OPEN_INITIAL_STATE = {
  open: true,
  type: "success",
  textError: "",
  statusCode: 200,
};

export const DEFAULT_ERROR_MESSAGE = "CAUSATION_ERROR";

export const typePaymentOnChange = {
  payment: "payment",
  advance: "advance",
  dueDate: "dueDate",
  amount: "amount",
};

export const isAdvance = "Cruzar anticipo";

export const INITIAL_PAYMENT_METHOD = {
  accounting_concept: "",
  amount: 0,
};
