import { DataGrid } from "@mui/x-data-grid";
import React from "react";

export const CustomDataGrid = ({ columns, rows }) => {
  return (
    <DataGrid
      autoHeight
      columns={columns}
      rows={rows}
      hideFooter={true}
      disableSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      disableColumnSort
      sx={{
        "& .MuiDataGrid-cell": {
          borderRight: "1px solid #D0D5DD",
          background: "#F9FAFB",
        },
        "& .MuiDataGrid-cell:first-child": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiDataGrid-cellContent": {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          textAlign: "right",
          color: "#344054",
          width: "100%",
        },
        "& .MuiDataGrid-columnHeader": {
          background: "#F2F4F7",
          borderRight: "1px solid #D0D5DD",
        },
        "& .MuiDataGrid-root .MuiDataGrid-main": {
          borderRadius: "8px",
          borderRight: "none",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontSize: "12px",
          fontWeight: "bold",
          lineHeight: "18px",
          color: "#101828",
        },
      }}
      style={{
        borderRadius: "8px",
        border: "1px solid #D0D5DD",
        borderRight: "none",
      }}
    />
  );
};
