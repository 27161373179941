import React, { useContext } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { ArrowRightWithoutStick, BannerErrorIcon } from "assets";
import { Typography } from "@mui/material";
import { ButtonWithIcon, Tooltip } from "commons";
import { WithoutBorderButton } from "commons/buttons";
import { styles } from "../styles";
import { UserContext } from "contexts";
import { transactionEventType } from "constants";

export const PaymentErrorSection = ({
  transaction,
  setDrawerIsOpen,
  handleRefundPayment,
}) => {
  const { currentUser, currentUserIsAdmin } = useContext(UserContext);

  return (
    <div style={styles.sectionContainer}>
      <p style={styles.sectionTitle}>Error en el pago</p>
      <div style={styles.errorContainer}>
        <div style={styles.errorRow}>
          <div style={styles.errorIcon}>
            <BannerErrorIcon />
          </div>
          <div style={styles.errorColumn}>
            <Typography sx={styles.errorSectionTitle}>
              No pudimos realizar el pago
            </Typography>
            <Typography sx={styles.errorSectionText}>
              {
                transaction.receipt_histories.at(
                  transaction.receipt_histories.length - 1
                )?.errorMessage
              }
              . Por favor, modifica la información para reintentar el pago.
            </Typography>
          </div>
        </div>
      </div>
      <Tooltip
        title={
          !currentUserIsAdmin
            ? "Este proceso lo puede realizar únicamente un administrador"
            : ""
        }
      >
        <span style={{ width: "100%" }}>
          <ButtonWithIcon
            action={() => {
              amplitude.logEvent("START_REPROCESS_FLOW", {
                user_id: currentUser.id,
                transaction_id: transaction.transaction_id,
                receipt_id: transaction.id,
                module: transactionEventType[transaction.operation_type],
                error_message: transaction.receipt_histories.at(
                  transaction.receipt_histories.length - 1
                )?.errorMessage,
              });
              setDrawerIsOpen(transaction.beneficiary_id);
            }}
            buttonLabel={"Modificar datos y reintentar pago"}
            endIcon={
              <ArrowRightWithoutStick stroke="#FFFFFF" width="20" height="20" />
            }
            width={"100%"}
            style={{ marginTop: "12px" }}
            disabled={!currentUserIsAdmin}
          />
        </span>
      </Tooltip>
      <Tooltip
        title={
          !currentUserIsAdmin
            ? "Este proceso lo puede realizar únicamente un administrador"
            : ""
        }
      >
        <span style={{ width: "100%" }}>
          <WithoutBorderButton
            text="Solicitar devolución del dinero"
            action={() => handleRefundPayment()}
            width="100%"
            style={{ color: "#667085" }}
            contentStyle={{ justifyContent: "center" }}
            isDisable={!currentUserIsAdmin}
          />
        </span>
      </Tooltip>
    </div>
  );
};
