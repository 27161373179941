import { TYPE } from "../constants";
import { formatDate } from "./formatDate";

export const formatBody = ({
  receiptDetails,
  entryTypeBody,
  data,
  selectedRows,
  comment,
}) => {
  const { receipts, provider } = receiptDetails;
  return {
    provider: provider,
    entryType: entryTypeBody,
    type: data.type,
    paymentMethods: data.paymentMethod.ACPaymentMeanID,
    paidAt: formatDate(data.paidAt),
    items:
      data.type === TYPE.debtPayment
        ? selectedRows.map((item) => ({
            ...item,
            DueDate: formatDate(item.DueDate),
            Value: item.amountRegister,
          }))
        : [],
    comments: `${comment.payana} / ${comment.transactions} / ${comment.documents}`,
    amountTotal: receipts.total,
    receiptId: receipts.id,
    ...(data?.costCenter && { costCenter: data.costCenter }),
  };
};
