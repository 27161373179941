import React from "react";
import { Typography } from "@mui/material";
import { RequiredAsterisk } from "./RequiredAsterisk";

export const RequiredLabel = ({ sx = {}, className = {}, label }) => {
  return (
    <Typography sx={sx} className={className}>
      {label} <RequiredAsterisk />
    </Typography>
  );
};
