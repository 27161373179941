import {
  Box,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";
import { INITIAL_TAX } from "../constants";
import { styles } from "../styles";

const formatAmount = ({
  value = 0,
  options = {
    displayType: "text",
    thousandSeparator: ".",
    decimalSeparator: ",",
    prefix: "$",
    decimalScale: "2",
  },
  styles = {},
}) => {
  return (
    <NumericFormat
      style={{ ...styles }}
      value={value}
      displayType={options.displayType}
      thousandSeparator={options.thousandSeparator}
      decimalSeparator={options.decimalSeparator}
      prefix={options.prefix}
      decimalScale={options.decimalScale}
    />
  );
};

export function ExpandCausationCountGeneral({
  content,
  handleOnChangeGeneralTax,
  taxReteIva,
  taxReteIca,
  amountTotalNeto,
  allowReteIva = true,
  allowReteIca = true,
  isCaused,
}) {
  const isAllowReteIva = taxReteIva.length > 0 && allowReteIva;
  const isAllowReteIca = taxReteIca.length > 0 && allowReteIca;

  const taxOptionSelected = (type) => {
    if (type === "ReteIVA") {
      return (
        taxReteIva.find((tax) => tax.id === content.reteIva.id) || INITIAL_TAX
      );
    }

    if (type === "ReteICA") {
      return (
        taxReteIca.find((tax) => tax.id === content.reteIca.id) || INITIAL_TAX
      );
    }
  };

  return (
    <Box sx={styles.countGeneralBoxContainer}>
      <Stack gap={"20px"} sx={styles.countGeneralStackContainer}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography style={styles.countGeneralText}>Total bruto</Typography>
          {formatAmount({
            value: content.totalGross,
            styles: styles.countGeneralTextNum,
          })}
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography style={styles.countGeneralText}>Descuento</Typography>
          {formatAmount({
            value: content.discount,
            styles: styles.countGeneralTextNum,
          })}
        </Stack>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography style={styles.countGeneralText}>Subtotal</Typography>
          {formatAmount({
            value: content.subtotal,
            styles: styles.countGeneralTextNum,
          })}
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography style={styles.countGeneralText}>IVA</Typography>
          {formatAmount({
            value: content.ivaTotal,
            styles: styles.countGeneralTextNum,
          })}
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography style={styles.countGeneralText}>Retefuente</Typography>
          {formatAmount({
            value: content.retefuenteTotal,
            styles: styles.countGeneralTextNum,
          })}
        </Stack>
        {isAllowReteIva && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={36}
          >
            <Typography style={styles.countGeneralText}>ReteIVA</Typography>
            <div style={styles.taxesContainer}>
              <Select
                name="ReteIVA"
                size="small"
                displayEmpty={true}
                onChange={handleOnChangeGeneralTax}
                value={taxOptionSelected("ReteIVA")}
                sx={{ ...styles.taxesSelect, ...styles.countGeneralText }}
                disabled={isCaused}
              >
                <MenuItem
                  value={INITIAL_TAX}
                  style={{ height: "36px" }}
                ></MenuItem>
                {taxReteIva.map((tax) => (
                  <MenuItem
                    key={tax.id}
                    value={tax}
                    style={styles.countGeneralText}
                  >
                    {tax.description}
                  </MenuItem>
                ))}
              </Select>
              {formatAmount({
                value: content.reteIvaValue,
                styles: {
                  textAlign: "right",
                  width: "80px",
                  ...styles.countGeneralTextNum,
                  padding: "8px 0px",
                },
              })}
            </div>
          </Stack>
        )}
        {isAllowReteIca && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={36}
          >
            <Typography style={styles.countGeneralText}>ReteICA</Typography>
            <div style={styles.taxesContainer}>
              <Select
                name="ReteICA"
                size="small"
                displayEmpty={true}
                onChange={handleOnChangeGeneralTax}
                value={taxOptionSelected("ReteICA")}
                sx={{ ...styles.taxesSelect, ...styles.countGeneralText }}
                disabled={isCaused}
              >
                <MenuItem
                  value={INITIAL_TAX}
                  style={{ height: "36px" }}
                ></MenuItem>
                {taxReteIca.map((tax) => (
                  <MenuItem
                    key={tax.id}
                    value={tax}
                    style={styles.countGeneralText}
                  >
                    {tax.description}
                  </MenuItem>
                ))}
              </Select>
              {formatAmount({
                value: content.reteIcaValue,
                styles: {
                  textAlign: "right",
                  width: "80px",
                  ...styles.countGeneralTextNum,
                },
              })}
            </div>
          </Stack>
        )}
        <Divider style={{ background: "#D0D5DD" }} />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          style={styles.totalText}
        >
          <Typography style={styles.totalText}>Total neto</Typography>
          {formatAmount({
            value: amountTotalNeto,
            styles: styles.totalTextNum,
          })}
        </Stack>
      </Stack>
    </Box>
  );
}
