import React, { useContext, useEffect, useState } from "react";
import { TrackJS } from "trackjs";
import { Box, Divider, Stack } from "@mui/material";
import { PseIcon, CardIcon } from "../../../assets";
import { styles } from "./styles";
import { PrimaryButton } from "../../buttons";
import { UserContext } from "../../../contexts";
import { GetTransaction } from "../../../services";
import ComissionRetentions from "./ComissionRetentions";
import {
  DEFAULT_COLLECTION_CARD_FEE,
  DEFAULT_PAYMENT_CARD_FEE,
} from "../../../constants";
import { payanaComissionRetentions } from "../../../utils";
import PaymentMethodItem from "./PaymentMethodItem";
import CardDisabledIcon from "./CardIcon";
import BankingCorrespondantDisabledIcon from "./BankingCorrespondantDisabledIcon";
import BankingCorrespondantIcon from "./BankingCorrespondantIcon";
import BankingCorrespondentDisabledLeyend from "./BankingCorrespondent/DisabledLeyend";
import CreditCardDisabledLeyend from "./CreditCard/DisabledLeyend";
import getComissionDetails from "./getComissionDetails";
import PaymentModalRow from "./PaymentModalRow";
import getPayanaComission from "./getPayanaComission";

function PaymentMethodSelectionStep({
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  action,
  wompiButton,
  isLoading,
  totalAmount,
  totalEmployees,
  selectedRowsIds = [],
  selectedSuppliers = [],
  trxId,
  type = "invoice",
  section,
  isCardLimitExceeded,
  showBankingCorrespondent,
  isBankingCorrespondentLimitExceeded,
}) {
  const [loading, setLoading] = useState(false);
  const [linkGenerated, setLinkGenerated] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(totalAmount);

  useEffect(() => {
    trxId &&
      GetTransaction({ id: trxId }).then((res) => {
        setTransactionAmount(res.data.amount - res.data.amount_comission);
      });
  }, [trxId]);

  const formatAmount = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const {
    newPricing,
    newPricingMetadata,
    comissionRetentions,
    comissionRetentionIsEnabled,
  } = useContext(UserContext);

  const cardFeePercentage =
    type === "collection"
      ? DEFAULT_COLLECTION_CARD_FEE
      : DEFAULT_PAYMENT_CARD_FEE;

  const payanaComission = getPayanaComission({
    newPricing,
    newPricingMetadata,
    selectedPaymentMethod,
    totalAmount,
    type,
    selectedSuppliers,
    totalEmployees,
    transactionAmount,
    cardFeePercentage,
    selectedRowsIds,
  });

  const totalToPay = (selectedPaymentMethod, retentions) => {
    return section === "customer"
      ? selectedPaymentMethod === "others"
        ? transactionAmount
        : payanaComission + transactionAmount
      : comissionRetentionIsEnabled && selectedPaymentMethod === "others"
      ? newPricing
        ? payanaComission -
          payanaComissionRetentions(
            payanaComission,
            selectedPaymentMethod,
            retentions
          ) +
          transactionAmount
        : transactionAmount
      : payanaComission + transactionAmount;
  };

  const primaryButtonTextLabels = {
    others: "Pagar con PSE",
    card: "Pagar con tarjeta de crédito",
    banking_correspondent: "Pagar en efectivo",
  };

  const primaryButtonText = primaryButtonTextLabels[selectedPaymentMethod];
  const comission = getComissionDetails(
    newPricing,
    newPricingMetadata,
    selectedPaymentMethod,
    cardFeePercentage,
    formatAmount,
    type,
    payanaComission,
    section
  );

  return (
    <Box sx={styles.box}>
      <p style={styles.title}>Elige cómo quieres pagar</p>
      <p style={styles.subtitle}>Métodos de pago:</p>
      <div style={styles.column}>
        <PaymentMethodItem
          icon={<PseIcon />}
          type="others"
          selectedPaymentMethod={selectedPaymentMethod}
          id="paymentMethodOthers"
          label="PSE"
          onClick={() => setSelectedPaymentMethod("others")}
        />
        {section === "customer" && showBankingCorrespondent && (
          <PaymentMethodItem
            icon={
              isBankingCorrespondentLimitExceeded ? (
                <BankingCorrespondantDisabledIcon />
              ) : (
                <BankingCorrespondantIcon />
              )
            }
            type="banking_correspondent"
            selectedPaymentMethod={selectedPaymentMethod}
            id="paymentMethodBankingCorrespondent"
            label="Efectivo en corresponsal bancario"
            onClick={() => setSelectedPaymentMethod("banking_correspondent")}
            disabled={isBankingCorrespondentLimitExceeded}
            disabledLegend={<BankingCorrespondentDisabledLeyend />}
          />
        )}
        <PaymentMethodItem
          icon={isCardLimitExceeded ? <CardDisabledIcon /> : <CardIcon />}
          disabledLegend={<CreditCardDisabledLeyend />}
          type="card"
          disabled={isCardLimitExceeded}
          selectedPaymentMethod={selectedPaymentMethod}
          id="paymentMethodCard"
          label="Tarjetas de crédito"
          onClick={() => setSelectedPaymentMethod("card")}
        />
        <div style={styles.sourceOfFundsInfoContainer}>
          <div style={styles.sourceOfFundsInfoRow}>
            <p style={styles.sourceOfFundsInfoTitle}>
              Pagos a realizar (x
              {type === "invoice"
                ? selectedRowsIds.length
                : totalEmployees
                ? totalEmployees
                : selectedRowsIds.length}
              )
            </p>
            <p style={styles.sourceOfFundsInfoTotal}>
              {formatAmount.format(transactionAmount)}
            </p>
          </div>
          {comission && (
            <PaymentModalRow label={comission.label} value={comission.value} />
          )}
          <Divider sx={styles.divider} />
          {section !== "customer" && (
            <ComissionRetentions
              comissionRetentionIsEnabled={comissionRetentionIsEnabled}
              comissionRetentions={comissionRetentions}
              newPricing={newPricing}
              selectedPaymentMethod={selectedPaymentMethod}
              formatAmount={formatAmount}
              payanaComission={payanaComission}
            />
          )}
          <div style={styles.sourceOfFundsInfoRow}>
            <p style={styles.sourceOfFundsInfoTotal}>Total a pagar</p>
            <p style={styles.sourceOfFundsInfoTotal}>
              {formatAmount.format(
                totalToPay(selectedPaymentMethod, comissionRetentions)
              )}
            </p>
          </div>
        </div>
        <Stack
          direction="row"
          justifyContent="space-between"
          pt={1}
          style={{ width: "100%" }}
        >
          <PrimaryButton
            loading={loading || isLoading}
            isDisable={loading || isLoading || linkGenerated}
            width="100%"
            text={primaryButtonText}
            action={async () => {
              try {
                setLoading(true);
                await action();
                setLinkGenerated(true);
              } catch (error) {
                TrackJS.console.error(error);
                throw error;
              } finally {
                setLoading(false);
                setLinkGenerated(false);
              }
            }}
          />
        </Stack>
      </div>
      <form
        style={{ visibility: "hidden", height: 0 }}
        ref={wompiButton}
      ></form>
    </Box>
  );
}

export default PaymentMethodSelectionStep;
