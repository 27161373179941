import {
  getStatusPillStyle,
  getStatusWording,
  getStatusCausationWording,
  getStatusCausationPillStyle,
} from "../../../utils";
import { causationStates } from "../../../constants";

const causation = ({ status, isIncomplete }) => {
  const isReallyIncomplete = isIncomplete && status === "pending";
  const style = isReallyIncomplete
    ? getStatusCausationPillStyle(causationStates.draft)
    : getStatusCausationPillStyle(status);
  const label = getStatusCausationWording(
    status === "pending" ? causationStates.draft : status
  );
  return {
    style,
    label,
  };
};
const getPropsForTransactionsSections = (props) => {
  const status = getTransactionStatusLabel(props.status, props.operationType);
  const style = getStatusPillStyle(status);
  const label = getStatusWording(status);
  return { style, label };
};
const getReceipts = ({ statusPaymentGateway }) => {
  const status = getReceiptStatus(statusPaymentGateway);
  const style = getStatusPillStyle(status);
  const label = getStatusWording(status);
  return {
    style,
    label,
  };
};
const invoices = (props) => {
  const complianceStatus = getInvoiceStatusLabelApprovation(props);
  const status = complianceStatus || getInvoiceStatusLabel(props);
  const style = getStatusPillStyle(status);
  const label = getStatusWording(status);
  return { style, label };
};
const customer = (props) => {
  const status = props.status;
  const style = getStatusPillStyle(status, false, props.section);
  const label = getStatusWording(status, false, props.section);
  return { style, label };
};
const collections = ({ status, section }) => {
  const style = getStatusPillStyle(status, false, section);
  const label = getStatusWording(status, false, section);
  return { style, label };
};
const getReceiptStatus = (statusPaymentGateway) => {
  return statusPaymentGateway === "TRANSFER"
    ? "in_dispersion"
    : statusPaymentGateway === "FINISHED"
    ? "receipt_paid"
    : "to_disperse";
};
const getInvoiceStatusLabel = (props) => {
  const { status, transactionStatus, receipts } = props;
  if (status === "paid" || status === "paid_outside") {
    return status;
  }
  if (
    receipts?.length > 0 &&
    receipts?.every((receipt) => receipt.status === null)
  ) {
    return "to_disperse";
  }
  if (receipts?.some((receipt) => receipt.status === "TRANSFER")) {
    return "in_dispersion";
  }
  if (
    receipts?.length > 0 &&
    receipts?.every((receipt) => receipt.status === "FINISHED")
  ) {
    return "paid";
  }
  return transactionStatus === "pay_later" ? "batched" : status;
};
const getInvoiceStatusLabelApprovation = (props) => {
  const {
    status,
    invoiceIsApproved = false,
    currentUserIsAdmin,
    approvationNeeded,
  } = props;
  const invoiceIsInProcess = status === "in_process";
  const invoiceIsPaid = status === "paid" || status === "paid_outside";
  const invoiceIsPayLater = status === "pay_later";
  const needsCompliance =
    !invoiceIsApproved &&
    !invoiceIsInProcess &&
    !invoiceIsPaid &&
    !invoiceIsPayLater;

  if (!approvationNeeded || !needsCompliance) {
    return;
  }
  return currentUserIsAdmin ? "to_be_approved" : "to_be_checked";
};
const getTransactionStatusLabel = (status, operationType = "invoice") => {
  const transactionStatus = {
    in_process: "tx_in_process",
    paid: operationType === "collection" ? "tx_collected" : "tx_paid",
    rejected: "tx_rejected",
    pay_later: "tx_pay_later",
  };
  return transactionStatus[status] || status;
};

const statusPropsGetter = {
  causation,
  customer,
  transactions: getPropsForTransactionsSections,
  payrollTrx: getPropsForTransactionsSections,
  invoiceTrx: getPropsForTransactionsSections,
  receipts: getReceipts,
  invoices,
  collections,
  collectionTrx: collections,
};

export const getStatusPillProps = (props) => {
  const section = props.section;
  const functionToCall =
    statusPropsGetter[section] || statusPropsGetter.invoices;
  return functionToCall(props);
};
