import React, { useState } from "react";
import { renderToString } from "react-dom/server";
import { NumericFormat } from "react-number-format";
import { IconButton } from "@mui/material";
import { EditIcon } from "../../../assets";
import { styles } from "../styles";
import { AmountModal } from "../../modals";
import { permissions } from "../../../constants";
import { useIsUserAllowedTo } from "../../../utils";
import { Tooltip } from "commons/components";

export const AmountRow = ({
  cell,
  amountModal,
  pageIndex,
  status,
  providerId,
  toggleSelected,
  isChecked = null,
  isInvoices = false,
  supplierIncomplete = false,
  prefix = "$",
}) => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const [visible, setVisible] = useState(false);

  const handleClose = () => setVisible(false);
  const handleVisible = () => setVisible(true);

  return (
    <>
      <div style={isInvoices ? styles.invoicesAmountRow : styles.amountRow}>
        <p style={styles.revert}>
          <NumericFormat
            value={parseInt(
              renderToString(
                cell.row.original.operation_type === "collection" &&
                  cell.row.original.beneficiary_type !== "receiver"
                  ? cell.row.original.batch_total_amount
                  : cell.render("Cell")
              )
            )}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={prefix}
            decimalScale={2}
            allowNegative={false}
          />
        </p>
        {amountModal &&
        (cell.row.original.status === "pending" ||
          cell.row.original.status === "outstanding_balance") &&
        !supplierIncomplete &&
        isUserAllowedTo(permissions.INVOICES_UPDATE) ? (
          <Tooltip title="Editar monto">
            <IconButton onClick={handleVisible} sx={{ width: 35, height: 35 }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton disabled sx={{ width: 35, height: 35 }}></IconButton>
        )}
      </div>
      <AmountModal
        visible={visible}
        handleClose={handleClose}
        id={cell.row.original.id}
        pageIndex={pageIndex}
        status={status}
        providerId={providerId}
        toggleSelected={toggleSelected}
        isChecked={isChecked}
      />
    </>
  );
};
