import React, { useMemo, useState, useEffect, useContext } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  DeleteItemModal,
  NotifyWompiModal,
  Submenu,
  Table,
  TableSkeleton,
  PaymentModal,
  LimitExceededModal,
} from "../../commons";
import { paymentsScreens, invoicesTransactionsColumns } from "../../utils";
import { styles } from "./styles";
import { GetTotalTransactions, GetTransaction } from "../../services";
import { TransactionsContext, UserContext } from "../../contexts";
import { TableEmptyState } from "../../commons/Layout/EmptyState";
import { useLocation, useNavigate } from "react-router-dom";
import { EditSourceOfFundsModal } from "../../commons/modals/RegisterSourceOfFundsModal/EditSourceOfFundsModal";
import { useMediaQuery } from "react-responsive";
import { SearchIcon } from "../../assets";
import { RegisterSourceOfFundsModal } from "../../commons/modals/RegisterSourceOfFundsModal";
import { useChat } from "../../hooks";
import { TransactionsDetails } from "commons/modals/TransactionDetails";

export const InvoicesTransactionsScreen = (props) => {
  const location = useLocation();
  const [sourceOfFundsModal, setSourceOfFundsModal] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [totals, setTotal] = useState();
  const [searchInputText, setSearchInputText] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const { invoiceTransactions, deletePendingTransaction, getTransactions } =
    useContext(TransactionsContext);
  const {
    canPayWithCobre,
    displayCobreAlert,
    sourceOfFundsNeeded,
    currentUser,
  } = useContext(UserContext);
  const [detailsModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [selectedTrx, setSelectedTrx] = useState();
  const [deleteItemModalIsVisible, setDeleteItemModalIsVisible] =
    useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  const [searchOptions, setSearchOptions] = useState([]);
  const [wompiModalProps, setWompiModalProps] = useState({
    visible: false,
    totalAmount: 0,
    selectedRowsIds: [],
  });
  const [loading, setLoading] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [notifyWompiModalIsVisible, setNotifyWompiModalIsVisible] =
    useState(id);
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const [limitExceededAlert, setLimitExceededAlert] = useState({});
  const navigate = useNavigate();

  useChat();

  const refreshTransactionsTable = () => {
    getTransactions(pageIndex, "invoice", searchInputText);
    GetTotalTransactions({ text: searchInputText }).then((res) => {
      setTotal(res);
    });
  };

  useEffect(() => {
    amplitude.logEvent("VIEW_LOTS_TAB", {
      user_id: currentUser.id,
      module: "pagos_proveedores",
    });

    getTransactions(pageIndex, "invoice").finally(() => {
      setLoading(false);
    });
    GetTotalTransactions({ text: searchInputText }).then((res) => {
      setTotal(res);
    });
    location.state &&
      location.state.openDetail &&
      openDetailsModal(location.state.openDetail);
  }, []);

  useEffect(() => {
    refreshTransactionsTable();
  }, [pageIndex, searchInputText]);

  useEffect(() => {
    if (totals) {
      if (totals.total > 100) {
        setTotalPages(Math.ceil(totals.total / 100));
      } else {
        setTotalPages(1);
      }
    }
  }, [totals]);

  const columns = useMemo(
    () => invoicesTransactionsColumns(searchInputText),
    [searchInputText]
  );

  const openDetailsModal = (id) => {
    setSelectedTrx(id);
    setDetailModalIsOpen(true);
    setIsLoading(true);
    GetTransaction({ id: id })
      .then((res) => {
        setTransaction(res.transactions.transaction);
        setTrxRows(res.transactions.rows);
        setSourceOfFunds(res.transactions.source);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const closeDeleteItemModal = () => setDeleteItemModalIsVisible(false);
  const openDeleteItemModal = (id) => {
    setItemToDelete(id);
    setDeleteItemModalIsVisible(true);
  };

  const getSearchOptions = (query) => {
    setSearchInputText(query);
  };

  const closeWompiModal = () => {
    setWompiModalProps({
      ...wompiModalProps,
      visible: false,
    });
    refreshTransactionsTable();
  };
  const openWompiModal = ({
    visible,
    totalAmount,
    selectedRowsIds,
    selectedSuppliers,
  }) =>
    setWompiModalProps({
      visible: visible,
      totalAmount: totalAmount,
      selectedRowsIds: selectedRowsIds,
      selectedSuppliers: selectedSuppliers,
    });

  useEffect(() => {
    if (wompiModalProps.visible) {
      setDetailModalIsOpen(false);
    }
  }, [wompiModalProps]);

  const closeNotifyWompiModal = (id) => {
    setNotifyWompiModalIsVisible(false);
    getTransactions(pageIndex, "invoice");
    navigate("/transactions", {
      replace: true,
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [trxRows, setTrxRows] = useState([]);
  const [sourceOfFunds, setSourceOfFunds] = useState({});

  const closeAndReset = () => {
    setDetailModalIsOpen(false);
    getTransactions(pageIndex, "invoice");
  };

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        options={searchOptions}
        size="small"
        sx={{ width: isMobile ? "100%" : "35%", minWidth: "164px" }}
        freeSolo
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        onInputChange={(event) => {
          getSearchOptions(event.target.value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Buscar por código"}
          />
        )}
      />
    );
  };

  const handleCloseLimitExceededAlert = () => {
    setLimitExceededAlert((limitExceeded) => {
      return {
        ...limitExceeded,
        visible: false,
      };
    });
  };

  return (
    <>
      <NotifyWompiModal
        visible={notifyWompiModalIsVisible}
        handleClose={closeNotifyWompiModal}
      />
      {sourceOfFundsNeeded ? (
        <RegisterSourceOfFundsModal
          visible={wompiModalProps.visible}
          handleClose={closeWompiModal}
          canPayWithCobre={canPayWithCobre}
          selectedId={transaction.source_of_funds_id}
          displayCobreAlert={displayCobreAlert}
          redirectUrl={`${process.env.REACT_APP_BASE_URL}/transactions`}
          type="invoice"
          trxId={selectedTrx}
          setLimitExceededAlert={setLimitExceededAlert}
          {...wompiModalProps}
        />
      ) : (
        <PaymentModal
          visible={wompiModalProps.visible}
          handleClose={closeWompiModal}
          canPayWithCobre={canPayWithCobre}
          displayCobreAlert={displayCobreAlert}
          redirectUrl={`${process.env.REACT_APP_BASE_URL}/transactions`}
          type="invoice"
          trxId={selectedTrx}
          setLimitExceededAlert={setLimitExceededAlert}
          {...wompiModalProps}
        />
      )}
      <DeleteItemModal
        itemId={itemToDelete}
        action={deletePendingTransaction}
        handleClose={closeDeleteItemModal}
        title="¿Quieres borrar esta transacción pendiente de pago?"
        subtitle="Si la eliminas, no aparecerá más en Payana"
        visible={deleteItemModalIsVisible}
      />
      <LimitExceededModal
        onClose={handleCloseLimitExceededAlert}
        data={limitExceededAlert}
      />
      <TransactionsDetails
        isLoading={isLoading}
        transaction={transaction}
        trxRows={trxRows}
        sourceOfFunds={sourceOfFunds}
        visible={detailsModalIsOpen}
        setSourceOfFundsModal={setSourceOfFundsModal}
        onClose={closeAndReset}
        id={selectedTrx}
        sourceOfFundsActive={true}
        openWompiModal={openWompiModal}
        type={"invoice"}
        closeDrawer={() => {
          setDetailModalIsOpen(false);
        }}
      />
      <EditSourceOfFundsModal
        transactionId={transaction?.id}
        selectedId={sourceOfFunds?.id}
        sourceOfFunds={sourceOfFunds}
        visible={sourceOfFundsModal}
        handleClose={() => setSourceOfFundsModal(false)}
        handleSuccess={setSourceOfFunds}
      />
      <div>
        <Submenu items={paymentsScreens} />
        <div className="layout-container">
          <div style={styles.titleContainer}>
            <Typography sx={styles.title} noWrap>
              Lotes
            </Typography>
          </div>
          {loading ? (
            <TableSkeleton />
          ) : (
            <Table
              columns={columns}
              clearSearchText={() => setSearchInputText("")}
              data={invoiceTransactions}
              setPageIndex={setPageIndex}
              section="invoiceTrx"
              moneyIcon
              expandIcon
              expandAction={openDetailsModal}
              checkboxVisible={false}
              pageCount={totalPages}
              deleteIcon
              openDeleteItemModal={openDeleteItemModal}
              renderAutocomplete={renderAutocomplete}
              emptyState={
                <TableEmptyState
                  title={"Tu empresa aún no tiene transacciones"}
                  subtitle={
                    "Aquí se mostrarán los pagos o agrupamientos de facturas que realices."
                  }
                />
              }
            />
          )}
        </div>
      </div>
    </>
  );
};
