export const ExternalLinkIcon = ({ style = {} }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" style={style}>
    <path
      d="M13 5.00098L13 1.00098M13 1.00098H8.99999M13 1.00098L7 7.00098M5.66667 1.00098H4.2C3.0799 1.00098 2.51984 1.00098 2.09202 1.21896C1.71569 1.41071 1.40973 1.71667 1.21799 2.093C1 2.52082 1 3.08087 1 4.20098V9.80098C1 10.9211 1 11.4811 1.21799 11.909C1.40973 12.2853 1.71569 12.5912 2.09202 12.783C2.51984 13.001 3.07989 13.001 4.2 13.001H9.8C10.9201 13.001 11.4802 13.001 11.908 12.783C12.2843 12.5912 12.5903 12.2853 12.782 11.909C13 11.4811 13 10.9211 13 9.80098V8.33431"
      stroke="#475467"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
