import React, { useContext } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { styles } from "../styles";
import { Typography } from "@mui/material";
import { openInNewTab } from "utils";
import { PrimaryButton } from "commons/buttons";
import { REFERRAL_LINK } from "constants";
import { CloseIcon, ReferralBannerImage } from "assets";
import { UserContext } from "contexts";

export const ReferralBanner = ({
  saveInLocalStorage = () => {},
  setReferralBannerVisible = () => {},
}) => {
  const { currentUser } = useContext(UserContext);

  return (
    <div style={styles.referralContainer}>
      <ReferralBannerImage />
      <div style={styles.referralContent}>
        <div style={styles.referralColumn}>
          <div style={styles.rowSpaceBetween}>
            <Typography sx={styles.referralTitle}>
              Gana $50.000 por referir a una empresa
            </Typography>
            <div
              style={{ cursor: "pointer", marginTop: "-8px" }}
              onClick={() => {
                amplitude.logEvent("CLICK_REFERRAL_BANNER", {
                  user_id: currentUser.id,
                });
                saveInLocalStorage("isReferralBannerVisible", "false");
                setReferralBannerVisible("false");
              }}
            >
              <CloseIcon color={"#FFFFFF"} />
            </div>
          </div>
          <div style={styles.rowSpaceBetween}>
            <Typography sx={styles.referralInfo}>
              ¿Conoces un dueño, contador o auxiliar contable al que Payana le
              pueda servir? Déjanos el contacto{" "}
              <strong>
                y si se suman a Payana ganas $50.000 en un bono de regalo
              </strong>
            </Typography>
            <PrimaryButton
              text="Referir"
              action={() => openInNewTab(REFERRAL_LINK)}
              width="150px"
              style={styles.referralButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
