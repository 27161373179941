import { PricingIcon } from "../../../../assets";
import "./ReferralMessage.css";

function ReferralMessage({ visible }) {
  return visible ? (
    <div className="referral-message-navbar">
      <PricingIcon color="#027a48" />
      <span className="referral-message-navbar-text">
        <strong>
          <a
            href="https://payana.la/referidos/?utm_source=referidos&utm_medium=topNavbar"
            target="_blank"
          >
            Recomienda Payana y gana $50.000
          </a>
        </strong>
      </span>
    </div>
  ) : null;
}

export default ReferralMessage;
