import { IconButton } from "@mui/material";
import { SendIcon } from "assets";
import { Tooltip } from "commons";
import { toastOptions } from "constants";
import { toast } from "react-toastify";

const SendCollectionToClient = ({
  row,
  blockedCompany,
  setOpenBlockedModal,
  setOpenCollectionIncomplete,
  collectionIsIncomplete,
  isChecked,
  toggleSelectedRow,
  setOpenCustomerIncomplete,
  SendNotification,
  refreshCollectionsTable,
}) => {
  return (
    <Tooltip title="Enviar notificación al cliente">
      <IconButton
        sx={{ margin: "0px 10px" }}
        onClick={() => {
          if (blockedCompany) {
            setOpenBlockedModal(true);
          } else {
            if (!row.original.expiration_date) {
              setOpenCollectionIncomplete(true);
            } else if (collectionIsIncomplete(row.original)) {
              !isChecked(row.original) && toggleSelectedRow(row.original);
              setOpenCustomerIncomplete(true);
            } else {
              SendNotification([row.original.id]).then(() => {
                toast.success(
                  `La notificación fue enviada con éxito`,
                  toastOptions
                );
                refreshCollectionsTable();
              });
            }
          }
        }}
      >
        <SendIcon />
      </IconButton>
    </Tooltip>
  );
};

export default SendCollectionToClient;
