import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { CloseButton, Spinner } from "react-bootstrap";
import {
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Box,
  Dialog,
  Divider,
} from "@mui/material";
import { CollectionsContext, UserContext } from "../../../contexts";
import { styles } from "../../Table/styles";
import {
  PrimaryButton,
  SecondaryButton,
  ThirdButton,
  WithoutBorderButton,
} from "../../buttons";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import FileUpload from "../ExpandInvoiceModal/components/FileUpload";
import { CustomTextField } from "./components";
import {
  GetCustomer,
  GetReceiver,
  SearchCustomers,
  SendNotification,
} from "../../../services";
import {
  BLOCKED_MESSAGE,
  minimumLengthForSearch,
  permissions,
  toastOptions,
} from "../../../constants";
import { toast } from "react-toastify";
import { objectIsEmpty } from "../../../utils/objectUtils";
import { useMediaQuery } from "react-responsive";
import { AddresseeIcon, CornerDownRightIcon } from "../../../assets";
import PayanaComission from "../ExpandCollectionModal/components/PayanaComission";
import { getDetailsToCollect } from "../../../screens/CollectionsScreen/comission/calcCollectionsComission";
import {
  useIsUserAllowedTo,
  disabledByReceiversData,
  isExceeded,
} from "../../../utils";
import { RequiredLabel } from "../../components";
import { AttentionModal } from "../AttentionModal";
import ReceiversSection from "./ReceiversSection";
import DisabledReceivers from "../ExpandCollectionModal/components/DisabledReceivers";

const getCustomerFormState = (customer = {}) => ({
  customer_id: "",
  issue_date: null,
  expiration_date: null,
  amount: "",
  type: "collection",
  concept: "",
  collection_number: "",
  file_path: "",
  receivers: [],
  ...customer,
});

export const CollectionObligationModal = ({
  visible,
  handleClose,
  createdCustomer,
  setDrawerIsOpen,
  defaultCollectionNumber,
  setReceiverDrawerIsOpen,
  createdReceiver,
}) => {
  const [style, setStyle] = useState({});
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const [searchOptions, setSearchOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedReceiver, setSelectedReceiver] = useState({});
  const [selectedSecondReceiver, setSelectedSecondReceiver] = useState({});
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);
  const [checked, setChecked] = useState(false);
  const [secondReceiver, setSecondReceiver] = useState(false);
  const [firstReceiverAmountState, setFirstReceiverAmount] = useState("");
  const [receiverOption, setReceiverOption] = useState("amount");
  const [secondReceiverAmountState, setSecondReceiverAmount] = useState("");
  const [openBlockedModal, setOpenBlockedModal] = useState(false);
  const [customerError, setCustomerError] = useState({
    helperText: "",
    error: false,
  });
  const [conceptError, setConceptError] = useState({
    helperText: "",
    error: false,
  });
  const [collectionNumberError, setCollectionNumberError] = useState({
    helperText: "",
    error: false,
  });
  const [expirationDateError, setExpirationDateError] = useState({
    helperText: "",
    error: false,
  });
  const {
    createCollection,
    uploadCollectionFile,
    getMainReceiver,
    mainReceiver,
    internalGetCollections,
    statusSelected,
    perPageSelected,
    sortingSelected,
    collectionsScreen,
  } = useContext(CollectionsContext);
  const {
    currentUser,
    companyCollectionsCommission,
    companyCollectionsFixedCommission,
    blockedCompany,
    hasCollectionReceiversEnabled,
  } = useContext(UserContext);

  const [formValues, setFormValues] = useState(
    getCustomerFormState({ collection_number: defaultCollectionNumber })
  );
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [collectionNumber, setCollectionNumber] = useState(
    defaultCollectionNumber
  );
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  useEffect(() => {
    if (visible) {
      objectIsEmpty(mainReceiver) && setReceiverDrawerIsOpen(true);
      setSelectedCustomer({});
      setCollectionNumber(defaultCollectionNumber);
      getMainReceiver();
    }
  }, [visible]);

  useEffect(() => {
    setStyle({ padding: "0px" });
  }, []);

  useEffect(() => {
    setFormValues((data) => {
      return {
        ...data,
        customer_id: createdCustomer?.id,
      };
    });
    setCollectionNumber(defaultCollectionNumber);
    if (formValues.customer_id) {
      GetCustomer({ id: formValues.customer_id }).then((res) => {
        setSelectedCustomer(res[0]);
      });
    }
  }, [createdCustomer]);

  useEffect(() => {
    if (createdReceiver?.id) {
      GetReceiver({ id: createdReceiver?.id }).then((res) => {
        setSelectedReceiver(res);
      });
    }
  }, [createdReceiver]);

  const { round_comission, receivers, total_to_collector } =
    getDetailsToCollect(
      formValues.amount,
      companyCollectionsCommission,
      companyCollectionsFixedCommission,
      firstReceiverAmountState,
      secondReceiverAmountState,
      receiverOption
    );
  const amountComission = round_comission;
  const firstReceiverAmount = receivers.at(0)?.round_comission;
  const secondReceiverAmount = receivers.at(1)?.round_comission;

  const pageIndex = collectionsScreen?.pageIndex;
  const customerIdSelected = collectionsScreen?.customerIdSelected?.value;

  const fieldHasError = () => {
    let hasError = false;
    if (!formValues.customer_id) {
      setCustomerError({
        helperText: "Este campo no puede estar vacío",
        error: true,
      });
      hasError = true;
    }

    if (!formValues.concept) {
      setConceptError({
        helperText: "Este campo no puede estar vacío",
        error: true,
      });
      hasError = true;
    }

    if (!formValues.collection_number) {
      setCollectionNumberError({
        helperText: "Este campo no puede estar vacío",
        error: true,
      });
      hasError = true;
    }

    if (!formValues.expiration_date) {
      setExpirationDateError({
        helperText: "Este campo no puede estar vacío",
        error: true,
      });
      hasError = true;
    } else {
      setExpirationDateError({
        helperText: "",
        error: false,
      });
    }

    return hasError;
  };

  const saveAndRedirect = async (sendNow = false) => {
    if (createRequestInProcess) {
      return;
    }
    if (fieldHasError()) {
      return;
    }

    setCreateRequestInProcess(true);
    const uploadedFile = await uploadCollectionFile(selectedFiles);
    const date = new Date();

    const collectionReceivers = [
      { receiver_id: mainReceiver.id, amount: companyAmount() },
    ];

    checked &&
      !objectIsEmpty(selectedReceiver) &&
      firstReceiverAmount &&
      collectionReceivers.push({
        receiver_id: selectedReceiver.id,
        amount: firstReceiverAmount,
        percentage:
          receiverOption === "percentage" ? firstReceiverAmountState : null,
      });

    checked &&
      !objectIsEmpty(selectedSecondReceiver) &&
      secondReceiverAmount &&
      collectionReceivers.push({
        receiver_id: selectedSecondReceiver.id,
        amount: secondReceiverAmount,
        percentage:
          receiverOption === "percentage" ? secondReceiverAmountState : null,
      });

    await createCollection({
      ...formValues,
      file_path: uploadedFile?.location,
      issue_date: date,
      receivers: collectionReceivers,
      amount_type: receiverOption,
    })
      .then((res) => {
        if (res?.response?.status === 500) {
          throw res;
        }
        if (sendNow) {
          SendNotification([res.collection_id]).then(() => {
            toast.success(
              `La notificación fue enviada con éxito`,
              toastOptions
            );
            internalGetCollections(
              pageIndex,
              statusSelected,
              customerIdSelected,
              perPageSelected,
              sortingSelected
            );
          });
        }
        handleClose();
        setFormValues(getCustomerFormState());
        setSelectedCustomer({});
      })
      .catch((err) => {
        toast.error(
          "Hubo un error creando el cobro, por favor intente de nuevo.",
          toastOptions
        );
      })
      .finally(() => {
        setCreateRequestInProcess(false);
      });
  };

  useEffect(() => {
    createdCustomer && setSelectedCustomer(createdCustomer);
  }, [createdCustomer]);

  useEffect(() => {
    if (formValues.customer_id) {
      GetCustomer({ id: formValues.customer_id }).then((res) => {
        setSelectedCustomer(res[0]);
      });
    }
  }, [formValues.customer_id]);

  const getSearchOptions = (query) => {
    SearchCustomers(query).then((res) => {
      setSearchOptions(res);
    });
  };

  const handleOnChangeAutocomplete = (event, newValue) => {
    if (newValue?.id) {
      setFormValues({
        ...formValues,
        customer_id: newValue.id,
      });
      setCollectionNumber(defaultCollectionNumber);
    } else {
      setFormValues({
        ...formValues,
        customer_id: null,
      });
    }
  };

  const onOptionSelectedChanged = (option) => {
    setReceiverOption(option);
    setFirstReceiverAmount("");
    setSecondReceiverAmount("");
  };

  const companyAmount = () => {
    return formValues.amount ? total_to_collector : 0;
  };

  const isReceiverAmountExceeded = isExceeded(
    receiverOption,
    parseInt(firstReceiverAmountState),
    parseInt(secondReceiverAmountState) || 0,
    parseInt(formValues.amount - amountComission)
  );

  const isDisable =
    createRequestInProcess ||
    !formValues.amount ||
    formValues.amount < 5000 ||
    objectIsEmpty(mainReceiver) ||
    disabledByReceiversData(
      checked,
      secondReceiver,
      receiverOption,
      selectedReceiver,
      firstReceiverAmountState,
      formValues,
      amountComission,
      selectedSecondReceiver,
      secondReceiverAmountState
    );

  const deleteSecondReceiver = () => {
    setSecondReceiver(false);
    setSelectedSecondReceiver({});
    setSecondReceiverAmount("");
  };

  return (
    <>
      <AttentionModal
        title={BLOCKED_MESSAGE}
        onClose={() => setOpenBlockedModal(false)}
        open={openBlockedModal}
      />
      <Dialog fullScreen open={visible} style={style} onClose={handleClose}>
        <div className="modal-header-payana">
          <div style={styles.headerRow}>
            <CloseButton
              style={styles.nonShadow}
              onClick={() => {
                handleClose();
                setFormValues(getCustomerFormState());
                setSelectedCustomer({});
              }}
            />
            <div className="modalTitle" style={styles.title}>
              Nuevo cobro manual
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <SecondaryButton
              text={
                createRequestInProcess ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Guardar"
                )
              }
              action={() => saveAndRedirect(false)}
              isDisable={isDisable}
              width="100%"
            />
            {isUserAllowedTo(permissions.SHOW_FOOTER_COLLECTIONS) && (
              <PrimaryButton
                text={
                  createRequestInProcess ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Enviar ahora"
                  )
                }
                action={() => {
                  if (blockedCompany) {
                    setOpenBlockedModal(true);
                  } else {
                    saveAndRedirect(true);
                  }
                }}
                isDisable={isDisable}
                style={{ marginLeft: isMobile ? "0px" : "16px" }}
                width="100%"
              />
            )}
          </div>
        </div>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{ margin: "auto" }}
        >
          <Box
            sx={{
              minWidth: isMobile ? 380 : 448,
              maxWidth: isMobile ? 380 : 448,
              margin: "0 auto",
              paddingTop: 2,
              paddingLeft: 2,
              paddingRight: 2,
              marginBottom: "24px",
              width: isMobile ? "100%" : "50%",
              paddingBottom: 40,
            }}
          >
            <>
              <Stack spacing={1} id="new_payment_supplier_field">
                <div style={styles.sectionRow}>
                  <AddresseeIcon />
                  <span style={styles.cardTitle}>Cliente</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "-8px",
                  }}
                >
                  <RequiredLabel
                    label="Cliente"
                    sx={styles.invoiceCardTitleRevert}
                  />
                  <WithoutBorderButton
                    text="Crear nuevo"
                    action={() => setDrawerIsOpen(true)}
                    width="86px"
                  />
                </div>
                <Autocomplete
                  options={searchOptions}
                  freeSolo
                  getOptionLabel={(option) => option?.name || ""}
                  value={selectedCustomer}
                  onChange={handleOnChangeAutocomplete}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { fontSize: 14 },
                      }}
                      placeholder={"Ingresa el nombre"}
                      error={customerError.error}
                    />
                  )}
                  size="small"
                  onInputChange={(event) => {
                    if (
                      event?.target?.value?.length >= minimumLengthForSearch
                    ) {
                      getSearchOptions(event?.target?.value);
                    }
                    if (event?.target?.value?.length === 0) {
                      setCustomerError({
                        helperText: "Este campo no puede estar vacío",
                        error: true,
                      });
                    } else {
                      setCustomerError({
                        helperText: "",
                        error: false,
                      });
                    }
                  }}
                />
                {customerError.error && (
                  <Typography sx={styles.inputError} noWrap>
                    {customerError.helperText}
                  </Typography>
                )}
                {formValues.customer_id && !objectIsEmpty(selectedCustomer) && (
                  <div style={styles.supplierDataCardContainer}>
                    <div style={styles.supplierDataCard}>
                      <p
                        style={styles.invoiceSupplierCardTitle}
                        className="card-subtitle mb-2"
                      >
                        {selectedCustomer?.document_type
                          ? selectedCustomer.document_type
                          : "NIT"}
                      </p>
                      <p
                        style={styles.invoiceSupplierCardText}
                        className="card-subtitle mb-2"
                      >
                        {selectedCustomer.document_number}
                      </p>
                    </div>
                    <Divider sx={{ marginBottom: "8px" }} />
                    <div style={styles.supplierDataCard}>
                      <p
                        style={styles.invoiceSupplierCardTitle}
                        className="card-subtitle mb-2"
                      >
                        Correo electrónico
                      </p>
                      <p
                        style={styles.invoiceSupplierCardText}
                        className="card-subtitle mb-2"
                      >
                        {selectedCustomer.email}
                      </p>
                    </div>
                  </div>
                )}
              </Stack>
              <div style={styles.invoiceRow}>
                <div style={styles.invoiceColumn}>
                  <Stack spacing={1} id="new_payment_amount_field">
                    <RequiredLabel label="Monto" sx={styles.invoiceCardTitle} />
                    <NumericFormat
                      id="amount"
                      name="amount"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={0}
                      allowNegative={false}
                      isNumericString={true}
                      value={formValues.amount}
                      customInput={CustomTextField}
                      onValueChange={(values, sourceInfo) => {
                        setFormValues({
                          ...formValues,
                          amount: values.floatValue,
                        });
                      }}
                    />
                  </Stack>
                </div>
              </div>
              <div style={styles.invoiceRow}>
                <div style={styles.invoiceColumn}>
                  <Stack spacing={1}>
                    <RequiredLabel
                      label="Concepto"
                      sx={styles.invoiceCardTitle}
                    />
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="Ej: Anticipo por reparación maquinaria"
                      InputProps={{
                        style: { fontSize: 14 },
                      }}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          concept: e.target.value,
                        });
                        if (e?.target?.value?.length === 0) {
                          setConceptError({
                            helperText: "Este campo no puede estar vacío",
                            error: true,
                          });
                        } else {
                          if (
                            !e.target.value.match(/^[\p{L}\p{N}\p{Zs}]+$/gmu)
                          ) {
                            setConceptError({
                              helperText: "El formato del campo no es válido",
                              error: true,
                            });
                          } else {
                            setConceptError({
                              helperText: "",
                              error: false,
                            });
                          }
                        }
                      }}
                      error={conceptError.error}
                    />
                    {conceptError.error && (
                      <Typography sx={styles.inputError} noWrap>
                        {conceptError.helperText}
                      </Typography>
                    )}
                  </Stack>
                </div>
              </div>
              <div style={styles.invoiceRow}>
                <div style={styles.invoiceColumn}>
                  <Stack spacing={1} sx={{ marginRight: "12px" }}>
                    <RequiredLabel
                      label="Número de comprobante"
                      sx={styles.invoiceCardTitle}
                    />
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="Número de comprobante"
                      InputProps={{
                        style: { fontSize: 14 },
                      }}
                      defaultValue={collectionNumber}
                      value={collectionNumber}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          collection_number: e.target.value,
                        });
                        setCollectionNumber(e.target.value);
                        if (e?.target?.value?.length === 0) {
                          setCollectionNumberError({
                            helperText: "Este campo no puede estar vacío",
                            error: true,
                          });
                        } else {
                          if (!e.target.value.match(/^[A-Za-z0-9_-]+$/)) {
                            setCollectionNumberError({
                              helperText: "El formato del campo no es válido",
                              error: true,
                            });
                          } else {
                            setCollectionNumberError({
                              helperText: "",
                              error: false,
                            });
                          }
                        }
                      }}
                      error={collectionNumberError.error}
                    />
                    {collectionNumberError.error && (
                      <Typography sx={styles.inputError} noWrap>
                        {collectionNumberError.helperText}
                      </Typography>
                    )}
                  </Stack>
                </div>
                <div style={styles.invoiceColumn}>
                  <Stack spacing={1}>
                    <RequiredLabel
                      label="Fecha de vencimiento"
                      sx={styles.invoiceCardTitle}
                    />
                    <LocalizationProvider
                      adapterLocale={es}
                      dateAdapter={AdapterDateFns}
                    >
                      <DatePicker
                        value={formValues.expiration_date}
                        onChange={(value) => {
                          setFormValues({
                            ...formValues,
                            expiration_date: value,
                          });
                        }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              style: { fontSize: 14 },
                            },
                            size: "small",
                            error: expirationDateError.error,
                          },
                        }}
                        format="dd/MM/yyyy"
                      />
                    </LocalizationProvider>
                    {expirationDateError.error && (
                      <Typography sx={styles.inputError} noWrap>
                        {expirationDateError.helperText}
                      </Typography>
                    )}
                  </Stack>
                </div>
              </div>
              <div style={styles.invoiceRow}>
                <div style={styles.invoiceColumn}>
                  <Stack spacing={1}>
                    <FileUpload
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      isInvoiceFile={true}
                    />
                  </Stack>
                </div>
              </div>
              {hasCollectionReceiversEnabled ? (
                <ReceiversSection
                  setChecked={setChecked}
                  checked={checked}
                  setSelectedReceiver={setSelectedReceiver}
                  setSelectedSecondReceiver={setSelectedSecondReceiver}
                  setFirstReceiverAmount={setFirstReceiverAmount}
                  setSecondReceiverAmount={setSecondReceiverAmount}
                  setSecondReceiver={setSecondReceiver}
                  objectIsEmpty={objectIsEmpty}
                  mainReceiver={mainReceiver}
                  onOptionSelectedChanged={onOptionSelectedChanged}
                  receiverOption={receiverOption}
                  setReceiverOption={setReceiverOption}
                  firstReceiverAmountState={firstReceiverAmountState}
                  setReceiverDrawerIsOpen={setReceiverDrawerIsOpen}
                  selectedReceiver={selectedReceiver}
                  isReceiverAmountExceeded={isReceiverAmountExceeded}
                  formValues={formValues}
                  amountComission={amountComission}
                  secondReceiver={secondReceiver}
                  secondReceiverAmountState={secondReceiverAmountState}
                  selectedSecondReceiver={selectedSecondReceiver}
                  deleteSecondReceiver={deleteSecondReceiver}
                />
              ) : (
                <DisabledReceivers />
              )}
            </>
          </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "50%",
              minWidth: isMobile ? 380 : 400,
              maxWidth: isMobile ? 380 : 400,
            }}
          >
            <div
              style={{
                marginTop: "24px",
                width: "100%",
                backgroundColor: "#F2F3FF",
                borderRadius: "8px",
                padding: "24px",
                position: "sticky",
                top: "8px",
              }}
            >
              <p style={{ ...styles.cardTitle, marginBottom: "24px" }}>
                Detalle del cobro
              </p>
              <div style={styles.supplierDataCard}>
                <p
                  style={styles.invoiceSupplierCardTitle}
                  className="card-subtitle mb-2"
                >
                  Importe de la factura
                </p>
                <p
                  style={styles.collectionTotalsCardText}
                  className="card-subtitle mb-2"
                >
                  $
                  <NumericFormat
                    value={parseInt(formValues.amount) || 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                  />
                </p>
              </div>
              <PayanaComission
                amount={parseInt(formValues.amount)}
                companyCollectionsCommission={companyCollectionsCommission}
                companyCollectionsFixedCommission={
                  companyCollectionsFixedCommission
                }
              />
              <Divider sx={{ marginBottom: "8px" }} />
              <div style={styles.supplierDataCard}>
                <p
                  style={styles.collectionTotalCardTitle}
                  className="card-subtitle mb-2"
                >
                  Total a recibir
                </p>
                <p
                  style={styles.collectionTotalCardText}
                  className="card-subtitle mb-2"
                >
                  $
                  <NumericFormat
                    value={
                      formValues.amount
                        ? parseInt(formValues.amount - amountComission)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                  />
                </p>
              </div>
              {objectIsEmpty(mainReceiver) ? (
                <div style={styles.emptyReceiverDataCard}>
                  <p
                    style={styles.receiverCardTitle}
                    className="card-subtitle mb-2"
                  >
                    Para poder enviar este cobro necesitas cargar los datos de
                    tu cuenta bancaria.
                  </p>
                  <ThirdButton
                    text={"Completar información bancaria"}
                    action={() => setReceiverDrawerIsOpen(true)}
                    width="240px"
                    style={{ marginTop: "8px", marginBottom: "8px" }}
                  />
                </div>
              ) : (
                <>
                  <div style={styles.receiverDataCard}>
                    <p
                      style={styles.receiverCardTitle}
                      className="card-subtitle mb-2"
                    >
                      <CornerDownRightIcon /> Destinatario{" "}
                      {currentUser?.company?.name}
                    </p>
                    <p
                      style={styles.collectionTotalsCardText}
                      className="card-subtitle mb-2"
                    >
                      $
                      <NumericFormat
                        value={parseInt(companyAmount())}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                      />
                    </p>
                  </div>
                  {checked && !objectIsEmpty(selectedReceiver) && (
                    <div style={styles.receiverDataCard}>
                      <p
                        style={styles.receiverCardTitle}
                        className="card-subtitle mb-2"
                      >
                        <CornerDownRightIcon /> Destinatario{" "}
                        {selectedReceiver.name.toUpperCase()}
                      </p>
                      <p
                        style={styles.collectionTotalsCardText}
                        className="card-subtitle mb-2"
                      >
                        $
                        <NumericFormat
                          value={firstReceiverAmount ? firstReceiverAmount : 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                        />
                      </p>
                    </div>
                  )}
                  {checked && !objectIsEmpty(selectedSecondReceiver) && (
                    <div style={styles.receiverDataCard}>
                      <p
                        style={styles.receiverCardTitle}
                        className="card-subtitle mb-2"
                      >
                        <CornerDownRightIcon /> Destinatario{" "}
                        {selectedSecondReceiver.name.toUpperCase()}
                      </p>
                      <p
                        style={styles.collectionTotalsCardText}
                        className="card-subtitle mb-2"
                      >
                        $
                        <NumericFormat
                          value={
                            secondReceiverAmount ? secondReceiverAmount : 0
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                        />
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </Box>
        </Stack>
      </Dialog>
    </>
  );
};
