export const styles = {
  title: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "28px",
    color: "#344054",
  },
  subtitleProviderAdvance: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#667085",
  },
  textAddNewItem: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#5925DC",
    cursor: "pointer",
    width: "30%",
  },
  warningText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#F04438",
  },
  selectAdvance: {
    width: "100%",
    borderRadius: "8px",
    border: "1px",
    opacity: "0px",
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      padding: "8px 14px",
      fontSize: "14px",
    },
  },
  selectRenderContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  selectRenderIcon: {
    display: "flex",
    alignItems: "center",
  },
  inputAmount: {
    width: "100%",
    borderRadius: "8px",
    border: "1px",
    opacity: "0px",
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      padding: "8px 14px",
      fontSize: "14px",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  tooltipTitle: {
    color: "#5522FF",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    marginBottom: "6px",
  },
  tooltipSubtitle: {
    color: "#5522FF",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
  },
};
