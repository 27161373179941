const getPayanaComission = ({
  newPricing,
  newPricingMetadata,
  selectedPaymentMethod,
  totalAmount,
  type,
  selectedSuppliers,
  cardFeePercentage,
  selectedRowsIds,
}) => {
  if (selectedPaymentMethod === "banking_correspondent") {
    return 0;
  }
  if (selectedPaymentMethod === "card") {
    return Math.round((totalAmount / 100) * cardFeePercentage);
  }
  if (newPricing && newPricingMetadata.payment_percentage) {
    return Math.round(
      (totalAmount / 100) * newPricingMetadata.payment_percentage
    );
  }
  if (newPricing && newPricingMetadata.amount) {
    const payouts =
      type === "invoice" ? selectedSuppliers.length : selectedRowsIds.length;
    return payouts * newPricingMetadata.amount;
  }
  return 0;
};

export default getPayanaComission;
