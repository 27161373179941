import { Link, Typography } from "@mui/material";
import { styles } from "./styles";
import { ChevronRight } from "assets";

const ChevronLink = ({ text }) => {
  return (
    <div className="container-see-details">
      <Link style={{ textDecoration: "none", marginRight: 10 }}>
        <Typography className="text" sx={styles.labelButton}>
          {text}
        </Typography>
      </Link>
      <ChevronRight pathClassName="chevron" />
    </div>
  );
};

export default ChevronLink;
