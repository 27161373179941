import React, { useContext } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { CollectionsContext, PaymentsContext } from "../../contexts";

export const BankEntitySelector = ({
  bankSelectedId,
  validationProps,
  context = "payments",
}) => {
  const { allBanks } = useContext(PaymentsContext);
  const { banks } = useContext(CollectionsContext);
  const banksList = context === "payments" ? allBanks : banks;
  const option = banksList.find((bank) => bank.id === bankSelectedId) || {
    name: "",
  };
  return (
    <Autocomplete
      sx={{ zIndex: 1302 }}
      options={banksList}
      size="small"
      freeSolo
      disablePortal
      value={option}
      getOptionLabel={(option) => (option ? option.name : "")}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: 14, backgroundColor: "#FFFFFF" },
            placeholder: "Ingresa la entidad bancaria",
          }}
        />
      )}
      {...validationProps}
      onChange={(event, option) => {
        validationProps.onChange({
          ...event,
          target: {
            ...event.target,
            name: "bankId",
            value: option ? option.id : "",
          },
        });
      }}
    />
  );
};
