export const SecondaryButton = ({
  text,
  action,
  isDisable = false,
  height = "40px",
  width = "100px",
  fontSize = "14px",
  icon = null,
  style = {},
  textStyle = {},
}) => {
  const styles = {
    borderRadius: "8px",
    height: height,
    fontSize: fontSize,
    fontWeight: "500",
    lineHeight: "20px",
    width: width,
    backgroundColor: "#FFFFFF",
    border: isDisable ? "1px solid #D0D5DD80" : "1px solid #D0D5DD",
    color: isDisable ? "#34405480" : "#344054",
    justifyContent: "space-between",
    padding: "8px 16px 8px 16px",
    textWrap: "nowrap",
    ...style,
  };

  return (
    <button type="button" onClick={action} disabled={isDisable} style={styles}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
        {text && <span style={textStyle}>{text}</span>}
      </div>
    </button>
  );
};
